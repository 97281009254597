import { CustomInformationFields } from '@/features/onboarding/custom-information/utils';
import { UserTypes } from 'kennek/interfaces/accounts';

export interface LeaseEstateCustomInfoProps {
  onBack?: () => void;
  onContinue?: () => void;
  loanId: string;
  initialValues: CustomInformationFields[];
  isEdit?: boolean;
  isRestructure?: boolean;
  refetch?: () => void;
}

export interface LeaseEstateCustomInfoForm {
  residualValue: number;
  higherUpfrontPayment: number;
  progressivePaymentSchedule: number;
  tvaOrNot: boolean;
  comment?: string;
  estimatedWorth?: number;
  dateEstimated?: string;
  estimatedBy?: string;
  freeText?: string;
  dateOfConstruction?: string;
  dateOfRenovation?: string;
  surfaceOfPlot?: number;
  builtSurface?: number;
}

export interface LeaseEstateFieldsVisibility {
  key: string;
  visibility: UserTypes[];
}

export const customFieldsVisibility: LeaseEstateFieldsVisibility[] = [
  {
    key: 'residualValue',
    visibility: ['ORIGINATOR_ADMIN', 'BORROWER'],
  },
  {
    key: 'higherUpfrontPayment',
    visibility: ['ORIGINATOR_ADMIN', 'BORROWER'],
  },
  {
    key: 'progressivePaymentSchedule',
    visibility: ['ORIGINATOR_ADMIN', 'BORROWER'],
  },
  {
    key: 'tvaOrNot',
    visibility: ['ORIGINATOR_ADMIN', 'BORROWER'],
  },
  {
    key: 'comment',
    visibility: ['ORIGINATOR_ADMIN', 'BORROWER'],
  },
  {
    key: 'estimatedWorth',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'dateEstimated',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'estimatedBy',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'freeText',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'dateOfConstruction',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'dateOfRenovation',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'surfaceOfPlot',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'builtSurface',
    visibility: ['ORIGINATOR_ADMIN'],
  },
];

export const emptyLeaseEstateCustomInformation: LeaseEstateCustomInfoForm = {
  residualValue: 0,
  higherUpfrontPayment: 0,
  progressivePaymentSchedule: 0,
  tvaOrNot: false,
  comment: '',

  estimatedWorth: 0,
  dateEstimated: '',
  estimatedBy: '',
  freeText: '',
  dateOfConstruction: '',
  dateOfRenovation: '',
  surfaceOfPlot: 0,
  builtSurface: 0,
};
