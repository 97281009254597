import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useTypedSelector } from '@/store/hooks';
import { UserForm } from './UserInformationForm';
import type { AdditionalDetailsFormType } from './loan-details/AdditionalDetailsForm';
import type { LoanInformationFormType } from './loan-details/LoanInformationForm';
import type { ScheduleFormType } from './loan-details/ScheduleForm';
import { AssetsFormInput } from './loanAssets/LoanAssets.utils';
import { Loan } from 'kennek/interfaces/loans';
import { RowLoanFacility } from '@/interfaces/loans/facility';
import { BorrowerCompany } from '@/interfaces/originator/borrower-company';

export type OnboardingState = {
  isActive: boolean;
  company: {
    form: BorrowerCompany;
  };
  borrower: {
    form: UserForm;
    shouldSaveDraft: boolean;
  };
  loanInformation: {
    form: LoanInformationFormType;
    shouldSaveDraft: boolean;
    productTypeKey: string;
  };
  schedule: {
    form: ScheduleFormType;
    shouldSaveDraft: boolean;
    shouldOverrideFormWithRules: boolean;
  };
  additionalDetails: {
    form: AdditionalDetailsFormType;
    shouldSaveDraft: boolean;
    shouldOverrideFormWithRules: boolean;
    draftLoan?: Loan;
  };
  assets: {
    form: AssetsFormInput[];
    shouldSaveDraft: boolean;
    shouldOverrideFormWithRules: boolean;
  };
  loanFacilities?: RowLoanFacility[];
  borrowerCompanyExternalId?: string;
  originatorExternalId?: string;
  flow: 'FirstLoan' | 'MultiLoan';
};

const INITIAL_STATE: OnboardingState = {
  isActive: false,
  company: {
    form: undefined,
  },
  borrower: {
    form: undefined,
    shouldSaveDraft: false,
  },
  loanInformation: {
    form: undefined,
    shouldSaveDraft: false,
    productTypeKey: undefined,
  },
  schedule: {
    form: undefined,
    shouldSaveDraft: false,
    shouldOverrideFormWithRules: false,
  },
  additionalDetails: {
    form: undefined,
    shouldSaveDraft: false,
    shouldOverrideFormWithRules: false,
    draftLoan: undefined,
  },
  assets: {
    form: undefined,
    shouldSaveDraft: false,
    shouldOverrideFormWithRules: false,
  },
  loanFacilities: [],
  flow: 'FirstLoan',
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: INITIAL_STATE,
  reducers: {
    // company
    onContinueCompany(
      state,
      action: PayloadAction<{ form: BorrowerCompany; isDirty: boolean }>
    ) {
      if (action.payload.isDirty) {
        state.company.form = action.payload.form;
      }
    },
    onContinueWithExistingCompany(
      state,
      action: PayloadAction<{
        borrowerCompanyExternalId: string;
        originatorExternalId: string;
      }>
    ) {
      state.borrowerCompanyExternalId =
        action.payload.borrowerCompanyExternalId;
      state.originatorExternalId = action.payload.originatorExternalId;
      state.flow = 'MultiLoan';
    },
    onCompanyUpdated(state) {
      state.company = INITIAL_STATE.company;
    },
    // borrower
    onEnterBorrower(state) {
      state.borrower.shouldSaveDraft = true;
    },
    onExitBorrower(
      state,
      action: PayloadAction<{ form: UserForm; isDirty: boolean }>
    ) {
      if (state.isActive && state.borrower.shouldSaveDraft) {
        state.borrower.shouldSaveDraft = false;

        if (action.payload.isDirty) {
          state.borrower.form = action.payload.form;
        }
      }
    },
    onBorrowerCreated(state) {
      state.company = INITIAL_STATE.company;
      state.borrower = INITIAL_STATE.borrower;
    },
    onBorrowerUpdated(state) {
      state.borrower = INITIAL_STATE.borrower;
    },
    onBorrowerClientCreated(
      state,
      action: PayloadAction<{
        borrowerCompanyExternalId: string;
        originatorExternalId: string;
      }>
    ) {
      state.borrowerCompanyExternalId =
        action.payload.borrowerCompanyExternalId;
      state.originatorExternalId = action.payload.originatorExternalId;
    },
    // loan information
    onEnterLoanInformation(state) {
      state.loanInformation.shouldSaveDraft = true;
    },
    onExitLoanInformation(
      state,
      action: PayloadAction<{
        form: LoanInformationFormType;
        loanFacilities?: RowLoanFacility[];
        isDirty: boolean;
      }>
    ) {
      if (state.isActive && state.loanInformation.shouldSaveDraft) {
        state.loanInformation.shouldSaveDraft = false;

        if (action.payload.isDirty) {
          state.loanInformation.form = action.payload.form;
          state.loanFacilities = action.payload.loanFacilities;
        }
      }
    },
    onContinueLoanInformation(
      state,
      action: PayloadAction<{
        form: LoanInformationFormType;
        loanFacilities?: RowLoanFacility[];
        isDirty: boolean;
      }>
    ) {
      state.loanInformation.shouldSaveDraft = false;

      if (action.payload.isDirty) {
        state.loanInformation.form = action.payload.form;
      }
      state.loanFacilities = action.payload.loanFacilities;
    },
    onChangeLoanProductType(
      state,
      { payload }: PayloadAction<{ productTypeKey: string }>
    ) {
      state.loanInformation.productTypeKey = payload.productTypeKey;
      state.schedule.shouldOverrideFormWithRules = true;
      state.additionalDetails.shouldOverrideFormWithRules = true;
      state.loanFacilities = [];
    },
    // schedule
    onEnterSchedule(state) {
      state.schedule.shouldSaveDraft = true;
    },
    onContinueSchedule(
      state,
      action: PayloadAction<{ form: ScheduleFormType; isDirty: boolean }>
    ) {
      state.schedule.shouldSaveDraft = false;
      state.schedule.shouldOverrideFormWithRules = false;

      const isLoanInformationDirty = !!state.loanInformation.form;

      if (action.payload.isDirty || isLoanInformationDirty) {
        state.schedule.form = action.payload.form;
      }
    },
    onExitSchedule(
      state,
      action: PayloadAction<{ form: ScheduleFormType; isDirty: boolean }>
    ) {
      if (state.isActive && state.schedule.shouldSaveDraft) {
        state.schedule.shouldSaveDraft = false;
        state.schedule.shouldOverrideFormWithRules = false;

        const isLoanInformationDirty = !!state.loanInformation.form;

        if (action.payload.isDirty || isLoanInformationDirty) {
          state.schedule.form = action.payload.form;
        }
      }
    },
    // additional details
    onEnterAdditionalDetails(state) {
      state.additionalDetails.shouldSaveDraft = true;
      state.additionalDetails.shouldOverrideFormWithRules = true;
    },
    onExitAdditionalDetails(
      state,
      action: PayloadAction<{
        form: AdditionalDetailsFormType;
        isDirty: boolean;
      }>
    ) {
      if (state.isActive && state.additionalDetails.shouldSaveDraft) {
        state.additionalDetails.shouldSaveDraft = false;
        state.additionalDetails.shouldOverrideFormWithRules = false;

        const isLoanInformationDirty = !!state.loanInformation.form;
        const isScheduleDirty = !!state.schedule.form;

        if (
          action.payload.isDirty ||
          !!isLoanInformationDirty ||
          !!isScheduleDirty
        ) {
          state.additionalDetails.form = action.payload.form;
        }
      }
    },
    onEnterAssetInformation(state) {
      state.assets.shouldSaveDraft = true;
    },
    onExitAssetsInformation(
      state,
      action: PayloadAction<{
        form: AssetsFormInput[];
        isDirty: boolean;
      }>
    ) {
      if (state.assets.shouldSaveDraft) {
        state.assets.shouldSaveDraft = false;
        state.assets.shouldOverrideFormWithRules = false;
        if (action.payload.isDirty) {
          state.assets.form = action.payload.form;
        }
      }
    },
    onSetDraftLoan(state, action: PayloadAction<Loan>) {
      state.additionalDetails.draftLoan = action.payload;
    },
    onLoanCreated(state) {
      state.loanInformation = INITIAL_STATE.loanInformation;
      state.schedule = INITIAL_STATE.schedule;
      state.additionalDetails = INITIAL_STATE.additionalDetails;
    },
    onLoanUpdated(state) {
      state.loanInformation = INITIAL_STATE.loanInformation;
      state.schedule = INITIAL_STATE.schedule;
      state.additionalDetails = INITIAL_STATE.additionalDetails;
    },
    // general
    onEnterOnboarding(state) {
      state.isActive = true;
    },
    onExitOnboarding() {
      return INITIAL_STATE;
    },
  },
});

export const {
  onBorrowerCreated,
  onBorrowerUpdated,
  onBorrowerClientCreated,
  onEnterBorrower,
  onExitBorrower,
  onCompanyUpdated,
  onContinueCompany,
  onContinueWithExistingCompany,
  onEnterLoanInformation,
  onExitLoanInformation,
  onContinueLoanInformation,
  onEnterAdditionalDetails,
  onExitAdditionalDetails,
  onEnterAssetInformation,
  onExitAssetsInformation,
  onLoanCreated,
  onLoanUpdated,
  onSetDraftLoan,
  onChangeLoanProductType,
  onEnterOnboarding,
  onExitOnboarding,
  onContinueSchedule,
  onEnterSchedule,
  onExitSchedule,
} = onboardingSlice.actions;

export function useSelectDraftLoan() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) =>
      onboarding.additionalDetails.draftLoan
  );
}

export function useSelectCompanyForm() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) => onboarding.company.form
  );
}

export function useSelectBorrowerForm() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) =>
      onboarding.borrower.form
  );
}

export function useSelectLoanInformationForm() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) =>
      onboarding.loanInformation.form
  );
}

export function useSelectLoanFacilities() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) =>
      onboarding.loanFacilities
  );
}

export function useSelectRepaymentsForm() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) =>
      onboarding.schedule.form
  );
}

export function useSelectIsLoanInformationFormReady() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) =>
      !onboarding.loanInformation.shouldSaveDraft
  );
}

export function useSelectIsScheduleFormReady() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) =>
      !onboarding.schedule.shouldSaveDraft
  );
}

export function useSelectAdditionalDetailsForm() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) =>
      onboarding.additionalDetails.form
  );
}

export function useSelectAssetsForm() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) => onboarding.assets.form
  );
}

export function useSelectShouldOverrideScheduleWithRules() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) =>
      onboarding.schedule.shouldOverrideFormWithRules
  );
}

export function useSelectShouldOverrideAdditionalDetailsWithRules() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) =>
      onboarding.additionalDetails.shouldOverrideFormWithRules
  );
}

export function useSelectLoanProductTypeKey() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) =>
      onboarding.loanInformation.productTypeKey
  );
}

export function useSelectBorrowerCompanyExternalId() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) =>
      onboarding.borrowerCompanyExternalId
  );
}

export function useSelectOriginatorExternalId() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) =>
      onboarding.originatorExternalId
  );
}

export function useSelectOnboardingFlow() {
  return useTypedSelector(
    ({ onboarding }: { onboarding: OnboardingState }) => onboarding.flow
  );
}

export default onboardingSlice.reducer;
