import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { debounce, isEmpty } from 'lodash';
import { Autocomplete, Button, FormInput, Select, Title } from 'ui';
import { Option } from 'ui/components/Select';
import { useDebouncedCallback } from 'use-debounce';
import * as yup from 'yup';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { COMPANY } from '@/constants/numeric';
import { COMPANY_MESSAGES } from '@/constants/onboarding';
import { SNACKBAR_ONBOARDING } from '@/constants/snackbar-messages';
import { BorrowerType } from '@/enums/borrower-type.enum';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useValidateCompanyCreationMutation } from '@/services/accounts/borrower';
import {
  useEditLoanModificationMutation,
  useEditLoanMutation,
} from '@/services/loans/edits';
import {
  useGetCompanyQuery,
  useLazyGetCompanyByIdQuery,
} from '@/services/originator';
import { useUpdateBorrowerCompanyMutation } from '@/services/originator/borrower-company';
import { isFetchBaseQueryError } from '@/services/utils';
import { useAppDispatch } from '@/store/hooks';
import { useSelectUser } from '@/store/user/selectors';
import {
  onCompanySaved,
  onEditSaved,
  useSelectEditId,
} from '../edit-loan/editLoanSlice';
import { onCompanyUpdated, onContinueCompany } from './onboardingSlice';
import { User } from 'kennek/interfaces/accounts';
import { CompanyByIdQueryResponse } from 'kennek/interfaces/originator';
import { Loan } from '@/interfaces/loans';
import { EditLoanPayload } from '@/interfaces/loans/queries';
import {
  BorrowerCompany,
  UpdateBorrowerCompanyPayload,
} from '@/interfaces/originator/borrower-company';

enum CompanyFlow {
  NEW = 'NEW',
  REUSE = 'REUSE',
  DISABLED = 'DISABLED',
}

export interface CompanyInformationProps {
  onContinue: () => void;
  onBack: () => void;
  openReuseCompanyModal?: (
    borrowerCompanyExternalId: string,
    originatorExternalId: string
  ) => void;
  borrower: User;
  isEdit: boolean;
  isReview: boolean;
  initialFormValues: BorrowerCompany;
  loanInfo?: Loan;
  formFlow?: 'ONBOARDING' | 'REFINANCE';
  showInitialValuesForm?: boolean;
  borrowerCompanyExternalId: string;
}

const CompanyInformationForm: React.FC<CompanyInformationProps> = ({
  onBack,
  onContinue,
  openReuseCompanyModal,
  isEdit,
  borrower,
  isReview,
  initialFormValues,
  loanInfo,
  formFlow = 'ONBOARDING',
  showInitialValuesForm = false,
  borrowerCompanyExternalId,
}) => {
  const dispatch = useAppDispatch();
  const labelsConfig = useGetLabelsConfig();
  const [queryCompanyFilter, setQueryCompanyFilter] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);
  const [openOnFocusCompanyOptions, setOpenOnFocusCompanyOptions] =
    useState(false);
  const [showInitValuesForm, setShowInitValuesForm] = useState(false);

  const [borrowerType, setBorrowerType] = useState(BorrowerType.COMPANY);
  const [companyFlow, setCompanyFlow] = useState<CompanyFlow>(CompanyFlow.NEW);
  const { borrowerUpper, borrowerLower } = useGetLabelsConfig();
  const onChangeDebouncedAutocomplete = useDebouncedCallback(
    () => setQueryCompanyFilter(partialForm.getValues('autocompletedCompany')),
    500
  );

  const [validateCompany] = useValidateCompanyCreationMutation();

  const [countryOptions, setCountryOptions] = useState<Option[]>([
    {
      value: 'United Kingdom',
      label: 'United Kingdom',
    },
  ]);

  const onChangeLoader = useCallback(
    debounce((val) => {
      setIsLoading(val);
    }, 500),
    []
  );

  const borrowerTypeOptions = [
    {
      value: BorrowerType.COMPANY,
      label: 'Company',
    },
    {
      value: BorrowerType.INDIVIDUAL,
      label: 'Individual',
    },
  ];

  useEffect(() => {
    if (borrowerCompanyExternalId) {
      setCompanyFlow(CompanyFlow.DISABLED);
    }
  }, [borrowerCompanyExternalId]);

  useEffect(() => {
    const val = partialForm.getValues('autocompletedCompany');
    if (val) {
      setQueryCompanyFilter(val);
      setOpenOnFocusCompanyOptions(true);
    } else {
      onChangeLoader(false);
    }
  }, []);

  const [completeForm, setCompleteForm] = useState(isEdit || isReview);

  useEffect(() => {
    setShowInitValuesForm(showInitialValuesForm);
    if (initialFormValues?.borrowerType)
      setBorrowerTypeValue(initialFormValues?.borrowerType);
  }, [showInitialValuesForm]);

  useEffect(() => {
    setCompleteForm(completeForm || showInitValuesForm);
  }, [showInitValuesForm, completeForm, setCompleteForm]);

  const userOriginator = useSelectUser();

  const { data: companies = [], ...companiesqueries } =
    useGetCompanyQuery(queryCompanyFilter);

  const partialForm = useForm<{ autocompletedCompany: string }>({
    defaultValues: {
      autocompletedCompany:
        initialFormValues?.name && initialFormValues?.number
          ? `${initialFormValues.name} (${initialFormValues.number})`
          : '',
    },
    mode: 'onSubmit',
  });

  const { isDirty: isPartialFormDirty } = partialForm.formState;

  const { autocompletedCompany: isAutocompletedCompanyFieldDirty } =
    partialForm.formState.dirtyFields;

  const [getCompanyById] = useLazyGetCompanyByIdQuery();

  const [updateBorrowerCompany, { isLoading: isUpdating }] =
    useUpdateBorrowerCompanyMutation();

  const snackbar = useSnackbar();

  const fullForm = useForm<
    BorrowerCompany & { isCompanyDateCreationEnabled: string }
  >({
    defaultValues: !isEmpty(initialFormValues)
      ? {
          ...initialFormValues,
          borrowerType: initialFormValues.borrowerType ?? BorrowerType.COMPANY,
          country: 'United Kingdom',
        }
      : undefined,
    shouldFocusError: false,
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { isDirty: isFullFormDirty } = fullForm.formState;

  const fillCompanyForm = (company: BorrowerCompany) => {
    setCountryOptions([
      {
        value: company?.country ?? 'United Kingdom',
        label: company?.country ?? 'United Kingdom',
      },
    ]);
    fullForm.setValue('name', company?.name);
    fullForm.setValue('number', company?.number);
    fullForm.setValue('addressLine1', company?.addressLine1);
    fullForm.setValue('addressLine2', company?.addressLine2);
    fullForm.setValue('city', company?.city);
    fullForm.setValue('region', company?.region);
    fullForm.setValue('postalCode', company.postalCode);
    fullForm.setValue('country', company.country);
    fullForm.setValue('sicCode', company.sicCode);
    fullForm.setValue('borrowerType', BorrowerType.COMPANY);
  };

  const onCompanySelectedChange = (id: string) => {
    setIsLoadingCompany(true);
    getCompanyById(id, true).then((response) => {
      const company = mapDtoToForm(response.data);
      fillCompanyForm(company);

      validateCompany({
        companyNumber: company?.number,
      })
        .unwrap()
        .then((validationResponse) => {
          if (validationResponse.status === 'Valid') {
            setCompleteForm(true);
          } else if (validationResponse.status === 'Reusable') {
            fillCompanyForm(validationResponse.prepopulatedFields.company);
            onReuseCompany();
          } else if (validationResponse.status === 'Non-Company') {
            onNonCompanyBorrower();
          }
        })
        .catch(() => {
          snackbar.show({
            severity: 'error',
            title:
              SNACKBAR_ONBOARDING.COMPANY_UPDATE_FAILED_TITLE(labelsConfig),
            content: SNACKBAR_ONBOARDING.COMPANY_FAILED_INTERNAL_ERROR,
          });
        })
        .finally(() => setIsLoadingCompany(false));
    });
  };

  const setBorrowerTypeValue = (type = BorrowerType.COMPANY) => {
    setBorrowerType(type);
    fullForm.setValue('borrowerType', type);
  };

  const onBorrowerTypeChange = (value) => {
    const type: BorrowerType =
      BorrowerType[value?.target?.value as BorrowerType];
    setBorrowerTypeValue(type);
  };

  const onCantFindCompany = () => {
    fullForm.clearErrors();
    fullForm.reset(EMPTY_FORM);
    setCompleteForm(true);
    setQueryCompanyFilter('');
    setShowInitValuesForm(false);
    setBorrowerTypeValue(BorrowerType.COMPANY);
    setCompanyFlow(CompanyFlow.NEW);
  };

  const onNonCompanyBorrower = () => {
    fullForm.clearErrors();
    fullForm.reset(EMPTY_FORM);
    setCompleteForm(true);
    setQueryCompanyFilter('');
    setShowInitValuesForm(false);
    setBorrowerTypeValue(BorrowerType.INDIVIDUAL);
    setCompanyFlow(CompanyFlow.NEW);
  };

  const onSearchNewCompany = () => {
    partialForm.clearErrors();
    partialForm.reset({ autocompletedCompany: '' });
    setCompleteForm(false);
    partialForm.clearErrors();
    fullForm.clearErrors();
    setShowInitValuesForm(false);
    setBorrowerTypeValue(BorrowerType.COMPANY);
    setCompanyFlow(CompanyFlow.NEW);
  };

  const onReuseCompany = () => {
    setCompanyFlow(CompanyFlow.REUSE);
    setCompleteForm(true);
  };

  const onSubmitPartialForm = partialForm.handleSubmit(() => {
    onContinue();
  });

  const handleOnSubmitCompleteForm = (data) => {
    if (!isEdit) {
      onContinue();
      dispatch(
        onContinueCompany({
          form: data,
          isDirty:
            isPartialFormDirty || isFullFormDirty || openOnFocusCompanyOptions,
        })
      );
    } else if (formFlow === 'REFINANCE') {
      handleEditCompanyInfo(data);
    } else {
      const payload: UpdateBorrowerCompanyPayload = {
        company: data,
        userId: borrower._id,
        mambuBranchEncodedKey:
          userOriginator?.mambuUser?.[0]?.mambuBranchEncodedKey,
        id: borrower?.borrowerCompanyIds[0],
      };
      updateBorrowerCompany(payload)
        .unwrap()
        .then(() => {
          dispatch(onCompanyUpdated());
          snackbar.show({
            severity: 'primary',
            title: SNACKBAR_ONBOARDING.COMPANY_UPDATE_SUCCESS,
          });
          onContinue();
        })
        .catch((error) => {
          setShowInitValuesForm(true);
          if (isFetchBaseQueryError(error) && error.status === 400) {
            snackbar.show({
              severity: 'error',
              title:
                SNACKBAR_ONBOARDING.COMPANY_UPDATE_FAILED_TITLE(labelsConfig),
              content:
                SNACKBAR_ONBOARDING.COMPANY_UPDATE_FAILED_COMPANY_MISMATCH,
            });
          } else if (isFetchBaseQueryError(error) && error.status === 404) {
            snackbar.show({
              severity: 'error',
              title:
                SNACKBAR_ONBOARDING.COMPANY_UPDATE_FAILED_TITLE(labelsConfig),
              content:
                SNACKBAR_ONBOARDING.COMPANY_UPDATE_FAILED_USER_NOT_FOUND(
                  labelsConfig
                ),
            });
          } else {
            snackbar.show({
              severity: 'error',
              title:
                SNACKBAR_ONBOARDING.COMPANY_UPDATE_FAILED_TITLE(labelsConfig),
              content: SNACKBAR_ONBOARDING.COMPANY_FAILED_INTERNAL_ERROR,
            });
          }
        });
    }
  };

  const onSubmitFactory = (callback: (formValues: any) => void) => {
    if (companyFlow === CompanyFlow.REUSE) {
      const formValues = fullForm.getValues();
      return () => callback(formValues);
    } else {
      return fullForm.handleSubmit(callback);
    }
  };

  const onSubmitCompleteForm = onSubmitFactory((data) => {
    if ((data as { number?: string })?.number?.length > 0) {
      validateCompany({
        companyNumber: (data as { number?: string })?.number,
      })
        .unwrap()
        .then((validationResponse) => {
          if (validationResponse.status === 'Valid') {
            handleOnSubmitCompleteForm(data);
          } else if (validationResponse.status === 'Reusable') {
            if (borrower?.borrowerExecutionIds?.length ?? 0) {
              onContinue();
              dispatch(
                onContinueCompany({
                  form: data,
                  isDirty:
                    isPartialFormDirty ||
                    isFullFormDirty ||
                    openOnFocusCompanyOptions,
                })
              );
            }
            openReuseCompanyModal(
              validationResponse.prepopulatedFields.borrowerCompanyExternalId,
              validationResponse.prepopulatedFields.originatorExternalId
            );
          }
        })
        .catch(() => {
          snackbar.show({
            severity: 'error',
            title:
              SNACKBAR_ONBOARDING.COMPANY_UPDATE_FAILED_TITLE(labelsConfig),
            content: SNACKBAR_ONBOARDING.COMPANY_FAILED_INTERNAL_ERROR,
          });
        });
    } else {
      handleOnSubmitCompleteForm(data);
    }
  });

  const editId = useSelectEditId();

  const [editLoan, { isLoading: isEditingLoan }] = useEditLoanMutation();
  const [editLoanModification, { isLoading: isPatchingEditingLoan }] =
    useEditLoanModificationMutation();

  const handleEditCompanyInfo = (formData) => {
    const editPayload: EditLoanPayload = {
      type: 'BORROWER_COMPANY',
      action: 'POST',
      requestData: {
        company: formData,
        userId: borrower._id,
        mambuBranchEncodedKey:
          userOriginator?.mambuUser?.[0]?.mambuBranchEncodedKey,
        id: borrower?.borrowerCompanyIds[0],
      },
    };

    if (editId) {
      editLoanModification({
        editInfo: editPayload,
        loanEncodedKey: loanInfo?.encodedKey,
        id: editId,
      })
        .unwrap()
        .then(() => {
          dispatch(
            onCompanySaved({
              company: formData,
              userId: borrower._id,
              mambuBranchEncodedKey:
                userOriginator?.mambuUser?.[0]?.mambuBranchEncodedKey,
              id: borrower?.borrowerCompanyIds[0],
            })
          );
          onContinue();
        })
        .catch(() => {
          snackbar.show({
            severity: 'error',
            title: 'Error editing',
          });
          onContinue();
        });
    } else {
      editLoan({
        editInfo: editPayload,
        loanEncodedKey: loanInfo?.encodedKey,
      })
        .unwrap()
        .then((data) => {
          dispatch(
            onCompanySaved({
              company: formData,
              userId: borrower._id,
              mambuBranchEncodedKey:
                userOriginator?.mambuUser?.[0]?.mambuBranchEncodedKey,
              id: borrower?.borrowerCompanyIds[0],
            })
          );
          dispatch(onEditSaved(data._id));
          onContinue();
        })
        .catch(() => {
          snackbar.show({
            severity: 'error',
            title: 'Error editing',
          });
          onContinue();
        });
    }
  };

  useEffect(() => {
    onChangeLoader(completeForm ? companiesqueries.isFetching : false);
  }, [companiesqueries.isFetching]);

  const companyNameLabel =
    borrowerType === BorrowerType.COMPANY ? 'Company name' : 'Full name';

  const isIndividual = borrowerType === BorrowerType.INDIVIDUAL;

  const isCompanyDateCreationEnabled =
    ((completeForm && queryCompanyFilter === '' && !showInitValuesForm) ||
      initialFormValues?.source === 'MANUAL') &&
    !isIndividual;

  // workaround to add a condition into validation schema
  useEffect(() => {
    fullForm.register('isCompanyDateCreationEnabled');
    fullForm.setValue(
      'isCompanyDateCreationEnabled',
      isCompanyDateCreationEnabled ? 'ENABLED' : 'DISABLED'
    );
  }, [fullForm, isCompanyDateCreationEnabled]);

  const isLoadingButton =
    isUpdating || isEditingLoan || isPatchingEditingLoan || isLoadingCompany;

  if (isLoading) return <LoadingAnimationPlat />;

  const isFormDisabled =
    companyFlow === CompanyFlow.DISABLED || companyFlow === CompanyFlow.REUSE;

  const sectionTitle = `${completeForm ? borrowerUpper : 'Company'} information`;

  return (
    <div className="xl:w-6/12">
      <Title
        title={sectionTitle}
        icon={<OfficeBuildingIcon />}
        titleSize="lg"
        className="mt-2 mb-10"
      />
      <form
        onSubmit={(e) => e.preventDefault()}
        className="form stepper-form"
        noValidate
        autoComplete="off"
      >
        {!completeForm ? (
          <div>
            <Controller
              name="autocompletedCompany"
              control={partialForm.control}
              shouldUnregister
              rules={{
                validate: (value) => {
                  if (!value) return COMPANY_MESSAGES.REQUIRED_NAME_OR_NUMBER;

                  if (
                    isAutocompletedCompanyFieldDirty &&
                    !companies?.some(
                      (company) =>
                        `${company?.name} (${company?.number})` === value
                    )
                  )
                    return COMPANY_MESSAGES.SEARCH_COMPANY_NOT_FOUND;

                  return true;
                },
              }}
              render={({ field, fieldState }) => (
                <>
                  <label className="relative heading-100 mb-2 select-none">
                    Company name or number
                    <Autocomplete
                      value={field.value}
                      onSearch={(value) => {
                        setOpenOnFocusCompanyOptions(false);
                        field.onChange(value);
                        onChangeDebouncedAutocomplete();
                      }}
                      onChange={onCompanySelectedChange}
                      openOnFocus={openOnFocusCompanyOptions}
                      results={
                        companies.length > 0
                          ? companies.map((company) => ({
                              id: company.number,
                              label: `${company.name} (${company.number})`,
                            }))
                          : companiesqueries.isFetching
                            ? [{ id: '0', label: 'Loading...' }]
                            : []
                      }
                      placeholder="Company name or number"
                      error={!!fieldState.error}
                    />
                    {fieldState.error && (
                      <ExclamationCircleIcon className="absolute text-error-500 w-5 h-5 bottom-[0.55rem] right-3" />
                    )}
                  </label>
                  <div className="body-200 text-error-700 mt-2">
                    {fieldState?.error?.message}
                  </div>

                  {!completeForm && (
                    <div
                      className="text-secondary-400 cursor-pointer flex items-center heading-200"
                      onClick={onCantFindCompany}
                    >
                      I can’t find my company
                    </div>
                  )}
                </>
              )}
            />

            <DevTool control={partialForm.control} />
          </div>
        ) : (
          <div className="flex flex-col">
            {((completeForm &&
              queryCompanyFilter === '' &&
              !showInitValuesForm) ||
              isIndividual ||
              initialFormValues?.source === 'MANUAL') && (
              <div className="flex items-center justify-between gap-3">
                <Select
                  label="Type"
                  name="borrowerType"
                  value={borrowerType}
                  placeholder="Type"
                  options={borrowerTypeOptions}
                  onChange={onBorrowerTypeChange}
                  disabled={isFormDisabled}
                  className={'grow'}
                />
              </div>
            )}
            <div className="flex items-center justify-between gap-3">
              <FormInput
                label={companyNameLabel}
                placeholder={companyNameLabel}
                errors={fullForm.formState.errors?.name?.message}
                {...fullForm.register('name')}
                className={classNames({
                  'w-[190px]': queryCompanyFilter && !showInitValuesForm,
                })}
                disabled={isFormDisabled}
                containerClassName={'grow'}
              />
            </div>
            {queryCompanyFilter !== '' &&
              !showInitValuesForm &&
              !isIndividual && (
                <FormInput
                  label="Company number"
                  placeholder="Company number"
                  errors={fullForm.formState.errors?.number?.message}
                  {...fullForm.register('number')}
                  className="w-[190px]"
                  disabled={
                    (queryCompanyFilter !== '' && !showInitValuesForm) ||
                    isFormDisabled
                  }
                />
              )}
            {isCompanyDateCreationEnabled && (
              <FormInput
                label="Company date of creation"
                type="date"
                disabled={isFormDisabled}
                errors={
                  fullForm.formState.errors?.companyDateOfCreation?.message
                }
                {...fullForm.register('companyDateOfCreation')}
              />
            )}
            {!isIndividual && (
              <FormInput
                label="Industry Code"
                placeholder="Industry / sic Code"
                disabled={isFormDisabled}
                type="number"
                errors={fullForm.formState.errors?.sicCode?.message}
                {...fullForm.register('sicCode')}
                bottomLabel="Optional"
              />
            )}
            <FormInput
              label="Address line 1"
              placeholder="Main address"
              disabled={isFormDisabled}
              errors={fullForm.formState.errors?.addressLine1?.message}
              {...fullForm.register('addressLine1')}
            />
            <FormInput
              label="Address line 2"
              placeholder="Secondary address"
              disabled={isFormDisabled}
              errors={fullForm.formState.errors?.addressLine2?.message}
              bottomLabel="Optional"
              {...fullForm.register('addressLine2')}
            />
            <div className="flex items-center justify-between gap-3">
              <FormInput
                label="City / Town"
                placeholder="City/Town"
                disabled={isFormDisabled}
                errors={fullForm.formState.errors?.city?.message}
                {...fullForm.register('city')}
                className="w-[220px]"
              />

              <FormInput
                label="Region"
                placeholder="Region"
                disabled={isFormDisabled}
                errors={fullForm.formState.errors?.region?.message}
                {...fullForm.register('region')}
                className="w-[160px]"
              />

              <FormInput
                label="Postal code"
                placeholder="Postal code"
                disabled={isFormDisabled}
                errors={fullForm.formState.errors?.postalCode?.message}
                {...fullForm.register('postalCode')}
                className="w-[160px]"
              />
            </div>
            <div className="mt-2 flex flex-col flex-1">
              <Controller
                control={fullForm.control}
                name="country"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Select
                    label="Country"
                    name="country"
                    placeholder="Country"
                    disabled={isFormDisabled}
                    value={value}
                    options={countryOptions}
                    onChange={onChange}
                    error={error?.message}
                  />
                )}
              />
            </div>
            <DevTool control={fullForm.control} />
          </div>
        )}

        <div className="flex justify-between">
          {isReview && (
            <div className="w-[95px]">
              <Button onClick={onBack} layout="ghost" disabled={isUpdating}>
                Cancel
              </Button>
            </div>
          )}

          {!completeForm && (
            <div
              className="text-secondary-400 cursor-pointer flex items-center heading-300"
              onClick={onNonCompanyBorrower}
            >
              Add non-company {borrowerLower}
            </div>
          )}

          {completeForm && (
            <Button
              onClick={onSearchNewCompany}
              layout="ghost"
              disabled={isLoadingButton}
            >
              Search new company
            </Button>
          )}

          <div
            className={classNames('flex justify-end', {
              'w-[95px]': isReview,
            })}
          >
            <Button
              onClick={
                completeForm ? onSubmitCompleteForm : onSubmitPartialForm
              }
              type="submit"
              loading={isLoadingButton}
              disabled={isLoadingButton}
            >
              {isReview
                ? 'Save'
                : isEdit && completeForm
                  ? 'Save and continue'
                  : 'Continue'}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

const EMPTY_FORM: BorrowerCompany = {
  name: '',
  number: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: '',
  postalCode: '',
  sicCode: null,
  borrowerType: BorrowerType.COMPANY,
};

export const mapDtoToForm = (
  company: CompanyByIdQueryResponse
): BorrowerCompany => {
  return {
    name: company?.name,
    number: company?.number,
    addressLine1: company?.address,
    city: company?.city,
    country: company?.country ?? 'United Kingdom',
    postalCode: company?.postal_code,
    region: company?.region,
    sicCode: Number(company?.industry_code),
    borrowerType: BorrowerType.COMPANY,
  };
};

const schema = yup
  .object({
    name: yup.string().required(COMPANY_MESSAGES.REQUIRED_NAME),
    number: yup.string(),
    sicCode: yup
      .number()
      .typeError(COMPANY_MESSAGES.REQUIRED_SIC_CODE)
      .max(
        COMPANY.MAX_SIC_CODE,
        COMPANY_MESSAGES.MAX_SIC_CODE(COMPANY.MAX_SIC_CODE)
      )
      .min(
        COMPANY.MIN_SIC_CODE,
        COMPANY_MESSAGES.MIN_SIC_CODE(COMPANY.MIN_SIC_CODE)
      )
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .optional(),
    addressLine1: yup.string().required(COMPANY_MESSAGES.REQUIRED_ADDRESS),
    addressLine2: yup.string(),
    city: yup.string().required(COMPANY_MESSAGES.REQUIRED_CITY),
    postalCode: yup
      .string()
      .min(3, COMPANY_MESSAGES.INVALID_POSTAL_CODE)
      .required(COMPANY_MESSAGES.REQUIRED_POSTAL_CODE),
    country: yup.string().required(COMPANY_MESSAGES.REQUIRED_COUNTRY),
    companyDateOfCreation: yup.string().when('isCompanyDateCreationEnabled', {
      is: 'ENABLED',
      then: yup
        .string()
        .required(COMPANY_MESSAGES.REQUIRED_COMPANY_DATE_OF_CREATION),
      otherwise: yup.string().nullable(),
    }),
  })
  .required();

export { CompanyInformationForm };
