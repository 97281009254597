import React, { useMemo } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { Button, Table } from 'ui';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { ROUTES } from '@/constants/routes';
import { useRole } from '@/hooks/useRole';
import { getRouterQuery } from '@/utils/helpers';
import { UserTypes } from 'kennek/interfaces/accounts';
import { Column } from 'ui/types';
import { LoanSummary } from '@/interfaces/loans/queries';
import { Schedule } from '@/interfaces/schedules';

export interface Props extends React.ComponentProps<React.FC> {
  title: string;
  loanSummary: LoanSummary;
  loanReports: Schedule[];
  isLoading?: boolean;
}

const ReportsTable: React.FC<Props> = ({
  title,
  loanReports,
  loanSummary,
  isLoading,
}) => {
  const searchParams = useSearchParams();
  const loanId = searchParams.get('loanId');

  const router = useRouter();

  const { role } = useRole();

  const permissions = ROLES_PERMISSIONS[role];

  const tableColumns = useMemo(() => {
    return [
      {
        Header: 'Report Name',
        accessor: 'name',
        maxWidth: 170,
        minWidth: 170,
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        Cell: ({ value }: { value: Schedule['frequency'] }) => (
          <span className="capitalize">
            {value?.replaceAll('_', ' ').toLowerCase()}
          </span>
        ),
        maxWidth: 170,
        minWidth: 170,
      },
      {
        Header: 'Due Date',
        accessor: 'deadline.days',
        Cell: ({ row: { original } }: { row: { original: Schedule } }) => {
          return `${
            original?.deadline?.days
          } ${original?.deadline?.dayType?.toLowerCase()} days`;
        },
        maxWidth: 170,
        minWidth: 170,
      },
      {
        Header: 'Calculation Date',
        accessor: 'deadline.calculationDate',
        Cell: ({
          value,
        }: {
          value: Schedule['deadline']['calculationDate'];
        }) => {
          return (
            <span className="capitalize">
              {value?.replaceAll('_', ' ').toLowerCase()}
            </span>
          );
        },
        maxWidth: 170,
        minWidth: 170,
      },
    ];
  }, []);

  return (
    <Table.Container variant="compact" isLoading={isLoading}>
      <Table.Header>
        <Table.Header.Left>
          <Table.Name title={title} leftIcon={<DocumentTextIcon />} />
        </Table.Header.Left>

        <Table.Header.Right>
          <Button
            layout="ghost"
            size="small"
            onClick={() => {
              const path = getRouterQuery(ROUTES.SERVICING_TASKS, { loanId });
              router.push(path);
            }}
          >
            View tasks
          </Button>

          {permissions?.includes('EDIT') &&
            loanSummary?.state === 'APPROVED' && (
              <Button
                layout="ghost"
                size="small"
                onClick={() => {
                  const path = getRouterQuery(ROUTES.REPORTS, { loanId });
                  router.push(path);
                }}
              >
                Edit
              </Button>
            )}
        </Table.Header.Right>
      </Table.Header>

      <Table.Body
        columns={tableColumns as Column<object>[]}
        data={
          loanReports
            ? [...loanReports].sort((a, b) => (a.name <= b.name ? -1 : 1))
            : []
        }
        emptyTableMessage="No reports available"
        maxHeight
      />
    </Table.Container>
  );
};

export default ReportsTable;

const PERMISSIONS = ['EDIT'] as const;

const ROLES_PERMISSIONS: Partial<
  Record<UserTypes, (typeof PERMISSIONS)[number][]>
> = {
  ORIGINATOR_ADMIN: ['EDIT'],
  ORIGINATOR_ANALYST: ['EDIT'],
};
