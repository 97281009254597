import {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import classNames from 'classnames';
import { addDays, format, subDays } from 'date-fns';
import { produce } from 'immer';
import { SvgIcon } from 'kennek/icons';
import { isNumber, startCase } from 'lodash';
import { Button, Dialog, FormInput, Select, Title } from 'ui';
import { DevTool } from '@hookform/devtools';
import ImportSchedule, {
  FileData,
} from '@/components/loans/import-schedule/ImportSchedule';
import EditTranchesContent from '@/components/sections/tranches/editTranches';
import { useTranchesValidator } from '@/components/sections/tranches/useTranchesValidator';
import {
  RawTranche,
  getMambuProductType,
} from '@/components/sections/tranches/utils';
import { fileTypes } from '@/constants/fileTypes';
import { ABSOLUTE_MAX_DATE, ABSOLUTE_MIN_DATE } from '@/constants/numeric';
import {
  LOAN_MESSAGES,
  LOAN_MESSAGES_BY_PRODUCT,
} from '@/constants/onboarding';
import { LABELS } from '@/constants/productTypeDictionary';
import { FILE_VALIDATION } from '@/constants/snackbar-messages';
import { CounterpartyForm } from '@/features/counterparty/CounterpartyForm';
import { CounterpartySelectInput } from '@/features/counterparty/CounterpartySelectInput';
import {
  onScheduleSaved,
  useSelectShouldOverrideScheduleWithRules as useSelectShouldOverrideScheduleWithRulesRefinance,
} from '@/features/edit-loan/editLoanSlice';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useLoanDetailsOverwriteLabel } from '@/hooks/useLoanDetailsOverwriteLabel';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useValidateRepaymentScheduleMutation } from '@/services/loans';
import {
  useCreateCounterpartyMutation,
  useGetOriginatorCounterpartiesQuery,
} from '@/services/loans/counterparty';
import { useAppDispatch } from '@/store/hooks';
import { checkDateIsInTheFuture } from '@/utils/dates';
import { zonedDate } from '@/utils/datetime';
import { formatDate } from '@/utils/formatters';
import { base64ToFile, fileToBase64 } from '@/utils/helpers';
import {
  onContinueSchedule,
  onEnterSchedule,
  onExitSchedule,
  useSelectShouldOverrideScheduleWithRules as useSelectShouldOverrideScheduleWithRulesOnboarding,
} from '../onboardingSlice';
import { checkIsFutureDisbursement } from '../utils';
import { EMPTY_FORM } from './ScheduleContainer';
import {
  mapFromRawForm,
  mapToRawForm,
  setDefaultFormFields,
} from './ScheduleForm.utils';
import InterestGraceSection from './interest-grace-section/InterestGraceSection';
import { RawScheduleForm } from './raw-schedule-form';
import { prettifyImportedScheduleValidationErrors } from './utils';
import { GetConfigurationProductResponse } from 'kennek/interfaces/kennek/queries';
import { Product } from 'kennek/interfaces/products';
import {
  CreateCounterpartyDto,
  InterestGraceHandleMethod,
  Loan,
  LoanProductRules,
  RepaymentPeriodUnits,
} from '@/interfaces/loans';
import { LoanFacility, RowLoanFacility } from '@/interfaces/loans/facility';

export type ScheduleFormType = {
  repaymentInstallments: number;
  payCurrentInstallment: number;
  minimumInterestPeriod: number;
  monthlyRepaymentDay: number;
  repaymentPeriodCount: number;
  repaymentPeriodUnits: RepaymentPeriodUnits;
  principalCollectionFrequency?: number;
  paymentHoliday: number;
  interestGraceInstallments?: number;
  firstRepaymentDate: string;
  disbursementDate: string;
  counterpartyId?: string;
  tranches: {
    disbursementDate: string;
    disbursementAmount: number;
    fees: Record<string, { amount: number; enabled: boolean }>;
    facilities?: Partial<RowLoanFacility>[];
    counterpartyId?: string;
  }[];
  rollUpDuration?: number;
  interestGraceHandleMethod?: InterestGraceHandleMethod;
  importedSchedule?: FileData;
};

export interface ScheduleProps {
  onContinue: () => void;
  onBack: (arg?: ScheduleFormType) => void;
  initialFormValues: ScheduleFormType;
  loanProductRules: LoanProductRules;
  loanAmount: number;
  configurationProductData: GetConfigurationProductResponse;
  formFlow: 'ONBOARDING' | 'REFINANCE';
  loanFacilities: RowLoanFacility[] | LoanFacility[];
  originalLoan?: Loan;
  products?: Product[];
}

const ScheduleForm = ({
  onBack,
  onContinue,
  initialFormValues,
  loanProductRules,
  loanAmount,
  configurationProductData,
  formFlow,
  loanFacilities,
  originalLoan,
  products,
}: ScheduleProps) => {
  const [importedSchedule, setImportedSchedule] = useState<FileData | null>(
    initialFormValues.importedSchedule || null
  );
  const [importedScheduleErrors, setImportedScheduleErrors] = useState<
    string[]
  >([]);

  const dispatch = useAppDispatch();
  const { borrowerLower } = useGetLabelsConfig();
  const [
    validateRepaymentSchedule,
    { isLoading: isValidatingRepaymentSchedule },
  ] = useValidateRepaymentScheduleMutation();

  const emptyTranche = mapToRawForm({
    scheduleForm: EMPTY_FORM,
    loanProductRules,
  }).tranches?.[0];

  const isRevenueBasedLoan =
    configurationProductData?.otherConfigurations?.revenueBasedFactorLoan;
  const repaymentPeriodCount = loanProductRules?.prefilledFields?.find(
    (field) => field.name === 'repaymentPeriodCount'
  )?.value as number;

  const repaymentPeriodUnits = loanProductRules?.prefilledFields?.find(
    (field) => field.name === 'repaymentPeriodUnits'
  )?.value as RepaymentPeriodUnits;

  const overrideWithRules = produce<ScheduleFormType>((draft) => {
    const repaymentInstallments =
      loanProductRules?.rules?.repaymentInstallments?.defaultValue;

    const paymentHoliday =
      loanProductRules?.rules?.paymentHoliday?.defaultValue;

    const principalCollectionFrequency =
      loanProductRules?.principalCollectionFrequency > 1;

    if (isNumber(repaymentInstallments)) {
      draft.repaymentInstallments = repaymentInstallments;
    }

    if (paymentHoliday || paymentHoliday === 0) {
      draft.paymentHoliday = paymentHoliday;
    }

    if (repaymentPeriodCount) {
      draft.repaymentPeriodCount = repaymentPeriodCount;
    }

    if (repaymentPeriodUnits) {
      draft.repaymentPeriodUnits = repaymentPeriodUnits;
    }

    if (principalCollectionFrequency) {
      draft.principalCollectionFrequency =
        loanProductRules?.principalCollectionFrequency;
    }

    // if user changes product type, to another that also has tranches, start over filling tranches
    if (loanProductRules?.maxNumberOfTranches > 0) {
      draft.tranches = EMPTY_FORM.tranches;
    }
  });
  const shouldOverrideValueWithRules = flowAction.override[formFlow]();
  const initialForm = shouldOverrideValueWithRules
    ? overrideWithRules(initialFormValues)
    : initialFormValues;

  const form = useForm<RawScheduleForm>({
    shouldFocusError: false,
    defaultValues: mapToRawForm({
      scheduleForm: initialForm,
      loanProductRules: loanProductRules,
    }),
    mode: 'onSubmit',
  });

  const {
    register,
    formState: { errors, touchedFields, isDirty },
    getValues: getFormValues,
    setValue,
  } = form;

  const productType = useMemo(() => {
    return configurationProductData?.payment?.blockPaymentTillLoanMatured
      ? 'blockedUntilMatured'
      : 'default';
  }, [configurationProductData?.payment?.blockPaymentTillLoanMatured]);

  const importRepaymentSchedule =
    configurationProductData?.features?.importRepaymentSchedule;

  const mambuProductType = useMemo(
    () =>
      getMambuProductType(
        products,
        configurationProductData?.productEncodedKey
      ),
    [products, configurationProductData?.productEncodedKey]
  );

  const tranchesValidator = useTranchesValidator(mambuProductType);

  const fields = useFieldArray({
    control: form.control,
    name: 'tranches',
    shouldUnregister: true,
    rules:
      loanProductRules?.maxNumberOfTranches > 0
        ? {
            validate: (value: RawTranche[]): string | true =>
              tranchesValidator.validateTranchesSummaryFields(
                value,
                loanAmount,
                loanProductRules.currency,
                null // last due date impossible to obtain without schedule
              ),
          }
        : undefined,
  });

  const touchedFieldsRef = useRef(touchedFields);

  useLayoutEffect(() => {
    touchedFieldsRef.current = touchedFields;
  }, [touchedFields]);

  const isDirtyRef = useRef(isDirty);

  useLayoutEffect(() => {
    isDirtyRef.current = isDirty;
  }, [isDirty]);

  const formValuesRef = useRef<RawScheduleForm>();

  useLayoutEffect(() => {
    // workaround to get tranches on exit
    // `getFormValues()` inside useEffect's cleanup function returns `tranches: undefined`
    formValuesRef.current = getFormValues();
  });

  useEffect(() => {
    dispatch(onEnterSchedule());
    return () => {
      dispatch(
        onExitSchedule({
          form: mapFromRawForm(formValuesRef.current, importedSchedule),
          isDirty: isDirtyRef.current,
        })
      );
    };
  }, [dispatch, getFormValues, importedSchedule]);

  const onResetDefaultRepaymentInstallments = () => {
    form.setValue(
      'repaymentInstallments',
      loanProductRules?.rules?.repaymentInstallments?.defaultValue
    );
    form.clearErrors('repaymentInstallments');
  };

  const onSubmit = form.handleSubmit(async (data) => {
    const isImportScheduleValid = await handleRepaymentScheduleValidation();
    if (!isImportScheduleValid) return;

    onContinue();

    setDefaultFormFields(data, !!loanProductRules.interestGrace);

    dispatch(
      flowAction.saveAndContinue[formFlow]({
        form: mapFromRawForm(data, importedSchedule),
        isDirty,
      })
    );
  });

  const handleRepaymentScheduleValidation = async (): Promise<boolean> => {
    if (!importRepaymentSchedule || !importedSchedule) return true;
    try {
      const file = base64ToFile(
        importedSchedule.base64File,
        importedSchedule.fileName,
        importedSchedule.type
      );
      const validateData = new FormData();
      const instalments = form.watch('repaymentInstallments');
      validateData.append('file', file, importedSchedule.fileName);
      validateData.append('loanAmount', loanAmount.toString());
      validateData.append('repaymentInstallments', instalments.toString());

      return validateRepaymentSchedule(validateData)
        .unwrap()
        .then(() => {
          return true;
        })
        .catch((e) => {
          setImportedScheduleErrors(
            e?.data?.message
              ? prettifyImportedScheduleValidationErrors(e?.data?.message)
              : []
          );
          return false;
        });
    } catch (e) {
      return false;
    }
  };

  const firstRepaymentDate = form.watch('firstRepaymentDate');

  useEffect(() => {
    const monthlyRepaymentDay =
      tranchesValidator.getMonthlyRepaymentDay(firstRepaymentDate);

    if (Number.isNaN(monthlyRepaymentDay)) return;

    setValue('monthlyRepaymentDay', monthlyRepaymentDay);
    setValue(
      'principalCollectionFrequency',
      loanProductRules?.principalCollectionFrequency
    );
  }, [
    firstRepaymentDate,
    loanProductRules?.principalCollectionFrequency,
    setValue,
  ]);
  const loanWithTranches = useMemo(
    () => loanProductRules?.maxNumberOfTranches > 0,
    [loanProductRules?.maxNumberOfTranches]
  );

  const repaymentPeriodUnitsOptions = useMemo(() => {
    const opts = loanProductRules?.repaymentPeriodUnits?.map((item) => ({
      value: item,
      label: startCase(item.toLowerCase()),
    }));
    return opts || [];
  }, [loanProductRules?.repaymentPeriodUnits]);

  const isRefinanceFlow = formFlow === 'REFINANCE';
  const isOnboardingFlow = formFlow === 'ONBOARDING';

  const isInterestGraceHandleMethodEnabled =
    loanProductRules?.interestGraceHandleMethodEnabled &&
    !loanProductRules?.payCurrentEnabled;

  const repaymentPeriodBottomLabel =
    isRefinanceFlow &&
    originalLoan?.scheduleSettings?.repaymentPeriodCount &&
    originalLoan?.scheduleSettings?.repaymentPeriodUnits;

  const overwriteLabel = useLoanDetailsOverwriteLabel();
  const { firstRepaymentDayLabel, deductedInterestLabel } = useMemo(() => {
    const firstRepaymentDayLabel = overwriteLabel.getPropertyLabel(
      LABELS[productType].firstRepaymentDate,
      'FIRST_REPAYMENT_DATE',
      configurationProductData?.grace
    );
    const deductedInterestLabel = overwriteLabel.getPropertyLabel(
      'Deduct on disbursement',
      'DEDUCTED_INTREST',
      configurationProductData?.grace
    );
    return { firstRepaymentDayLabel, deductedInterestLabel };
  }, [configurationProductData?.grace, productType]);

  const validateDisbursementDate = (disbursementDate: string) => {
    const isFutureDisbursement = checkIsFutureDisbursement(
      disbursementDate,
      mambuProductType,
      configurationProductData?.otherConfigurations.paymentsInAdvance
    );

    if (isRefinanceFlow || isFutureDisbursement) {
      return;
    }

    if (checkDateIsInTheFuture(zonedDate(disbursementDate))) {
      return LOAN_MESSAGES.DISBURSEMENT_DATE_IN_THE_FUTURE;
    }
  };

  const snackbar = useSnackbar();

  // ? COUNTERPARTIES HANDLING START
  const [counterpartyDialogOpen, setCounterpartyDialogOpen] = useState(false);

  const getCounterpartiesQuery = useGetOriginatorCounterpartiesQuery(
    {},
    {
      skip: !(
        loanProductRules?.disbursementBankAccountDetails && !loanWithTranches
      ),
    }
  );

  const [createCounterparty, createCounterpartyMutationState] =
    useCreateCounterpartyMutation();

  const createCounterpartySubmitHandler = async (
    data: CreateCounterpartyDto
  ) => {
    try {
      const newCounterparty = await createCounterparty(data).unwrap();
      form.setValue(`counterpartyId`, newCounterparty.id);
      snackbar.show({
        severity: 'success',
        title: 'Counterparty created successfully.',
      });
      setCounterpartyDialogOpen(false);
      getCounterpartiesQuery.refetch();
    } catch (err) {
      snackbar.show({
        severity: 'error',
        title: 'Failed to create counterparty.',
      });
    }
  };
  // ! COUNTERPARTIES HANDLING END

  const handleFileSelection = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files[0];
    if (!file) return;
    if (file.type !== fileTypes.xlsx) {
      snackbar.show({
        severity: 'error',
        title: FILE_VALIDATION.FILE_UPLOAD_FAILED_XLSX_EXTENSION,
      });
      return;
    }
    const base64File = await fileToBase64(file);
    setImportedSchedule({
      fileName: file.name,
      base64File,
      type: file.type,
    });
  };

  return (
    <>
      {loanProductRules?.disbursementBankAccountDetails &&
        !loanWithTranches && (
          <Dialog
            open={counterpartyDialogOpen}
            onClose={() => setCounterpartyDialogOpen(false)}
          >
            <CounterpartyForm
              isLoading={createCounterpartyMutationState.isLoading}
              onCancel={() => setCounterpartyDialogOpen(false)}
              onSubmit={(data) => createCounterpartySubmitHandler(data)}
            />
          </Dialog>
        )}

      <div className="flex flex-col gap-8">
        <Title
          title="Schedule"
          icon={<SvgIcon name="StatementIcon" />}
          titleSize="lg"
          className="mt-2 mb-1"
        />

        <form
          className="form stepper-form"
          onSubmit={(e) => e.preventDefault()}
          autoComplete="off"
          noValidate
        >
          <div className="form-input">
            {!loanWithTranches ? (
              <FormInput
                label="Disbursement date"
                {...register('disbursementDate', {
                  required: LOAN_MESSAGES.REQUIRED_DISBURSEMENT_END,
                  min: {
                    value: isRefinanceFlow
                      ? format(new Date(), 'yyyy-MM-dd')
                      : ABSOLUTE_MIN_DATE,
                    message: isRefinanceFlow
                      ? LOAN_MESSAGES.MIN_DATE_DISBURSEMENT_DATE
                      : LOAN_MESSAGES.MIN_DATE_ABSOLUTE(
                          formatDate(ABSOLUTE_MIN_DATE, 'dd-MM-yyyy')
                        ),
                  },
                  max: isRefinanceFlow
                    ? {
                        value: format(new Date(), 'yyyy-MM-dd'),
                        message: `Disbursement date cannot be a future date`,
                      }
                    : form.watch('firstRepaymentDate') && {
                        value: format(
                          subDays(
                            new Date(form.watch('firstRepaymentDate')),
                            1
                          ),
                          'yyyy-MM-dd'
                        ),
                        message: `Disbursement date cannot be same or after ${firstRepaymentDayLabel}`,
                      },
                  validate: validateDisbursementDate,
                })}
                min={isRefinanceFlow ? format(new Date(), 'yyyy-MM-dd') : null}
                type="date"
                errors={errors?.disbursementDate?.message}
                max="3000-12-31"
              />
            ) : null}
            <FormInput
              label={firstRepaymentDayLabel}
              {...register('firstRepaymentDate', {
                required: LOAN_MESSAGES_BY_PRODUCT[
                  productType
                ].REQUIRED_FIRST_REPAYMENT_DATE(firstRepaymentDayLabel),
                min: {
                  value: isRefinanceFlow
                    ? format(addDays(new Date(), 1), 'yyyy-MM-dd')
                    : null,
                  message: LOAN_MESSAGES.MIN_DATE_REPAYMENT_DATE,
                },
                max: {
                  value: ABSOLUTE_MAX_DATE,
                  message: LOAN_MESSAGES.MAX_DATE_ABSOLUTE(
                    formatDate(ABSOLUTE_MAX_DATE, 'dd-MM-yyyy')
                  ),
                },
                onChange: () => {
                  form.clearErrors('disbursementDate');
                  form.clearErrors('firstRepaymentDate');
                  form.clearErrors('monthlyRepaymentDay');
                },
              })}
              type="date"
              errors={errors?.firstRepaymentDate?.message}
              min={
                !loanWithTranches && form.watch('disbursementDate')
                  ? format(
                      addDays(new Date(form.watch('disbursementDate')), 1),
                      'yyyy-MM-dd'
                    )
                  : null
              }
              max="3000-12-31"
              bottomLabel={
                isRefinanceFlow && originalLoan?.firstRepaymentDate
                  ? formatDate(originalLoan?.firstRepaymentDate)
                  : null
              }
              bottomLabelTopic={
                isRefinanceFlow && originalLoan?.firstRepaymentDate
                  ? `Original ${firstRepaymentDayLabel}: `
                  : null
              }
            />
            {isRefinanceFlow && originalLoan?.firstRepaymentDate && (
              <div
                className={classNames({
                  'mb-[13px]':
                    isRefinanceFlow && !errors?.firstRepaymentDate?.message,
                })}
              />
            )}
            {loanProductRules?.variableRequiredFields?.find(
              (field) => field.name === 'monthlyRepaymentDay'
            ) ? (
              <FormInput
                label={LABELS[productType].monthlyRepaymentDay}
                placeholder="Day of the month"
                type="number"
                disabled={tranchesValidator.isMonthlyRepaymentDisabled(
                  firstRepaymentDate
                )}
                questionTooltip={
                  configurationProductData?.payment?.blockPaymentTillLoanMatured
                    ? null
                    : 'Day to perform a repayment'
                }
                errors={errors?.monthlyRepaymentDay?.message}
                {...register('monthlyRepaymentDay', {
                  valueAsNumber: true,
                  required: LOAN_MESSAGES.REQUIRED_MONTHLY_REPAYMENT_DAY(
                    LABELS[productType].monthlyRepaymentDay
                  ),
                  max: {
                    value: 31,
                    message: LOAN_MESSAGES.WRONG_MONTHLY_REPAYMENT_DAY,
                  },
                  min: {
                    value: 1,
                    message: LOAN_MESSAGES.WRONG_MONTHLY_REPAYMENT_DAY,
                  },
                  validate: (values) => {
                    if (
                      tranchesValidator.isFirstRepaymentDateLastDayOfMonth(
                        firstRepaymentDate
                      )
                    )
                      return;
                    return (
                      values ===
                        Number(
                          form.watch('firstRepaymentDate')?.split('-')?.[2]
                        ) ||
                      LOAN_MESSAGES_BY_PRODUCT[
                        productType
                      ].MISMATCH_REPAYMENT_DAY(
                        formatDate(
                          form.watch('firstRepaymentDate'),
                          'dd-MM-yyyy'
                        )
                      )
                    );
                  },
                })}
              />
            ) : (
              <div className="flex justify-between gap-5 mt-1">
                <FormInput
                  label={LABELS[productType].repaymentPeriodCount}
                  disabled={isRevenueBasedLoan}
                  errors={errors?.repaymentPeriodCount?.message}
                  placeholder="Number"
                  {...register('repaymentPeriodCount', {
                    valueAsNumber: true,
                    required: LOAN_MESSAGES.REQUIRED_REPAYMENT_PERIOD_COUNT,
                    max: {
                      value: 1000,
                      message: LOAN_MESSAGES.MAX_VALUE_REPAYMENT_PERIOD_COUNT,
                    },
                    min: {
                      value: 0,
                      message: LOAN_MESSAGES.MIN_VALUE_REPAYMENT_PERIOD_COUNT,
                    },
                  })}
                  type="number"
                  questionTooltip="Frequency"
                  className="w-[155px]"
                  bottomLabel={
                    repaymentPeriodBottomLabel
                      ? `${originalLoan?.scheduleSettings?.repaymentPeriodCount} ${originalLoan?.scheduleSettings?.repaymentPeriodUnits}`
                      : null
                  }
                  bottomLabelTopic={
                    repaymentPeriodBottomLabel ? 'Original: ' : null
                  }
                  noMargins
                />

                <div className="relative h-[84px] w-[235px]">
                  <Controller
                    name="repaymentPeriodUnits"
                    control={form.control}
                    rules={{
                      required: LOAN_MESSAGES.REQUIRED_REPAYMENT_PERIOD_UNIT,
                    }}
                    render={({ field }) => (
                      <Select
                        label={LABELS[productType].repaymentPeriodUnits}
                        ref={field.ref}
                        name={field.name}
                        disabled={isRevenueBasedLoan}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        value={field.value}
                        options={repaymentPeriodUnitsOptions}
                        placeholder="Select one..."
                        error={errors?.repaymentPeriodUnits?.message}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            {loanProductRules?.paymentHoliday && (
              <>
                <FormInput
                  label="Payment holiday"
                  questionTooltip="Period where no repayment is required"
                  errors={errors?.paymentHoliday?.message}
                  placeholder="In instalments"
                  type="number"
                  {...register('paymentHoliday', {
                    valueAsNumber: true,
                    required: 'Payment holiday cannot be empty',
                    max: form.watch('repaymentInstallments') && {
                      value: form.watch('repaymentInstallments') - 1,
                      message:
                        'Payment holiday has to be less than Repayment Instalments',
                    },
                    min: {
                      value: 0,
                      message: 'Cannot be less than 0',
                    },
                    onChange: () => {
                      form.clearErrors('paymentHoliday');
                    },
                  })}
                />
              </>
            )}
            {isRefinanceFlow &&
              originalLoan?.scheduleSettings?.repaymentPeriodCount >= 0 &&
              originalLoan?.scheduleSettings?.repaymentPeriodUnits && (
                <div
                  className={classNames({
                    'mb-[13px]':
                      isRefinanceFlow &&
                      (!errors?.repaymentPeriodUnits?.message ||
                        !errors?.repaymentPeriodCount?.message),
                  })}
                />
              )}
            <FormInput
              label={LABELS[productType].repaymentInstallments}
              questionTooltip={
                configurationProductData?.payment?.blockPaymentTillLoanMatured
                  ? null
                  : 'Number of repayments'
              }
              errors={errors?.repaymentInstallments?.message}
              placeholder="Number of instalments"
              type="number"
              defaultValue={
                loanProductRules?.rules?.repaymentInstallments?.defaultValue !==
                  form.watch('repaymentInstallments') &&
                loanProductRules?.rules?.repaymentInstallments?.defaultValue
              }
              defaultValueSetter={onResetDefaultRepaymentInstallments}
              {...register('repaymentInstallments', {
                valueAsNumber: true,
                required: LOAN_MESSAGES.REQUIRED_REPAYMENT_INSTALLMENTS(
                  LABELS[productType].repaymentInstallments
                ),
                max: {
                  value: loanProductRules?.rules?.repaymentInstallments
                    ?.maxValue
                    ? loanProductRules?.rules?.repaymentInstallments?.maxValue
                    : undefined,
                  message: tranchesValidator.getMaxInstallmentErrorMessage(
                    loanProductRules?.rules?.repaymentInstallments?.maxValue
                  ),
                },
                min: {
                  value: loanProductRules?.rules?.repaymentInstallments
                    ?.minValue
                    ? loanProductRules?.rules?.repaymentInstallments?.minValue
                    : 0,
                  message: LOAN_MESSAGES.MIN_VALUE_REPAYMENT_INSTALLMENTS(
                    loanProductRules?.rules?.repaymentInstallments?.minValue ||
                      0
                  ),
                },
                validate: () => {
                  const minFrequency = form.watch(
                    'principalCollectionFrequency'
                  );

                  return minFrequency &&
                    minFrequency > form.watch('repaymentInstallments')
                    ? `Cannot be less than Principal collection frequency`
                    : true;
                },
                onChange: () => {
                  form.clearErrors('repaymentInstallments');
                  form.clearErrors('paymentHoliday');
                },
              })}
              bottomLabel={
                isRefinanceFlow &&
                originalLoan?.scheduleSettings?.repaymentInstallments
                  ? originalLoan?.scheduleSettings?.repaymentInstallments?.toString()
                  : null
              }
              bottomLabelTopic={
                isRefinanceFlow &&
                originalLoan?.scheduleSettings?.repaymentInstallments
                  ? `Original ${LABELS[productType].repaymentInstallments}: `
                  : null
              }
            />

            {loanProductRules?.minimumInterestPeriod && (
              <FormInput
                label="Minimum interest period"
                errors={errors?.minimumInterestPeriod?.message}
                placeholder="In instalments"
                type="number"
                {...register('minimumInterestPeriod', {
                  valueAsNumber: true,
                  required: LOAN_MESSAGES.REQUIRED_MINIMUM_INTEREST_PERIOD,
                  max: form.watch('repaymentInstallments') && {
                    value: form.watch('repaymentInstallments'),
                    message:
                      LOAN_MESSAGES.MINIMUM_INTEREST_PERIOD_SMALLER_EQUAL,
                  },
                })}
              />
            )}

            {loanProductRules?.payCurrentEnabled && (
              <FormInput
                label={'Pay current interest from'}
                questionTooltip={`Moment from when the ${borrowerLower} starts paying the current interest`}
                errors={errors?.payCurrentInstallment?.message}
                placeholder="Instalment number"
                type="number"
                {...register('payCurrentInstallment', {
                  valueAsNumber: true,
                  required: LOAN_MESSAGES.REQUIRED_PAY_CURRENT_INSTALMENTS,
                  max: form.watch('repaymentInstallments') && {
                    value: form.watch('repaymentInstallments') - 1,
                    message:
                      'Pay current interest from has to be less than Repayment Instalments',
                  },
                })}
              />
            )}

            {(loanProductRules?.rollUpDuration ||
              loanProductRules?.rollUpDuration2) && (
              <FormInput
                label="Roll-up duration"
                errors={errors?.rollUpDuration?.message}
                placeholder="In instalments"
                type="number"
                {...register('rollUpDuration', {
                  valueAsNumber: true,
                  required: LOAN_MESSAGES.REQUIRED_ROLL_UP_DURATION,
                  min: {
                    value: 0,
                    message: LOAN_MESSAGES.MIN_VALUE_ROLL_UP_DURATION,
                  },
                  validate: (val) => {
                    const maxValue = form.watch('repaymentInstallments') ?? 0;
                    return maxValue < val
                      ? LOAN_MESSAGES.MAX_VALUE_ROLL_UP_DURATION
                      : true;
                  },
                  onChange: () => form.clearErrors('rollUpDuration'),
                })}
              />
            )}

            {isInterestGraceHandleMethodEnabled && (
              <InterestGraceSection
                errors={errors}
                deductedInterestLabel={deductedInterestLabel}
                form={form}
                isEpcInterestGraceEnabled={loanProductRules.interestGrace}
                isInterestGraceHandleMethodDefaultChecked={
                  isRefinanceFlow &&
                  loanAmount <= originalLoan?.balances?.principalBalance
                }
                isInterestGraceHandleMethodSelected={
                  formValuesRef.current?.interestGraceHandleMethod !== null
                }
                isInterestGraceHandleMethodVisible={
                  isOnboardingFlow ||
                  (isRefinanceFlow &&
                    loanAmount > originalLoan?.balances?.principalBalance)
                }
                register={register}
              />
            )}

            {isRefinanceFlow &&
              originalLoan?.scheduleSettings?.repaymentInstallments >= 0 && (
                <div
                  className={classNames({
                    'mb-[13px]':
                      isRefinanceFlow &&
                      !errors?.repaymentInstallments?.message,
                  })}
                />
              )}
            {loanWithTranches ? (
              <>
                <label className="heading-100 text-neutral-800 mb-5 block">
                  Disbursement tranches
                </label>

                <EditTranchesContent
                  firstRepaymentDayLabel={firstRepaymentDayLabel}
                  firstRepaymentDate={form.watch('firstRepaymentDate')}
                  fields={
                    fields as unknown as UseFieldArrayReturn<
                      {
                        tranches: RawTranche[];
                      },
                      'tranches',
                      'id'
                    >
                  }
                  form={
                    form as unknown as UseFormReturn<{ tranches: RawTranche[] }>
                  }
                  mambuProductType={mambuProductType}
                  loanProductRules={loanProductRules}
                  emptyTranche={emptyTranche as RawTranche}
                  loanAmount={loanAmount}
                  variant="onboarding"
                  facilitiesOptions={loanFacilities}
                  formFlow={formFlow}
                  originalLoan={originalLoan}
                />
              </>
            ) : null}

            {loanProductRules?.disbursementBankAccountDetails &&
              !loanWithTranches && (
                <Controller
                  control={form.control}
                  name={`counterpartyId`}
                  rules={{
                    validate: (value) =>
                      (value !== '' && value !== undefined && value !== null) ||
                      'Counterparty account cannot be empty.',
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <CounterpartySelectInput
                        disabled={
                          getCounterpartiesQuery.isFetching ||
                          getCounterpartiesQuery.isLoading
                        }
                        counterparties={
                          getCounterpartiesQuery.currentData ?? []
                        }
                        onValueChange={(value) => {
                          form.clearErrors(`counterpartyId`);
                          field.onChange(value);
                        }}
                        value={form.watch(`counterpartyId`)}
                        error={error?.message}
                        onAddNewClick={() => {
                          setCounterpartyDialogOpen(true);
                        }}
                      />
                    );
                  }}
                />
              )}
            {importRepaymentSchedule && (
              <ImportSchedule
                handleFileSelection={handleFileSelection}
                importedSchedule={importedSchedule}
                setImportedSchedule={setImportedSchedule}
                validationErrors={importedScheduleErrors}
                setValidationErrors={setImportedScheduleErrors}
              />
            )}
          </div>

          <div className="flex justify-between">
            <Button
              layout="ghost"
              onClick={() =>
                onBack(
                  isRefinanceFlow ? mapFromRawForm(form.getValues()) : null
                )
              }
              disabled={isValidatingRepaymentSchedule}
              loading={isValidatingRepaymentSchedule}
            >
              Back
            </Button>

            <Button
              layout="primary"
              onClick={onSubmit}
              disabled={isValidatingRepaymentSchedule}
              loading={isValidatingRepaymentSchedule}
            >
              Continue
            </Button>
          </div>

          <DevTool control={form.control} />
        </form>
      </div>
    </>
  );
};

const flowAction = {
  override: {
    ONBOARDING: useSelectShouldOverrideScheduleWithRulesOnboarding,
    REFINANCE: useSelectShouldOverrideScheduleWithRulesRefinance,
  },
  saveAndContinue: {
    ONBOARDING: onContinueSchedule,
    REFINANCE: onScheduleSaved,
  },
};

export { ScheduleForm };
