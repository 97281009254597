import ArrowIcon from './ArrowIcon';
import BankIcon from './BankIcon';
import ChainIcon from './ChainIcon';
import CircleIcon from './CircleIcon';
import Clipboard from './Clipboard';
import ClockIcon from './ClockIcon';
import CloseIcon from './CloseIcon';
import CloseModalIcon from './CloseModalIcon';
import CodatWarningIcon from './CodatWarningIcon';
import CopyIcon from './CopyIcon';
import CovenantIcon from './CovenantIcon';
import DeleteIcon from './DeleteIcon';
import DisburseIcon from './DisburseIcon';
import DocumentsIcon from './DocumentsIcon';
import EditColumnsIcon from './EditColumnsIcon';
import EditInterestRateIcon from './EditInterestRateIcon';
import ErrorIcon from './ErrorIcon';
import FileSearchIcon from './FileSearchIcon';
import FiltersIcon from './FiltersIcon';
import IndividualIcon from './IndividualIcon';
import InfoIcon from './InfoIcon';
import InvestorIcon from './InvestorIcon';
import LenderOfRecordIcon from './LenderOfRecordIcon';
import LoansIcon from './LoansIcon';
import LockClosedIcon from './LockClosedIcon';
import MoneyIcon from './MoneyIcon';
import MyFolder from './MyFolder';
import NeutralIcon from './NeutralIcon';
import PayOffIcon from './PayOffIcon';
import PortfolioIcon from './PortfolioIcon';
import ProgressBarThumbIcon from './ProgressBarThumbIcon';
import RateHistoryIcon from './RateHistoryIcon';
import RepaymentsIcon from './RepaymentsIcon';
import ReplyIcon from './ReplyIcon';
import RightIcon from './RightIcon';
import RiskAnalysisIcon from './RiskAnalysisIcon';
import SendIcon from './SendIcon';
import StackIcon from './StackIcon';
import StatementIcon from './StatementIcon';
import TooltipIcon from './TooltipIcon';
import UnderwritingApplicationsIcon from './UnderwritingApplicationsIcon';
import UnderwritingIcon from './UnderwritingIcon';
import WarningIcon from './WarningIcon';

export const icons = {
  ArrowIcon,
  BankIcon,
  ChainIcon,
  CircleIcon,
  Clipboard,
  ClockIcon,
  CloseIcon,
  CloseModalIcon,
  CodatWarningIcon,
  CopyIcon,
  CovenantIcon,
  DeleteIcon,
  DisburseIcon,
  DocumentsIcon,
  EditColumnsIcon,
  EditInterestRateIcon,
  ErrorIcon,
  FileSearchIcon,
  FiltersIcon,
  IndividualIcon,
  InfoIcon,
  InvestorIcon,
  LenderOfRecordIcon,
  LoansIcon,
  MoneyIcon,
  NeutralIcon,
  PayOffIcon,
  PortfolioIcon,
  ProgressBarThumbIcon,
  RepaymentsIcon,
  ReplyIcon,
  RightIcon,
  RiskAnalysisIcon,
  SendIcon,
  StackIcon,
  StatementIcon,
  TooltipIcon,
  UnderwritingIcon,
  WarningIcon,
  RateHistoryIcon,
  MyFolder,
  UnderwritingApplicationsIcon,
  LockClosedIcon,
};
