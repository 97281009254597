import React, { useMemo, useReducer, useState } from 'react';
import classNames from 'classnames';
import { Surface, SurfaceProps } from '../Surface';
import styles from './TableContainer.module.css';
import { TableContext } from './TableContext';
import { Loading } from './TableLoading';
import { PaginationProps } from './TablePagination';

type TableContainerProps = {
  children: React.ReactNode;
  isLoading?: boolean;
  initialPageSize?: number;
  variant?: TableVariantType;
  withoutHeaderOffset?: boolean;
  serverSidePagination?: boolean;
  serverSideSort?: boolean;
  border?: SurfaceProps['border'];
  dashboard?: boolean;
  loaderStandardColor?: boolean;
  loadingHeight?: string;
  noPaddingX?: boolean;
};

export type TableVariantType = (typeof TableVariant)[keyof typeof TableVariant];

export const TableVariant = {
  COMPACT: 'compact',
  STANDARD: 'standard',
  DASHBOARD: 'dashboard',
} as const;

const Container = ({
  isLoading = false,
  children,
  variant = TableVariant.STANDARD,
  withoutHeaderOffset = false,
  serverSidePagination,
  serverSideSort,
  border = 'light',
  dashboard,
  loaderStandardColor,
  loadingHeight,
  noPaddingX,
}: TableContainerProps) => {
  const [{ hasPagination }, setHasPagination] = useReducer(
    () => ({ hasPagination: true }),
    { hasPagination: false }
  );

  const [{ hasSubheader }, setHasSubheader] = useReducer(
    () => ({ hasSubheader: true }),
    { hasSubheader: false }
  );

  const [initialPageSize, setInitialPageSize] = useState<number>();

  const [pagination, registerPagination] = useState<
    Required<PaginationProps> | undefined
  >({
    currentPage: 0,
    pageSize: 10,
    totalItems: 0,
    onChangeCurrentPage: dummyCallback,
    onChangePageSize: dummyCallback,
  });

  const paddingXValue = useMemo(() => {
    if (noPaddingX) return 'none';
    return variant === TableVariant.COMPACT ? 'sm' : 'md';
  }, [noPaddingX, variant]);

  return (
    <TableContext.Provider
      value={{
        registerPagination,
        pagination: serverSidePagination ? undefined : pagination,
        isLoading,
        hasPagination,
        setHasPagination,
        initialPageSize,
        hasSubheader,
        setHasSubheader,
        setInitialPageSize,
        variant,
        withoutHeaderOffset,
        serverSideSort,
      }}
    >
      <Surface border={border} paddingX={paddingXValue} dashboard={dashboard}>
        <div
          className={classNames(
            'py-2.5',
            dashboard ? styles.containerForDashboard : styles.container
          )}
          data-variant={variant}
          data-without-header-offset={withoutHeaderOffset}
        >
          {children}
          {isLoading ? (
            <Loading
              loaderStandardColor={loaderStandardColor}
              loadingHeight={loadingHeight}
            />
          ) : null}
        </div>
      </Surface>
    </TableContext.Provider>
  );
};

const dummyCallback = () => {};

export { Container };
