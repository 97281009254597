import React, { useEffect, useState } from 'react';
import { SvgIcon } from 'kennek/icons';
import { Button, Input } from 'ui';
import { XIcon } from '@heroicons/react/outline';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';

interface Props {
  onClose: () => void;
  onSubmit: (notifyUsers: { [key: string]: boolean }, loanId?: string) => void;
  usersToNotify?: Array<{ email: string; id: string; role: string }>;
  loanId?: string;
  isLoading?: boolean;
}

const NotifyUsersModal: React.FC<Props> = ({
  onClose,
  onSubmit,
  usersToNotify,
  loanId = null,
  isLoading = false,
}) => {
  const [notifyUsers, setNotifyUsers] = useState<{ [key: string]: boolean }>(
    {}
  );
  const { borrowerUpper } = useGetLabelsConfig();

  useEffect(() => {
    // prepare keys for each user, borrower and investors
    setNotifyUsers(
      usersToNotify.reduce((obj, { id }) => ({ ...obj, [id]: true }), {})
    );
  }, [usersToNotify]);

  const handleSubmit = async () => {
    onSubmit(notifyUsers, loanId);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setNotifyUsers((prevState) => ({ ...prevState, [name]: checked }));
  };

  if (isLoading)
    return (
      <div className="flex items-center justify-center h-[450px]">
        <LoadingAnimationPlat size="md" fitBox />
      </div>
    );

  return (
    <>
      <div className="flex flex-col">
        <button
          onClick={onClose}
          className="flex-auto flex flex-row justify-end cursor-pointer text-neutral-700 font-normal hover:text-black outline-none"
        >
          <XIcon className="w-4" />
        </button>
        <div className="flex justify-center my-5">
          <SvgIcon name="SendIcon" />
        </div>
        <div className="mb-3 font-medium text-[16px] text-center">
          Select the users you want to invite to follow up the loan
        </div>
      </div>

      <div className="flex flex-col rounded-lg bg-neutral-100 pb-5">
        {usersToNotify.map((user) => (
          <label
            key={user.id}
            htmlFor="terms"
            className="relative mt-5 mx-4 flex items-center flex-row"
          >
            <Input
              type="checkbox"
              name={user.id}
              id={user.id}
              value={user.id}
              checked={notifyUsers[user.id] || false}
              onChange={handleCheckboxChange}
              className="flex-none mr-3 w-5 h-5"
            />
            <span className="flex-auto body-300 font-medium decoration-black">
              {user.email}
            </span>
            <span className="flex-none body-100 text-neutral-600">
              <span className="pl-2">
                {user.role === 'Borrower' ? borrowerUpper : user.role}
              </span>
            </span>
          </label>
        ))}
      </div>

      <div className="flex justify-between mt-8">
        <Button layout="link" className="w-40" onClick={onClose}>
          Cancel
        </Button>
        <Button className="w-40" layout="link" onClick={handleSubmit}>
          Confirm
        </Button>
      </div>
    </>
  );
};

export { NotifyUsersModal };
