import React from 'react';
import classNames from 'classnames';
import { Alert } from 'ui';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import ScheduleDetail from '@/components/widgets/ScheduleDetails';
import { Tranches } from '@/features/onboarding';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useRole } from '@/hooks/useRole';
import { formatAmount } from '@/utils/formatters';
import EditTranchesContainer from './editTranchesContainer';
import { TrancheRow } from './trancheRow';
import { UserTypes } from 'kennek/interfaces/accounts';
import { Loan, LoanProductRules, Tranche } from '@/interfaces/loans';
import { LoanTrancheSummary } from '@/interfaces/loans/queries';

interface TranchesSectionProps {
  isFetching?: boolean;
  tranchesList: Tranche[];
  tranchesSummary: LoanTrancheSummary;
  editButton?: React.ReactNode;
  variant: 'borrower' | 'onboarding' | 'originator';
  paidOff?: boolean;
  loan?: Loan;
  rules?: LoanProductRules;
  editTranches?: boolean;
  setEditTranches?: (val: boolean) => void;
  refreshTranchesData?: () => void;
  setTrancheToDisburse?: (value: Tranche) => void;
}
const TranchesSection: React.FC<TranchesSectionProps> = ({
  tranchesList,
  tranchesSummary,
  isFetching,
  variant,
  editButton = false,
  paidOff = false,
  loan,
  rules,
  editTranches = false,
  setEditTranches,
  refreshTranchesData,
  setTrancheToDisburse,
}) => {
  const { role } = useRole();

  const permissions = ROLES_PERMISSIONS[role];
  const { loanLower } = useGetLabelsConfig();

  const firstPending = tranchesList?.findIndex(
    (value) => value?.disbursed === false
  );

  if (isFetching)
    return (
      <div className="flex flex-1 h-52 justify-center items-center">
        <LoadingAnimationPlat fitBox />
      </div>
    );

  return (
    <>
      {paidOff && (
        <Alert
          title={`The ${loanLower} has been repaid in full.`}
          type="success"
          className="mx-5"
        >
          <div className="body-400 text-neutral-800">
            Pending tranches will not be disbursed
          </div>
        </Alert>
      )}
      <div className="pt-4">
        {tranchesList?.length > 0 ? (
          <>
            <div
              className={classNames('flex pl-8', {
                'pr-8': variant !== 'onboarding',
              })}
            >
              <div className="flex flex-col mt-8 items-center relative">
                <div className="w-2 h-2 rounded-full bg-neutral-500 absolute -left-1"></div>
                <div className="h-10 border-l border-neutral-500 mt-4 border-dashed"></div>
              </div>
              <div className="flex relative ml-8 mb-4 w-full border-b border-neutral-300">
                <div
                  className={classNames(
                    'flex justify-between w-full items-center sticky h-24 z-[5] bg-white'
                  )}
                >
                  {tranchesSummary && (
                    <>
                      <ScheduleDetail
                        title="Total Disbursed"
                        detail={formatAmount(
                          tranchesSummary?.totalDisbursed,
                          true,
                          tranchesSummary?.currency
                        )}
                        principal
                      />
                      <ScheduleDetail
                        title="Pending Disbursement"
                        detail={formatAmount(
                          tranchesSummary?.pendingDisbursement,
                          true,
                          tranchesSummary?.currency
                        )}
                      />
                      <ScheduleDetail
                        title="Max Disbursement"
                        detail={formatAmount(
                          tranchesSummary?.maxDisbursement,
                          true,
                          tranchesSummary?.currency
                        )}
                      />
                      <ScheduleDetail
                        title="Disbursed tranches"
                        detail={`${tranchesSummary?.tranchesDisbursed}/${
                          tranchesSummary?.tranchesRemaining +
                          tranchesSummary?.tranchesDisbursed
                        }`}
                      />
                    </>
                  )}
                </div>
                <div
                  className={classNames('flex justify-end items-center', {
                    'w-24': variant === 'onboarding',
                    'w-60': variant === 'originator',
                  })}
                >
                  {!paidOff &&
                    permissions?.includes('EDIT') &&
                    !editTranches &&
                    editButton}
                </div>
              </div>
            </div>
            {editTranches ? (
              <EditTranchesContainer
                rules={rules}
                tranchesList={tranchesList}
                loan={loan}
                setEditTranches={setEditTranches}
                refreshTranchesData={refreshTranchesData}
              />
            ) : (
              <Tranches.TranchesContainer>
                {tranchesList?.map((field, index) => (
                  <div
                    className={classNames('px-8', {
                      'bg-neutral-100': firstPending === index && !paidOff,
                    })}
                    key={index}
                  >
                    <Tranches.TrancheContainer>
                      <Tranches.TrancheLabel disbursed={field?.disbursed}>
                        Tranche {index + 1}
                      </Tranches.TrancheLabel>

                      <div className="pt-2.5 pb-2.5">
                        <div
                          className={classNames(
                            'border-l border-neutral-400 pl-8',
                            {
                              'border-neutral-500': !field?.disbursed,
                            }
                          )}
                        >
                          <TrancheRow
                            tranche={field}
                            firstPending={firstPending}
                            index={index}
                            isOnboardingPreview={variant === 'onboarding'}
                            isBorrowerView={variant === 'borrower'}
                            paidOff={paidOff}
                            state={loan?.state}
                            currency={tranchesSummary?.currency}
                            setTrancheToDisburse={setTrancheToDisburse}
                          />
                        </div>
                      </div>
                    </Tranches.TrancheContainer>
                  </div>
                ))}
              </Tranches.TranchesContainer>
            )}
          </>
        ) : (
          <div className="heading-500 text-neutral-500 text-center pt-48 w-full pb-48">
            No tranches for this {loanLower}
          </div>
        )}
      </div>
    </>
  );
};

export { TranchesSection };

const PERMISSIONS = ['DISBURSE', 'EDIT'] as const;

const ROLES_PERMISSIONS: Partial<
  Record<UserTypes, (typeof PERMISSIONS)[number][]>
> = {
  ORIGINATOR_ADMIN: ['DISBURSE', 'EDIT'],
  ORIGINATOR_ANALYST: ['DISBURSE', 'EDIT'],
};
