import React, { memo, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Input } from 'ui';
import { Option } from 'ui/components/Select';
import { Menu } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/outline';
import Items from './Items';
import Selector from './Selector';

export interface Props {
  list?: Option[];
  selected: Option;
  readOnly?: boolean;
  onChange?: (item: Option) => void;
  type?: 'loans' | 'borrower';
  noItemSelectedLabel?: string;
  label: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
}

const SelectSearch: React.FC<Props> = ({
  list,
  selected,
  onChange,
  noItemSelectedLabel = ' Select item',
  type = 'loans',
  label,
  error,
  disabled,
  placeholder = 'Search',
}) => {
  const [selectedItem, setSelectedItem] = useState<Option>(selected);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    onChange?.(selectedItem);
  }, [selectedItem]);

  const filteredList = useMemo(
    () =>
      list && type === 'loans'
        ? [...list]
            .filter((i) =>
              i?.label?.toLowerCase().includes(search?.toLowerCase())
            )
            .sort((a, b) => ((a.label || '') <= (b.label || '') ? -1 : 1))
        : type === 'borrower'
          ? list
          : [],
    [search]
  );

  return (
    <div className="flex flex-col h-[82px]">
      <div className="heading-100 mb-1.5 select-none text-left">{label}</div>
      <Menu as="div" className={classNames('relative')}>
        {({ open }) => (
          <>
            <div className="flex flex-col text-left">
              <Menu.Button className="z-10 w-full focus:outline-none focus:ring-primary-100 focus:border-primary-100 focus:ring-0 focus:outline-primary-500 focus:ring">
                <Selector
                  selected={selectedItem}
                  isOpen={open}
                  noItemSelectedLabel={noItemSelectedLabel}
                  disabled={disabled}
                />
              </Menu.Button>
              {error && (
                <div className="body-200 mt-1">
                  <span className="text-error-700">{error}</span>
                </div>
              )}
            </div>
            <Menu.Items className="absolute min-w-max w-full origin-top-right border border-neutral-200 rounded-md shadow-snackbar bg-neutral-100 focus:outline-none z-[100]">
              <div className="p-3 pt-4">
                <label className="relative">
                  <SearchIcon
                    width={16}
                    className="text-neutral-700 absolute top-3 left-4"
                  />
                  <Input
                    id="filterName"
                    name="filterName"
                    type="search"
                    autoComplete="off"
                    value={search}
                    onChange={(e) => setSearch(e?.target?.value)}
                    placeholder={placeholder}
                    autoFocus={false}
                    className="border-neutral-400 rounded-md body-400 h-10 pl-10"
                  />
                </label>
              </div>
              <Items list={filteredList} onClick={setSelectedItem} />
            </Menu.Items>
          </>
        )}
      </Menu>
    </div>
  );
};

export default memo(SelectSearch);
