import { formatAmount } from '@/utils/formatters';
import { LabelsConfig } from 'kennek/interfaces/labelsConfig';
import { Currencies } from 'kennek/interfaces/loans';

export const COMPANY_MESSAGES = {
  SEARCH_COMPANY_NOT_FOUND:
    'We cannot find the company you are looking for. Please click on I can’t find the company.',
  SEARCH_COMPANY_IN_USE_DIFFERENT_ORIGINATOR:
    'Please provide a different email address.',
  REQUIRED_NAME_OR_NUMBER: 'Company name or number cannot be empty',
  REQUIRED_NAME: 'Name cannot be empty',
  REQUIRED_NUMBER: 'Number  cannot be empty',
  REQUIRED_ADDRESS: 'Address cannot be empty',
  REQUIRED_CITY: 'City cannot be empty',
  REQUIRED_POSTAL_CODE: 'Cannot be empty',
  INVALID_POSTAL_CODE: 'Invalid format',
  REQUIRED_SIC_CODE: 'Industry code cannot be empty',
  REQUIRED_COMPANY_DATE_OF_CREATION: 'Company date of creation cannot be empty',
  MAX_SIC_CODE: (max: number) => `Industry code cannot be more than ${max}`,
  MIN_SIC_CODE: (min: number) => `Industry code cannot be less than ${min}`,
  REQUIRED_COUNTRY: 'Country cannot be empty',
} as const;

export const USER_MESSAGES = {
  REQUIRED_FIRST_NAME: 'First name cannot be empty',
  REQUIRED_LAST_NAME: 'Last name cannot be empty',
  REQUIRED_FULL_NAME: 'Full name cannot be empty',
  REQUIRED_EMAIL: 'Email cannot be empty',
};

export const LOAN_MESSAGES = {
  REQUIRED_LOAN_TYPE: ({ loanUpper }: LabelsConfig) =>
    `${loanUpper} type cannot be empty`,
  REQUIRED_LOAN_NAME: ({ loanUpper }: LabelsConfig) =>
    `${loanUpper} name cannot be empty`,
  REQUIRED_LOAN_AMOUNT: ({ loanUpper }: LabelsConfig) =>
    `${loanUpper} amount cannot be empty`,
  MAX_VALUE_LOAN_AMOUNT: (
    max: number,
    currency: Currencies,
    { loanUpper }: LabelsConfig
  ) =>
    `${loanUpper} amount cannot be greater than ${formatAmount(max, true, currency)}`,
  MIN_VALUE_LOAN_AMOUNT: (
    min: number,
    currency: Currencies,
    { loanUpper }: LabelsConfig
  ) =>
    `${loanUpper} amount cannot be less than ${formatAmount(min, true, currency)}`,
  MIN_VALUE_FACTOR_AMOUNT: (min: number, currency: Currencies) =>
    `Factor amount cannot be less than ${formatAmount(min, true, currency)}`,
  REQUIRED_INTEREST_RATE: 'Interest rate cannot be empty',
  MAX_VALUE_INTEREST_RATE: (max: number) =>
    `Interest rate cannot be greater than ${max}%`,
  MIN_VALUE_INTEREST_RATE: (min: number) =>
    `Interest rate cannot be less than ${min}%`,
  REQUIRED_DEFAULT_INTEREST_RATE: 'Default rate cannot be empty',
  MAX_VALUE_DEFAULT_INTEREST_RATE: (max: number) =>
    `Default rate cannot be greater than ${max}%`,
  MIN_VALUE_DEFAULT_INTEREST_RATE: (min: number) =>
    `Default rate cannot be less than ${min}%`,
  MAX_VALUE_INTEREST_INVESTOR: (max: number) =>
    `Interest investor cannot be greater than interest rate ${max}%`,
  MIN_VALUE_INTEREST_INVESTOR: (min: number) =>
    `Interest investor cannot be less than ${min}%`,
  REQUIRED_GRACE_PERIOD: 'Grace period cannot be empty',
  MAX_VALUE_GRACE_PERIOD: (max: number) =>
    `Grace period cannot be greater than ${max}`,
  MIN_VALUE_GRACE_PERIOD: (min: number) =>
    `Grace period cannot be less than ${min}`,
  REQUIRED_GRACE_AMOUNT: 'Grace amount cannot be empty',
  MAX_VALUE_GRACE_AMOUNT: (max: number) =>
    `Grace amount cannot be greater than ${max}`,
  MIN_VALUE_GRACE_AMOUNT: (min: number) =>
    `Grace amount cannot be less than ${min}`,
  REQUIRED_REPAYMENT_INSTALLMENTS: (label?: string) =>
    `${label || 'Repayment instalment'} cannot be empty`,
  MAX_VALUE_REPAYMENT_INSTALLMENTS: (max: number) =>
    `Repayment instalment cannot be greater than ${max}`,
  MIN_VALUE_REPAYMENT_INSTALLMENTS: (min: number) =>
    `Repayment instalment cannot be less than ${min}`,
  REQUIRED_INTEREST_GRACE_PERIOD: 'Interest grace period cannot be empty',
  REQUIRED_ROLL_UP_DURATION: 'Roll-up duration cannot be empty',
  REQUIRED_PAY_CURRENT_INSTALMENTS: 'Pay current instalments cannot be empty',
  REQUIRED_MINIMUM_INTEREST_PERIOD: 'Minimum interest period cannot be empty',
  MINIMUM_INTEREST_PERIOD_SMALLER_EQUAL:
    'Minimum interest period must be less than or equal to the Repayment instalments',
  MIN_VALUE_INTEREST_GRACE_REPAYMENT_INSTALLMENTS: (min: number) =>
    `Interest grace period cannot be less than ${min}`,

  MAX_VALUE_INTEREST_GRACE_REPAYMENT_INSTALLMENTS:
    'Interest grace period cannot be greater than the Repayment instalments',
  MAX_VALUE_INTEREST_GRACE_RETAINED_REPAYMENT_INSTALLMENTS:
    'Retained interest period cannot be greater than the number of Repayment instalments',
  MIN_VALUE_ROLL_UP_DURATION: 'Interest grace period cannot be less than 0',
  MAX_VALUE_ROLL_UP_DURATION:
    'Roll-up duration cannot be greater than the Repayment instalments',
  REQUIRED_REPAYMENT_PERIOD_UNIT: 'Repayment period unit cannot be empty',
  REQUIRED_REPAYMENT_PERIOD_COUNT: "Can't be empty",
  MAX_VALUE_REPAYMENT_PERIOD_COUNT: 'Period count cannot be greater than 1000',
  MIN_VALUE_REPAYMENT_PERIOD_COUNT: 'Period count cannot be less than 0',
  REQUIRED_REPAYMENT_SCHEDULE_METHOD:
    'Repayment schedule method cannot be empty',
  REQUIRED_PENALTY_RATE: 'Penalty rate cannot be empty',
  MAX_VALUE_PENALTY_RATE: (max: number) =>
    `Penalty rate cannot be greater than ${max}%`,
  MIN_VALUE_PENALTY_RATE: (min: number) =>
    `Penalty rate cannot be less than ${min}%`,
  REQUIRED_NEXT_FINANCIAL_YEAR_END: (label?: string) =>
    `Select ${label || 'Next financial year end'}`,
  MIN_DATE_NEXT_FINANCIAL_YEAR_END: (date: string) =>
    `Select a date after ${date}`,
  REQUIRED_DISBURSEMENT_END: 'Select disbursement date',
  REQUIRED_PERIODIC_PAYMENT: 'Periodic payment cannot be empty',
  MAX_VALUE_PERIODIC_PAYMENT: (max: number) =>
    `Periodic payment cannot be greater than ${formatAmount(max)}`,
  MIN_VALUE_PERIODIC_PAYMENT: (min: number) =>
    `Periodic payment cannot be less than ${formatAmount(min)}`,
  MAX_VALUE_BALLOON_PAYMENT: (max: number) =>
    `Balloon payment cannot be greater than ${formatAmount(max)}`,
  MIN_VALUE_BALLOON_PAYMENT: (min: number) =>
    `Balloon payment cannot be less than ${formatAmount(min)}`,
  MAX_VALUE_FEE: (max: number, currency: Currencies) =>
    `Fee cannot be greater than ${formatAmount(max, true, currency)}`,
  MAX_VALUE_PENALTY_BALANCE: (max: number, currency: Currencies) =>
    `Penalty balance cannot be greater than ${formatAmount(
      max,
      true,
      currency
    )}`,
  MAX_VALUE_INTEREST_BALANCE: (max: number, currency: Currencies) =>
    `Interest balance cannot be greater than ${formatAmount(
      max,
      true,
      currency
    )}`,
  // Date
  MIN_DATE_ABSOLUTE: (date: string) => `Select a date after ${date}`,
  MAX_DATE_ABSOLUTE: (date: string) => `Select a date before ${date}`,
  REQUIRED_CONFIRM_RESTRUCTURE: ({ loanLower }: LabelsConfig) =>
    `You need to confirm ${loanLower} restructure to continue`,
  LOAN_ID_ALREADY_EXISTS: ({ loanLower }: LabelsConfig) =>
    `This ID is already in use by another ${loanLower}`,
  REQUIRED_MONTHLY_REPAYMENT_DAY: (label?: string) =>
    `${label || 'Monthly repayment day'} cannot be empty`,
  WRONG_MONTHLY_REPAYMENT_DAY: 'Date must be between 1 and 31',
  REQUIRED_INVESTOR_INTEREST: 'Investor commission method cannot be empty',
  MIN_DATE_REPAYMENT_DATE: 'First repayment date should be a future date',
  MIN_DATE_DISBURSEMENT_DATE: 'Disbursement date should be today’s date',
  REQUIRED_LOAN_NET_CALCULATION_TYPE: ({ loanUpper }: LabelsConfig) =>
    `Net ${loanUpper} Calculation Method cannot be empty`,
  REQUIRED_INTEREST_CAPITALIZATION_FREQUENCY:
    'Interest capitalization frequency cannot be empty',

  RANGE_PERCENT_VALUE: (
    value: number,
    equalizer: 'greater' | 'less',
    label: string
  ) => `${label} cannot be ${equalizer} than ${value}%`,
  REQUIRED_LTV: 'LTV cannot be empty',
  MAX_LTV: (max: number) =>
    LOAN_MESSAGES.RANGE_PERCENT_VALUE(max, 'greater', 'LTV'),
  MIN_LTV: (min: number) =>
    LOAN_MESSAGES.RANGE_PERCENT_VALUE(min, 'less', 'LTV'),
  REQUIRED_GDV: 'GDV cannot be empty',
  MIN_GDV: (min: number) =>
    LOAN_MESSAGES.RANGE_PERCENT_VALUE(min, 'less', 'GDV'),
  REQUIRED_LTC: 'LTC cannot be empty',
  MAX_LTC: (max: number) =>
    LOAN_MESSAGES.RANGE_PERCENT_VALUE(max, 'greater', 'LTC'),
  MIN_LTC: (min: number) =>
    LOAN_MESSAGES.RANGE_PERCENT_VALUE(min, 'less', 'LTV'),
  REQUIRED_GDC: 'GDC cannot be empty',
  MIN_GDC: (min: number) =>
    LOAN_MESSAGES.RANGE_PERCENT_VALUE(min, 'less', 'GDC'),
  REQUIRED_EFFECTIVE_INTEREST_RATE: 'Effective Interest Rate cannot be empty',
  REQUIRED_GROSS_YIELD: 'Gross Yield cannot be empty',
  EFFECTIVE_INTEREST_RATE_LESS_THAN_GROSS_YIELD: `Effective Interest Rate cannot be greater than Gross Yield`,

  MAX_VALUE_REPAYMENT_INSTALLMENTS_REVOLVING_CREDIT: (
    max: number,
    { loanLower }: LabelsConfig
  ) =>
    `The ${loanLower} cannot have more than ${max} instalments. Review and edit before continuing`,
  LAST_DISBURSTMENT_DATE_BEFORE_LAST_DUE_DATE_REVOLVING_CREDIT: `The tranche disbursement date cannot be greater than the last instalment due date`,
  DISBURSTMENT_SUM_LESSER_THAN_LOAN_AMOUNT: (
    amount: number,
    currency: Currencies,
    { loanLower }: LabelsConfig
  ) =>
    `You need to disburse the full ${loanLower} amount (${formatAmount(
      amount,
      true,
      currency
    )}) before continuing.`,
  DISBURSTMENT_SUM_LOAN_AMOUNT_UNDEFINED: ({ loanLower }: LabelsConfig) =>
    `You need to define the ${loanLower} amount before continuing`,
  DISBURSTMENT_DATES_MUST_BE_ASC:
    'Disbursement date should be in ascending order',
  DISBURSTMENT_SUM_GREATER_THAN_LOAN_AMOUNT: (
    max: number,
    currency: Currencies,
    { loanLower }: LabelsConfig
  ) =>
    `You cannot disburse more than the ${loanLower} amount (${formatAmount(
      max,
      true,
      currency
    )}) before continuing.`,
  DISBURSTMENT_SUM_GREATER_THAN_LOAN_AMOUNT_REVOLVING_CREDIT: (
    max: number,
    currency: Currencies,
    { loanLower }: LabelsConfig
  ) =>
    `You cannot disburse more than the ${loanLower} amount (${formatAmount(
      max,
      true,
      currency
    )}). Review and edit the amount before continuing`,
  NON_HOLIDAY_DISBURSTMENT_DATE_REVOLVING_CREDIT: (date: string) =>
    `The disbursement date ${
      date ?? ''
    } cannot be a holiday. Review and edit the dates before continuing.`,
  DISBURSEMENT_DATE_AFTER_FIRST_REPAYMENT_DATE: (
    firstRepaymentDayLabel: string
  ) => `Disbursement date cannot be after ${firstRepaymentDayLabel}`,
  DISBURSEMENT_DATE_IN_THE_FUTURE: 'Disbursement date cannot be in the future',
};

export const LOAN_MESSAGES_BY_PRODUCT = {
  blockedUntilMatured: {
    REQUIRED_FIRST_REPAYMENT_DATE: (label?: string) =>
      `Select ${label?.toLocaleLowerCase() || 'first accrual date'}`,
    MISMATCH_REPAYMENT_DAY: (date: string) =>
      'Must be the same date as the first accrual date' +
      (date ? ` (${date})` : ''),
  },
  default: {
    REQUIRED_FIRST_REPAYMENT_DATE: (label?: string) =>
      `Select ${label?.toLocaleLowerCase() || 'first repayment date'}`,
    MISMATCH_REPAYMENT_DAY: (date: string) =>
      'Must be the same date as the first repayment date' +
      (date ? ` (${date})` : ''),
  },
};

export const INVESTOR_MESSAGES = {
  INVESTMENT_DELETED_SUCCESS: 'The investor has been deleted',
  INVESTMENT_DELETED_CONTENT: ({ loanLower }: LabelsConfig) =>
    `The ${loanLower} will no longer appear on the investors account`,
  INVESTMENT_DELETED_FAILED: 'Failed to delete investment',
};

export const USER_INFORMATION_TEXTS = ({
  borrowerLower,
  loanLower,
}: LabelsConfig) => {
  return {
    TITLE: `Key ${borrowerLower} contact`,
    SUBTITLE: `Enter the key contact details for the ${borrowerLower}. Once the ${loanLower} onboarding is complete, you can select whether you would like to invite them to access the platform and track the ${loanLower}`,
  };
};
