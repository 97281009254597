import { Locale } from 'date-fns';
import { formatInTimeZone as formatter } from 'date-fns-tz';
import enGB from 'date-fns/locale/en-GB';
import { RepaymentPeriodUnits } from '@/interfaces/loans';

/**
 * Function to format dates from date-fns and extended to accept string dates
 * @param date a valid Date, number or string date
 * @param format a valid date-fns format value, use `y: year`, `M: month`, `d: day`, `m: minute`, `s: second` by default `dd/MM/yyy`
 * @param options a valid date-fns-tz options object
 * @returns a string with the formatted date
 */
export const formatDate = (
  date: string | number | Date,
  format?: string,
  options?: {
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
    additionalDigits?: 0 | 1 | 2;
    timeZone?: string;
    locale?: Locale;
    includeSeconds?: boolean;
    addSuffix?: boolean;
    unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
    roundingMethod?: 'floor' | 'ceil' | 'round';
    awareOfUnicodeTokens?: boolean;
  }
): string => {
  const formatValue = format || 'dd/MM/yyyy';
  if (date) {
    if (typeof date === 'string')
      return formatter(new Date(date), 'Europe/London', formatValue, {
        locale: enGB,
        ...options,
      });
    else
      return formatter(date, 'Europe/London', formatValue, {
        locale: enGB,
        ...options,
      });
  }
};

/**
 * Function to format amounts adding symbol and separators, for non-integer values the amount is fixed to 2 decimals
 * @param amount - a valid string or number amount
 * @param decimals - a boolean to add decimals or not
 * @param currency - a valid ISO-4217 currency code
 * @param locale - a standard country locale (en-GB, es-ES, etc - a combination of ISO-639 and ISO-3166)
 * @returns a string with the formatted amount, e.g. £1,000.00, or '-' if the amount is not valid
 */
export const formatAmount = (
  amount: string | number,
  decimals = true,
  currency = 'GBP',
  locale = 'en-GB'
) => {
  const fractionDigits = decimals ? 2 : 0;

  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    useGrouping: true,
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
  });

  if (isNaN(Number(amount))) {
    return '-';
  }

  return formatter.format(Number(amount));
};

/**
 * Function to format numbers, adding separators and decimals
 * @param value - the string or number to format
 * @param decimals - a boolean to add decimals or not
 * @param locale - a standard country locale (en-GB, es-ES, etc - a combination of ISO-639 and ISO-3166)
 * @returns a string with the formatted number, or '-' if the value is not a number
 */
export const formatNumber = (
  value: string | number,
  decimals = true,
  locale = 'en-GB'
) => {
  const fractionDigits = decimals ? 2 : 0;

  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    useGrouping: true,
    style: 'decimal',
  });

  if (isNaN(Number(value))) {
    return '-';
  }

  return formatter.format(Number(value));
};

export const formatPercent = (
  amount: string | number = 0,
  maxDecimals = 2,
  minDecimals = 2,
  locale = 'en-GB'
) => {
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
    useGrouping: true,
    style: 'percent',
  });

  let percentage = Number(amount);
  if (isNaN(percentage)) {
    percentage = 0;
  }

  return formatter.format(percentage / 100);
};

export const formatPhone = (phone: string) => {
  if (!phone || phone.length < 1) return '';
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phone;
};

export const formatPhoneAddPrefix = (phone: string, prefix = '+44') => {
  if (!phone || phone.length < 1) return '';
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{4})(\d{6})$/);
  if (match) {
    return prefix + ' ' + match[1] + ' ' + match[2];
  }
  return phone;
};

export const checkDecimalsAmount = (amount: string | number): number => {
  if (!amount) return 2;
  const amountArray = amount.toString().split('.');
  const decimals = amountArray?.[1]?.length;
  return decimals === 3 || decimals === 2 ? decimals : 2;
};

export const pluraliseRepaymentPeriodUnits = (
  count: number,
  unit: RepaymentPeriodUnits
) => {
  switch (unit) {
    case 'DAYS':
      return count === 1 ? 'day' : 'days';
    case 'WEEKS':
      return count === 1 ? 'week' : 'weeks';
    case 'MONTHS':
      return count === 1 ? 'month' : 'months';
    case 'YEARS':
      return count === 1 ? 'year' : 'years';
  }
};
