import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { SvgIcon } from 'kennek/icons';
import { clone } from 'lodash';
import { Button, FormInput, Select, Title } from 'ui';
import { TrashIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { SNACKBAR_ONBOARDING } from '@/constants/snackbar-messages';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useSnackbar } from '@/hooks/useSnackbar';
import { usePatchOnboardingTaskStatusMutation } from '@/services/accounts/borrower';
import {
  useGetLoanProductRulesQuery,
  useGetLoanQuery,
  useUpdateLoanMutation,
} from '@/services/loans';
import { useGetLoanFacilitiesForLoanQuery } from '@/services/loans/facility';
import {
  useGetAssetsQuery,
  useGetSupportedAssetsQuery,
} from '@/services/loans/securities';
import { useGetBorrowerCompanyByIdQuery } from '@/services/originator/borrower-company';
import { useGetProductsByOriginatorIdQuery } from '@/services/products';
import { useSelectUser } from '@/store/user/selectors';
import {
  onEnterAssetInformation,
  onExitAssetsInformation,
  useSelectAssetsForm,
} from '../onboardingSlice';
import {
  AssetToggle,
  AssetsFormInputs,
  EMPTY_FORM,
  calculateLoanToValuePercentage,
  getEmptyAsset,
  loanToUpdateLoanDTO,
  mapAssetsFormInputToAssetDto,
  mapFormInitialValues,
  occupancyStatusOptions,
  schema,
} from './LoanAssets.utils';
import { Currencies } from 'kennek/interfaces/loans';

interface LoanAssetsProps {
  onContinue: () => void;
  onBack: () => void;
  loanId: string;
  isReview: boolean;
  loanCurrency: Currencies;
  isRefinance?: boolean;
  borrowerCompanyId?: string;
}

const LoanAssets: React.FC<LoanAssetsProps> = ({
  onContinue,
  onBack,
  loanId,
  isReview,
  loanCurrency,
  isRefinance = false,
  borrowerCompanyId,
}) => {
  const [toggle, setToggle] = useState<AssetToggle>({ [0]: true });
  const snackbar = useSnackbar();
  const user = useSelectUser();
  const labelsConfig = useGetLabelsConfig();

  const { data: companyInfo, isLoading: isLoadingCompanyInfo } =
    useGetBorrowerCompanyByIdQuery(
      { id: borrowerCompanyId },
      {
        skip: !borrowerCompanyId,
      }
    );
  const { data: assetsQueryData, isLoading: isLoadingAssets } =
    useGetAssetsQuery(loanId, {
      skip: !loanId,
    });
  const { currentData: loanDetails, isLoading: isLoadingLoan } =
    useGetLoanQuery(loanId, {
      skip: !loanId,
    });
  const { data: loanFacilitiesStored, isLoading: isLoadingFacilities } =
    useGetLoanFacilitiesForLoanQuery(loanDetails?.encodedKey, {
      skip: !loanDetails?.encodedKey,
    });

  const { data: loanProductRules, isLoading: isLoadingRules } =
    useGetLoanProductRulesQuery(
      { id: loanDetails?.productTypeKey },
      { skip: !loanDetails?.productTypeKey }
    );

  const { data: products, isLoading: isLoadingProducts } =
    useGetProductsByOriginatorIdQuery(
      { id: user?.mambuUser?.[0]?.mambuBranchEncodedKey, state: 'ACTIVE' },
      { skip: !user?.mambuUser?.[0]?.mambuBranchEncodedKey }
    );

  const getLoanQuery = useGetLoanQuery(loanId, { skip: !loanId });

  const dispatch = useDispatch();
  const [updateLoan, { isLoading: isUpdatingLoan }] = useUpdateLoanMutation();
  const assetsFormData = useSelectAssetsForm();

  const supportedAssetsQuery = useGetSupportedAssetsQuery(loanId, {
    skip: !loanId,
  });

  const [patchOnboardingTaskStatus, { isLoading: isPatchingStepStatus }] =
    usePatchOnboardingTaskStatusMutation();

  const assetTypeOptions = useMemo(() => {
    return (
      [...(supportedAssetsQuery?.currentData ?? [])]
        ?.sort((a, b) => a.order - b.order)
        ?.map((asset) => ({
          label: asset.assetLabel,
          value: asset.assetType,
        })) ?? []
    );
  }, [supportedAssetsQuery?.currentData]);

  const initialFormValues = assetsFormData?.length
    ? { assets: assetsFormData }
    : assetsQueryData
      ? mapFormInitialValues(assetsQueryData)
      : EMPTY_FORM;

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isDirty },
    watch,
  } = useForm<AssetsFormInputs>({
    resolver: yupResolver(schema(labelsConfig)),
    defaultValues: initialFormValues,
  });

  useEffect(() => {
    reset(initialFormValues);
    setToggle({ [0]: true });
    setBorrowerTypeDefaultValue();
  }, [assetsQueryData, reset]);

  useEffect(() => {
    const assetsWithErrors = errors.assets;
    const indexesOfAssetsWithErrors = assetsWithErrors?.map(
      (_, index) => index
    );

    const objFromAssetsWithErrors = indexesOfAssetsWithErrors?.reduce(
      (accumulator, value) => {
        return { ...accumulator, [value]: true };
      },
      {}
    );

    assetsWithErrors?.forEach(() => setToggle(objFromAssetsWithErrors));
  }, [errors]);

  // Set borrowerType to the borrower name if it's not set.
  // We need to do this only for the Asset 1 because it's initialised before the loan data is fetched.
  // Probably, we should split this component into two separate components.
  //   1) Manage the logic and data from the server-side
  //   2) Manage the UI and the form state
  useEffect(() => {
    setBorrowerTypeDefaultValue();
  }, [companyInfo?.name]);

  const setBorrowerTypeDefaultValue = () => {
    if (getValues('assets.0.borrowerType') === '' && companyInfo?.name) {
      setValue('assets.0.borrowerType', companyInfo?.name);
    }
  };

  const isDirtyRef = useRef(isDirty);

  useLayoutEffect(() => {
    isDirtyRef.current = isDirty;
  }, [isDirty]);

  useEffect(() => {
    dispatch(onEnterAssetInformation());
    return () => {
      const formValues = getValues();
      dispatch(
        onExitAssetsInformation({
          form: clone(formValues.assets),
          isDirty: isDirtyRef.current,
        })
      );
    };
  }, [dispatch, getValues]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'assets',
  });

  const onSubmit = handleSubmit((data: AssetsFormInputs) => {
    if (!loanDetails) return;

    if (isRefinance) {
      onContinue();
      return;
    }

    const assets = data.assets.map(mapAssetsFormInputToAssetDto);
    const payload = loanToUpdateLoanDTO(
      loanDetails,
      loanProductRules,
      assets,
      loanFacilitiesStored,
      products
    );

    updateLoan(payload)
      .unwrap()
      .then(async () => {
        snackbar.show({
          severity: 'primary',
          title: SNACKBAR_ONBOARDING.LOAN_ASSETS_UPDATE_SUCCESS,
        });
        patchOnboardingTaskStatus({
          taskName: 'SECURITIES',
          taskStatus: 'COMPLETED',
          loanId,
        });
        onContinue();
      })
      .catch(() => {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_ONBOARDING.LOAN_ASSETS_UPDATE_FAILED,
        });
      });
  });

  const removeAsset = (index: number) => {
    manageTogglesOnRemoveAsset(index);
    remove(index);
  };

  const toggleAsset = (toggleIndex: number) => {
    setToggle({
      ...toggle,
      [toggleIndex]: !toggle[toggleIndex],
    });
  };

  const manageTogglesOnAddAsset = () => {
    const newToggles = {};
    fields.forEach((_, index) => (newToggles[index] = false));
    setToggle({ ...newToggles, [fields.length]: true });
  };

  const manageTogglesOnRemoveAsset = (toggleIndex: number) => {
    const newToggles = {};
    fields.forEach((_, index) => {
      if (index !== toggleIndex) newToggles[index] = toggle[index];
    });
    setToggle({ ...newToggles, [fields.length - 2]: true });
  };

  const isLoading =
    isLoadingAssets ||
    isLoadingRules ||
    isLoadingLoan ||
    isLoadingFacilities ||
    isLoadingProducts ||
    isLoadingCompanyInfo ||
    !loanId;

  const isSubmitting = isUpdatingLoan || isPatchingStepStatus;

  if (isLoading)
    return (
      <div className="flex justify-center items-center">
        <LoadingAnimationPlat fitBox />
      </div>
    );

  return (
    <div>
      <Title
        icon={<SvgIcon name="StatementIcon" />}
        title="Asset information"
        titleSize="lg"
        className="mt-2"
      />
      <form
        className="form stepper-form"
        onSubmit={(e) => e.preventDefault()}
        autoComplete="off"
        noValidate
      >
        {fields?.map((field, index) => (
          <>
            <div className="flex justify-between mb-[-20px]">
              <Title
                title={`Asset ${index + 1}`}
                titleSize="sm"
                className="my-1"
              />
              <div className="flex align-center">
                {fields.length > 1 && (
                  <>
                    <Button
                      className="mr-[15px]"
                      icon={<TrashIcon />}
                      layout="ghost"
                      onClick={() => removeAsset(index)}
                    ></Button>
                    <button
                      className={classNames({ 'rotate-180': toggle[index] })}
                      onClick={() => toggleAsset(index)}
                    >
                      <SvgIcon name="ArrowIcon" />
                    </button>
                  </>
                )}
              </div>
            </div>
            {toggle[index] ? (
              <div key={field.id}>
                <Controller
                  name={`assets.${index}.borrowerType`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormInput
                      errors={errors.assets?.[index]?.borrowerType?.message}
                      placeholder="Name of the owner of the asset"
                      questionTooltip={`Owner of the asset, by default it is filled by the name of the ${labelsConfig.borrowerLower} but can be changed if the owner is different.`}
                      type="text"
                      label="Asset Owner"
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`assets.${index}.assetType`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      placeholder="Select a type"
                      label="Asset Type"
                      error={errors.assets?.[index]?.assetType?.message}
                      options={assetTypeOptions}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`assets.${index}.assetDescription`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormInput
                      label="Asset Description"
                      placeholder="Describe the asset main use"
                      questionTooltip="Describe the asset main use"
                      type="text"
                      errors={errors.assets?.[index]?.assetDescription?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name={`assets.${index}.securityAddress`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormInput
                      label="Security Asset Address"
                      placeholder="Property address or location"
                      questionTooltip="Property address or location"
                      errors={errors.assets?.[index]?.securityAddress?.message}
                      type="text"
                      {...field}
                    />
                  )}
                />

                <Controller
                  name={`assets.${index}.occupancyStatus`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      placeholder="Select a status"
                      label="Tenanted Status"
                      options={occupancyStatusOptions}
                      error={errors.assets?.[index]?.occupancyStatus?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name={`assets.${index}.assetValuation`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormInput
                      label="Asset valuation"
                      value={field.value}
                      currencyInput={loanCurrency}
                      disabled={field.disabled}
                      errors={errors?.assets?.[index]?.assetValuation?.message}
                      type="number"
                      onValueChange={(value) => field.onChange(value ?? '')}
                    />
                  )}
                />

                <FormInput
                  label="LTV %"
                  leftSymbol="%"
                  value={calculateLoanToValuePercentage(
                    getLoanQuery.data?.loanAmount ?? 0,
                    Number(watch(`assets.${index}.assetValuation`))
                  )}
                  disabled={true}
                  type="number"
                />

                {index !== fields.length - 1 && (
                  <div className="border-[1px] border-solid border-neutral-300"></div>
                )}
              </div>
            ) : (
              index !== fields.length - 1 && (
                <div className="border-[1px] border-solid border-neutral-300"></div>
              )
            )}
          </>
        ))}

        <Button
          className="self-start font-normal text-[13px]"
          type="button"
          layout="link"
          onClick={() => {
            manageTogglesOnAddAsset();
            append(getEmptyAsset(getLoanQuery.data?.borrowerContactFullName));
          }}
        >
          + Add another asset
        </Button>
        <div className="flex justify-between mt-6">
          <Button onClick={onBack} layout="ghost">
            {isReview ? 'Cancel' : 'Back'}
          </Button>

          <Button
            onClick={onSubmit}
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoanAssets;
