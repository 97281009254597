/**
 * Alert v.01
 * Check the latest version at:
 *  https://mate-ui.truenorth.co
 */
import React, { useContext } from 'react';
import classNames from 'classnames';
import { ThemeContext } from 'ui/theme';
import {
  BellIcon,
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/solid';

export enum Type {
  warning = 'warning',
  error = 'error',
  success = 'success',
  info = 'info',
  neutral = 'neutral',
  primary = 'primary',
  secondary = 'secondary',
}

enum Size {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The variant type of the alert
   */
  type?: keyof typeof Type;
  /**
   * The variant size of the alert
   */
  size?: keyof typeof Size;
  /**
   * Toggle icon visibility
   */
  showIcon?: boolean;
  /**
   * Pass a custom <Icon />
   */
  icon?: React.ReactNode;
  /**
   * Alert title
   */
  title?: string;
  /**
   * Content below the title
   */
  message?: string;
  /**
   * Inline action text button
   */
  actionLabel?: string;
  /**
   * If defined, shows the inline action button that calls this function
   */
  onAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * If defined, shows the close button that calls this function
   */
  onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Alert is used to communicate the state or status of a
 * page, feature or action
 */
const Alert: React.FC<AlertProps> = ({
  className,
  children,
  type = 'neutral',
  size = 'md',
  showIcon = true,
  icon,
  title,
  message,
  actionLabel,
  onAction,
  onClose,
  ...other
}) => {
  const {
    theme: { alert },
  } = useContext(ThemeContext);

  let AlertIcon = (icon as any) || null;
  if (!AlertIcon) {
    switch (type) {
      case 'warning':
        AlertIcon = ExclamationIcon;
        break;
      case 'error':
        AlertIcon = XCircleIcon;
        break;
      case 'success':
        AlertIcon = CheckCircleIcon;
        break;
      case 'info':
        AlertIcon = InformationCircleIcon;
        break;
      default:
        AlertIcon = BellIcon;
    }
  }

  const baseStyle = alert.base;
  const typeStyle = alert.type[type];
  const sizeStyle = alert.size[size];

  const iconBaseStyle = alert.icon.base;
  const iconTypeStyle = alert.icon[type];
  const iconSizeStyle = alert.icon[size];

  const titleBaseStyle = alert.title.base;
  const titleTypeStyle = alert.title[type];
  const titleSizeStyle = alert.title[size];

  const messageBaseStyle = alert.message.base;
  const messageTypeStyle = alert.message[type];
  const messageSizeStyle = alert.message[size];

  const inlineActionBaseStyle = alert.inlineAction.base;
  const inlineActionTypeStyle = alert.inlineAction[type];
  const inlineActionSizeStyle = alert.inlineAction[size];

  const closeBaseStyle = alert.close.base;
  const closeTypeStyle = alert.close[type];
  const closeSizeStyle = alert.close[size];

  const cls = classNames(
    baseStyle,
    typeStyle,
    sizeStyle,
    className,
    'flex justify-between items-start'
  );
  const iconCls = classNames(iconBaseStyle, iconTypeStyle, iconSizeStyle);
  const titleCls = classNames(
    titleBaseStyle,
    titleTypeStyle,
    titleSizeStyle,
    message || children ? 'mb-1' : ''
  );
  const messageCls = classNames(
    messageBaseStyle,
    messageTypeStyle,
    messageSizeStyle
  );
  const actionCls = classNames(
    inlineActionBaseStyle,
    inlineActionTypeStyle,
    inlineActionSizeStyle
  );
  const closeCls = classNames(closeBaseStyle, closeTypeStyle, closeSizeStyle);

  return (
    <div className={cls} role="alert" {...other}>
      {showIcon && AlertIcon ? <AlertIcon className={iconCls} /> : null}
      <div className="flex-1">
        {title ? <div className={titleCls}>{title}</div> : null}
        {message && !children ? (
          <div className={messageCls}>{message}</div>
        ) : (
          <div>{children}</div> || null
        )}
      </div>
      {onAction && (
        <button
          onClick={onAction}
          aria-label="inline-action"
          className={actionCls}
        >
          {actionLabel}
        </button>
      )}
      {onClose && (
        <button onClick={onClose} aria-label="close">
          <XIcon className={closeCls} />
        </button>
      )}
    </div>
  );
};

export default Alert;
