import React from 'react';
import { Control, Controller } from 'react-hook-form';
import Select, { Option } from 'ui/components/Select';
import { InvestmentsFormData } from '../InvestmentsForm.types';

type InvestmentsFormInvestorInputProps = {
  control: Control<InvestmentsFormData, unknown>;
  index: number;
  options: Option[];
  onAddInvestorClick: (index: number) => void;
  onChange: (index: number) => void;
  error?: string;
  disabled?: boolean;
};
export const InvestmentsFormInvestorInput: React.FC<
  InvestmentsFormInvestorInputProps
> = (props) => {
  return (
    <Controller
      name={`investments.${props.index}.investorId`}
      control={props.control}
      rules={{
        validate: (_investorId, formValues) => {
          const investorIds = formValues.investments.map(
            (investment) => investment.investorId
          );
          const uniqueInvestorIds = new Set(investorIds);
          return (
            uniqueInvestorIds.size === investorIds.length ||
            'Duplicate investors are not allowed'
          );
        },
      }}
      render={({ field }) => {
        return (
          <Select
            {...field}
            value={field.value}
            placeholder="Select an investor"
            options={props.options}
            optionsButton={{
              text: '+ Add New investor',
              onClick: () => props.onAddInvestorClick(props.index),
            }}
            onChange={(e) => {
              field.onChange(e);
              props.onChange(props.index);
            }}
            error={props.error}
            disabled={props.disabled}
            className="w-[180px] mt-[6px]"
            sortOptions
          />
        );
      }}
    />
  );
};
