import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { isAfter, isSameDay } from 'date-fns';
import {
  Alert,
  Button,
  Dialog,
  FormInput,
  ModalV2 as Modal,
  Select,
  Title,
  ToggleSwitch,
  Watch,
} from 'ui';
import { ArrowSmLeftIcon, TrashIcon, XIcon } from '@heroicons/react/outline';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { DevTool } from '@hookform/devtools';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useDisburseTrancheMutation } from '@/services/loans';
import {
  useCreateCounterpartyMutation,
  useGetOriginatorCounterpartiesQuery,
} from '@/services/loans/counterparty';
import { useGetLoanFacilitiesForLoanQuery } from '@/services/loans/facility';
import {
  useGetTransactionsQuerySubscription,
  useLazyGetTransactionsQuery,
} from '@/services/loans/transactions';
import { useSelectUser } from '@/store/user/selectors';
import { parseISOToZonedDate, zonedNow } from '@/utils/datetime';
import { formatAmount, formatDate } from '@/utils/formatters';
import { toFloatOrDefault } from '@/utils/helpers';
import { CounterpartyForm } from '../counterparty/CounterpartyForm';
import { CounterpartySelectInput } from '../counterparty/CounterpartySelectInput';
import style from './DisburseTrancheModal.module.css';
import {
  CreateCounterpartyDto,
  DisburseTrancheMutationInput,
  Loan,
  LoanProductRules,
  Tranche,
} from '@/interfaces/loans';
import {
  LoanFacility,
  LoanFacilityAllocationStatus,
} from '@/interfaces/loans/facility';

const EditTrancheModalContent = () => {
  return (
    <>
      <div className="flex justify-center pt-4">
        <span className="rounded-full w-12 h-12 bg-neutral-200 flex justify-center items-center">
          <InformationCircleIcon className="h-6 w-6 text-neutral-900" />
        </span>
      </div>

      <p className="pt-4 text-center text-neutral-900">
        To edit tranches details please contact support at{' '}
        <span className="heading-400">help@kennek.io</span>.
      </p>
    </>
  );
};

export type DisburseTrancheModalProps = {
  loan: Loan;
  rules: LoanProductRules;
  onEditDate: () => void;
  onSuccess?: () => void;
  tranche?: Tranche;
};

const FACILITIES_ALLOCATIONS_KEY = 'facilitiesAllocations';

type FacilitiesAllocations = {
  facilityId: string;
  allocation: string;
}[];

type TrancheForm = {
  fees: Record<string, { value: string; enabled: boolean }>;
  [FACILITIES_ALLOCATIONS_KEY]: FacilitiesAllocations;
  counterpartyId?: string;
  errorField?: string;
  _transactions?: unknown;
  applyInterestRetained?: boolean;
};

const DisburseTrancheModalContent = ({
  loan,
  rules,
  onEditDate,
  onSuccess,
  tranche,
}: DisburseTrancheModalProps) => {
  const [disburseTranche, { isLoading: isDisbursingTranche }] =
    useDisburseTrancheMutation();

  const user = useSelectUser();

  const [getTransactions] = useLazyGetTransactionsQuery();

  const { data: loanFacilities = [], ...loanFacilitiesQuery } =
    useGetLoanFacilitiesForLoanQuery(loan?.encodedKey, {
      skip: !loan?.encodedKey,
    });

  useGetTransactionsQuerySubscription(
    {
      branchEncodedKey: user?.mambuUser?.[0]?.mambuBranchEncodedKey,
      loanEncodedKey: loan?.encodedKey,
    },
    { skip: !user?.mambuUser?.[0]?.mambuBranchEncodedKey || !loan?.encodedKey }
  );

  const getNotEditibleAllocations = useCallback(
    (loanFacility: LoanFacility) => {
      return loanFacility.allocations?.filter(
        (allocation) =>
          (allocation.type === 'KENNEK_TRANCHE_ALLOCATION'
            ? allocation.kennekTrancheId !== tranche.id
            : allocation.mambuTrancheId !== tranche.id) ||
          allocation.status === LoanFacilityAllocationStatus.DISBURSED
      );
    },
    [tranche.id]
  );

  const facilitiesOptions = useMemo(() => {
    return loanFacilities
      .filter((loanFacility) => {
        const notEditibleAllocations = getNotEditibleAllocations(loanFacility);
        const allocated =
          loanFacility.amount ===
            notEditibleAllocations.reduce(
              (prev, { amount: curr }) => prev + curr,
              0
            ) || 0;
        return !allocated;
      })
      .map((loanFacility) => ({
        value: loanFacility?.facilityId,
        label: loanFacility?.name,
      }));
  }, [getNotEditibleAllocations, loanFacilities]);

  const form = useForm<TrancheForm>({
    defaultValues: mapToForm({ rules, tranche }),
    mode: 'onChange',
  });

  const facilitiesFields = form.getValues(FACILITIES_ALLOCATIONS_KEY) || [];

  const isSubmittingForm = form.formState.isSubmitting;

  const snackbar = useSnackbar();

  const hasCapitalizedFees = rules?.fees?.length > 0;

  const trancheDate = getTrancheDate(tranche);

  const isSubmitButtonDisabled = isDisbursingTranche || isSubmittingForm;

  const getFacilityAllocation = (facilityId: string): number => {
    const allocations = loanFacilities.reduce((result, loanFacility) => {
      const notEditibleAllocations =
        getNotEditibleAllocations(loanFacility).reduce(
          (prev, { amount: curr }) => prev + curr,
          0
        ) || 0;

      const editibleAllocations =
        parseFloat(
          facilitiesFields.find(
            (facilityAllocation) =>
              facilityAllocation?.facilityId === loanFacility.facilityId
          )?.allocation
        ) || 0;

      result[loanFacility.facilityId] =
        loanFacility.amount - notEditibleAllocations - editibleAllocations;
      return result;
    }, {});

    return parseFloat(allocations[facilityId]);
  };

  const allocatedFacilitiesSum = () => {
    return facilitiesFields.reduce(
      (acc, { allocation }) => acc + parseFloat(allocation ? allocation : '0'),
      0
    );
  };

  const validateTotalAllocation = () => {
    if (allocatedFacilitiesSum() > tranche?.amount) {
      form.setError('errorField', {
        message: `Total facilities allocated amount can't be greater than ${formatAmount(
          tranche?.amount,
          true,
          rules?.currency
        )}`,
        type: 'manual',
      });
    } else {
      form.clearErrors(`errorField`);
    }
  };

  const onConfirm = () => {
    if (!facilitiesOptions?.length) {
      form.clearErrors(FACILITIES_ALLOCATIONS_KEY);
      form.clearErrors(`errorField`);
    }

    if (loanFacilitiesQuery.isError) {
      return;
    }

    validateTotalAllocation();

    form.handleSubmit((data: TrancheForm) => {
      disburseTranche({
        loanId: loan?.encodedKey,
        body: {
          trancheId: tranche?.id,
          fees: mapFeesToDto(data),
          facilitiesAllocations: mapFacilitiesAllocationsToDto(data),
          counterpartyId: data.counterpartyId,
          applyInterestRetained: data.applyInterestRetained,
        },
      })
        .unwrap()
        .then(() => {
          snackbar.show({
            severity: 'success',
            title: 'The tranche has been disbursed.',
          });
          onSuccess?.();
        })
        .catch(() => {
          snackbar.show({
            severity: 'error',
            title: 'The disbursement has failed, please try again.',
            content:
              'If the problem persists please contact support at help@kennek.io.',
          });
        });
    })();
  };

  const replaceFacilities = (facilitiesFields: FacilitiesAllocations) => {
    form.unregister([FACILITIES_ALLOCATIONS_KEY]);

    facilitiesFields.map((field, fieldIndex) => {
      form.register(`${FACILITIES_ALLOCATIONS_KEY}.${fieldIndex}.allocation`);
      form.register(`${FACILITIES_ALLOCATIONS_KEY}.${fieldIndex}.facilityId`);

      if (field.allocation) {
        form.setValue(
          `${FACILITIES_ALLOCATIONS_KEY}.${fieldIndex}.allocation`,
          field.allocation
        );
      }

      if (field.facilityId) {
        form.setValue(
          `${FACILITIES_ALLOCATIONS_KEY}.${fieldIndex}.facilityId`,
          field.facilityId
        );
      }
    });
  };

  const removeFacility = (index: number) => {
    const facilitiesFieldsCopy = facilitiesFields.filter(
      (_, fieldIndex) => fieldIndex !== index
    );
    replaceFacilities(facilitiesFieldsCopy);
  };

  const addFacility = () => {
    replaceFacilities(facilitiesFields);
    const newFacilityIndex = facilitiesFields.length;
    form.register(
      `${FACILITIES_ALLOCATIONS_KEY}.${newFacilityIndex}.allocation`
    );
    form.register(
      `${FACILITIES_ALLOCATIONS_KEY}.${newFacilityIndex}.facilityId`
    );
  };

  const validateAllocation = (facilityId: string) => {
    if (getFacilityAllocation(facilityId) < 0) {
      return 'Amount cannot exceed facility amount';
    }
  };

  const getAvailableFacilitiesOptions = (facilityId: string) => {
    const selectedFacilitiessOptions = facilitiesFields.map(
      (facility) => facility.facilityId
    );
    return facilitiesOptions?.filter(
      (facility) =>
        !selectedFacilitiessOptions.includes(facility.value) ||
        facility.value === facilityId
    );
  };

  const getAvailableToAllocateLabel = (facilityId?: string) => {
    if (!facilityId) return '';

    return `Available to allocate ${formatAmount(
      getFacilityAllocation(facilityId),
      true,
      rules?.currency
    )}`;
  };

  const isRetainInterestOn = form.watch('applyInterestRetained');

  // ? COUNTERPARTIES HANDLING START
  const [counterpartyDialogOpen, setCounterpartyDialogOpen] = useState(false);

  const getCounterpartiesQuery = useGetOriginatorCounterpartiesQuery(
    {},
    { skip: !rules?.disbursementBankAccountDetails }
  );

  const [createCounterparty, createCounterpartyMutationState] =
    useCreateCounterpartyMutation();

  const createCounterpartySubmitHandler = async (
    data: CreateCounterpartyDto
  ) => {
    try {
      const newCounterparty = await createCounterparty(data).unwrap();
      form.setValue(`counterpartyId`, newCounterparty.id);
      snackbar.show({
        severity: 'success',
        title: 'Counterparty created successfully.',
      });
      setCounterpartyDialogOpen(false);
      getCounterpartiesQuery.refetch();
    } catch (err) {
      snackbar.show({
        severity: 'error',
        title: 'Failed to create counterparty.',
      });
    }
  };
  // ! COUNTERPARTIES HANDLING END

  return (
    <>
      {rules.disbursementBankAccountDetails && (
        <Dialog
          open={counterpartyDialogOpen}
          onClose={() => setCounterpartyDialogOpen(false)}
        >
          <CounterpartyForm
            isLoading={createCounterpartyMutationState.isLoading}
            onCancel={() => setCounterpartyDialogOpen(false)}
            onSubmit={(data) => createCounterpartySubmitHandler(data)}
          />
        </Dialog>
      )}

      <Modal.CloseButton />
      <Modal.Title>Disbursement details</Modal.Title>
      <p className="heading-100 text-neutral-800">
        Review the disbursement details before confirm
      </p>
      {!!tranche?.expectedRetainedInterest && (
        <div className="flex items-end mt-5">
          <FormInput
            label="Do you want to retain interest"
            value={tranche?.expectedRetainedInterest}
            currencyInput={rules?.currency}
            disabled
            containerClassName="!h-[60px] flex-1"
            type="number"
          />

          <Controller
            control={form.control}
            name="applyInterestRetained"
            render={({ field }) => (
              <div className="ml-[8px] mr-[3px]">
                <ToggleSwitch
                  enabled={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              </div>
            )}
          />
        </div>
      )}

      {hasCapitalizedFees && (
        <>
          <p className="heading-100 text-neutral-800 mt-5 mb-2">
            Do you want to include capitalised fees?
          </p>
          {rules?.fees?.map(
            ({
              id,
              name,
              calculationMethod,
              percentageAmount,
              amount: predefinedAmount,
            }) => (
              <div
                key={id}
                className="flex [&>*:first-child]:w-full gap-x-2 relative mb-2"
              >
                <Watch control={form.control} name={`fees.${id}.enabled`}>
                  {(enabled) => (
                    <>
                      {calculationMethod === 'PERCENTAGE' && (
                        <Controller
                          control={form.control}
                          name={`fees.${id}.value`}
                          render={({ field }) => (
                            <FormInput
                              ref={field.ref}
                              name={field.name}
                              value={enabled ? field.value : ''}
                              onValueChange={field.onChange}
                              onBlur={field.onBlur}
                              currencyInput={rules?.currency}
                              label={name}
                              disabled
                              type="number"
                              bottomLabel={`${
                                percentageAmount || 0
                              }% of disbursement amount`}
                            />
                          )}
                        />
                      )}

                      {calculationMethod === 'FLAT' && (
                        <Controller
                          control={form.control}
                          name={`fees.${id}.value`}
                          rules={{
                            required: enabled
                              ? `${name} cannot be empty`
                              : undefined,
                            validate: enabled
                              ? {
                                  min: (value) =>
                                    parseFloat(value) > 0
                                      ? true
                                      : `${name} cannot be less than ${formatAmount(
                                          0,
                                          false,
                                          rules?.currency
                                        )}`,
                                }
                              : undefined,
                          }}
                          render={({ field, fieldState }) => (
                            <FormInput
                              ref={field.ref}
                              name={field.name}
                              value={enabled ? field.value : ''}
                              onValueChange={(value) =>
                                field.onChange(value ?? '')
                              }
                              onBlur={field.onBlur}
                              errors={fieldState?.error?.message}
                              disabled={!enabled || predefinedAmount > 0}
                              label={name}
                              currencyInput={rules?.currency}
                              type="number"
                              withSpacing={!!fieldState.error}
                              dynamicHeightErrorBanner={!!fieldState.error}
                              autoComplete="off"
                            />
                          )}
                        />
                      )}
                    </>
                  )}
                </Watch>

                <Controller
                  control={form.control}
                  name={`fees.${id}.enabled`}
                  render={({ field }) => (
                    <div className="[&>*]:top-[30px] pr-[4px]">
                      <ToggleSwitch
                        enabled={field.value}
                        onChange={(value) => {
                          form.clearErrors(`fees.${id}.value`);
                          field.onChange(value);
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            )
          )}
        </>
      )}
      {facilitiesOptions?.length > 0 && (
        <>
          <div className="flex justify-between items-top gap-5">
            <label className="heading-100 w-[176px] mt-1">Facility</label>
            <label className="heading-100 w-[176px] mt-1">Amount</label>
            <div className="w-[42px]"></div>
          </div>

          <div
            className={`grid grid-cols-[1fr_1fr_40px] items-start gap-x-3 gap-y-1`}
          >
            {facilitiesFields.map((row, index) => (
              <Fragment key={index}>
                <Controller
                  name={
                    `${FACILITIES_ALLOCATIONS_KEY}.${index}.facilityId` as `${typeof FACILITIES_ALLOCATIONS_KEY}.${number}.facilityId`
                  }
                  control={form.control}
                  rules={{
                    required: 'Facility field is required',
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <Select
                        {...field}
                        value={form.watch(
                          `${FACILITIES_ALLOCATIONS_KEY}.${index}.facilityId`
                        )}
                        placeholder="Select a facility"
                        className={`mt-[0.59rem] ${
                          fieldState?.error?.message ? 'mb-5' : ''
                        } ${style['select-input']}`}
                        height=""
                        error={fieldState?.error?.message}
                        onChange={(value) => {
                          field.onChange(value);
                          if (row.allocation) {
                            form.trigger(
                              `${FACILITIES_ALLOCATIONS_KEY}.${index}.allocation`
                            );
                          }
                        }}
                        options={getAvailableFacilitiesOptions(row.facilityId)}
                      />
                    );
                  }}
                />

                <Controller
                  name={
                    `${FACILITIES_ALLOCATIONS_KEY}.${index}.allocation` as `${typeof FACILITIES_ALLOCATIONS_KEY}.${number}.allocation`
                  }
                  control={form.control}
                  rules={{
                    required: 'Allocation field is required',
                    validate: () => validateAllocation(row.facilityId),
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <FormInput
                        ref={field.ref}
                        name={field.name}
                        value={form.watch(
                          `${FACILITIES_ALLOCATIONS_KEY}.${index}.allocation`
                        )}
                        onValueChange={(value) => {
                          field.onChange(value?.toString() ?? '');
                        }}
                        onBlur={() => {
                          field.onBlur();
                        }}
                        bottomLabel={getAvailableToAllocateLabel(
                          row.facilityId
                        )}
                        errors={fieldState?.error?.message}
                        currencyInput={rules?.currency}
                        containerClassName="mb-8"
                        type="number"
                        withSpacing={false}
                        dynamicHeightErrorBanner={false}
                        autoComplete="off"
                      />
                    );
                  }}
                />

                <Button
                  icon={<TrashIcon />}
                  layout="ghost"
                  className="h-[50px] mt-[0.1rem]"
                  onClick={() => removeFacility(index)}
                />
              </Fragment>
            ))}
          </div>
        </>
      )}
      {facilitiesFields.length < facilitiesOptions?.length &&
        facilitiesOptions?.length > 0 && (
          <div
            className="text-secondary-400 mt-2 cursor-pointer flex items-center heading-200 w-min whitespace-nowrap"
            onClick={addFacility}
          >
            + Add another facility
          </div>
        )}
      {facilitiesOptions?.length > 0 && (
        <div className="w-full mt-6 mb-4">
          <div className="flex items-start">
            <div className="flex-grow flex flex-col">
              <span className="body-200 text-neutral-700 mb-2">
                Total facilities selected
              </span>
              <span className="text-sm/[11px]  mr-2">
                {facilitiesFields.length} of {facilitiesOptions?.length || 0}
              </span>
            </div>
            <div className="flex-grow flex flex-col">
              <span className="body-200 text-neutral-700 mb-2">
                Allocated amount
              </span>
              <span className="text-sm/[11px]  mr-2">
                {formatAmount(allocatedFacilitiesSum())} of{' '}
                {formatAmount(tranche.amount || 0)}
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="h-[2px] bg-neutral-300 my-6"></div>

      {rules.disbursementBankAccountDetails && (
        <Controller
          control={form.control}
          name={`counterpartyId`}
          rules={{
            validate: (value) =>
              (value !== '' && value !== undefined && value !== null) ||
              'Counterparty account cannot be empty.',
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <CounterpartySelectInput
                disabled={
                  getCounterpartiesQuery.isFetching ||
                  getCounterpartiesQuery.isLoading
                }
                counterparties={getCounterpartiesQuery.currentData ?? []}
                onValueChange={(value) => {
                  form.clearErrors(`counterpartyId`);
                  field.onChange(value);
                }}
                value={form.watch(`counterpartyId`)}
                error={error?.message}
                onAddNewClick={() => {
                  setCounterpartyDialogOpen(true);
                }}
              />
            );
          }}
        />
      )}

      {form.formState?.errors?.errorField?.message && (
        <div className={'bg-error-200 rounded-lg items-center p-4'}>
          <Title
            title={form.formState?.errors?.errorField?.message}
            insideCard={true}
            titleSize={'sm'}
            icon={<XIcon />}
          />
        </div>
      )}
      <div
        className={classNames('p-5 bg-neutral-100 mb-6 rounded-md', {
          'mt-10': !hasCapitalizedFees,
          'mt-5': hasCapitalizedFees,
        })}
      >
        {hasCapitalizedFees && (
          <div className="border-b border-neutral-400 pb-5 ">
            <div className="flex justify-between">
              <p className="text-neutral-800 heading-200">Principal amount</p>
              <p className="text-neutral-800 heading-200">
                {formatAmount(tranche?.amount, true, loan?.currency)}
              </p>
            </div>

            {isRetainInterestOn && (
              <div className="flex justify-between">
                <p className="text-neutral-800 heading-200">Retain interest</p>
                <p className="text-neutral-800 heading-200">
                  {formatAmount(
                    tranche?.expectedRetainedInterest,
                    true,
                    loan?.currency
                  )}
                </p>
              </div>
            )}

            <Watch control={form.control} name="fees">
              {(fees) =>
                Object.entries(fees ?? {})
                  .filter(([, { enabled }]) => enabled)
                  .map(([id, { value }]) => (
                    <div key={id} className="flex justify-between">
                      <p className="text-neutral-800 heading-200">
                        {rules?.fees?.find((fee) => fee.id === id)?.name}
                      </p>

                      <p className="text-neutral-800 heading-200">
                        {formatAmount(value, true, loan?.currency)}
                      </p>
                    </div>
                  ))
              }
            </Watch>
          </div>
        )}

        <div
          className={classNames('space-y-2', {
            'pt-5': hasCapitalizedFees,
          })}
        >
          <div className="flex justify-between">
            <p className="text-neutral-800 heading-300">Total Amount</p>
            <p className="text-neutral-800 heading-500">
              <Watch control={form.control} name="fees">
                {(fees) =>
                  formatAmount(
                    (tranche?.amount || 0) +
                      Object.entries(fees ?? {})
                        .filter(
                          ([id]) =>
                            rules?.fees?.find((fee) => fee.id === id)
                              ?.trigger !== 'DISBURSEMENT'
                        )
                        .filter(([, { enabled }]) => enabled)
                        .reduce(
                          (total, [, fee]) =>
                            total + toFloatOrDefault(fee.value, 0),
                          0
                        ),
                    true,
                    loan?.currency
                  )
                }
              </Watch>
            </p>
          </div>

          {trancheDate === 'past' && (
            <>
              <div className="flex justify-between">
                <p className="text-neutral-800 heading-300">
                  Disbursement date
                </p>
                <p className="text-neutral-800 heading-500">
                  {formatDate(tranche?.disbursementDate, 'dd/MM/yyyy')}
                </p>
              </div>

              <div className="flex justify-end">
                <Button
                  layout="link"
                  className="!p-0 !heading-100"
                  onClick={onEditDate}
                >
                  Edit date
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <Controller
        control={form.control}
        name="_transactions"
        rules={{
          validate: () =>
            getTransactions(
              {
                branchEncodedKey: user?.mambuUser?.[0]?.mambuBranchEncodedKey,
                loanEncodedKey: loan?.encodedKey,
              },
              true
            )
              .unwrap()
              .then(
                (transactions) =>
                  !transactions?.data
                    ?.filter(
                      ({ type, adjusted }) => type === 'RECONCILE' && !adjusted
                    )
                    .some((transaction) => {
                      const transactionDate = parseISOToZonedDate(
                        transaction?.valueDate
                      );
                      const disbursementDate = parseISOToZonedDate(
                        tranche?.disbursementDate
                      );
                      isAfter(transactionDate, disbursementDate) &&
                        !isSameDay(transactionDate, disbursementDate);
                    })
              )
              .catch(() => false),
        }}
        render={({ fieldState }) => (
          <>
            {fieldState?.error && (
              <Alert
                type="error"
                className="w-full mt-3 mb-6"
                title="Disbursements cannot be made before existing transactions."
              >
                <p className="body-400 mt-1 text-neutral-800">
                  To confirm the disbursement, change the disbursement date or
                  adjust the existing transactions before the dibursement date.
                </p>
              </Alert>
            )}
          </>
        )}
      />
      <div className="flex justify-between mx-20">
        <Modal.Close asChild disabled={isSubmitButtonDisabled}>
          <Button layout="ghost">Cancel</Button>
        </Modal.Close>

        <Button
          layout="ghost"
          onClick={onConfirm}
          disabled={isSubmitButtonDisabled}
          loading={isSubmitButtonDisabled}
        >
          Confirm
        </Button>
      </div>
      <DevTool control={form.control} />
    </>
  );
};

const mapFeesToDto = (
  form: TrancheForm
): DisburseTrancheMutationInput['fees'] => {
  return Object.entries(form.fees ?? {})
    .filter(([, fees]) => fees.enabled && fees.value)
    .map(([predefinedFeeEncodedKey, fees]) => ({
      amount: parseFloat(fees.value),
      predefinedFeeEncodedKey,
    }));
};

const mapFacilitiesAllocationsToDto = (
  form: TrancheForm
): DisburseTrancheMutationInput['facilitiesAllocations'] => {
  return form.facilitiesAllocations?.length
    ? form.facilitiesAllocations?.map((facility) => ({
        amount: parseFloat(facility.allocation),
        facilityId: facility.facilityId,
      }))
    : [];
};

const mapToForm = ({
  rules,
  tranche,
}: {
  rules: LoanProductRules;
  tranche: Tranche;
}): TrancheForm => {
  const fees = rules?.fees?.reduce(
    (fees, feeRule) => ({
      ...fees,
      [feeRule.id]: {
        value: (() => {
          switch (feeRule.calculationMethod) {
            case 'FLAT': {
              return (
                tranche?.fees?.find(
                  (fee) => fee.predefinedFeeEncodedKey === feeRule.id
                )?.amount ?? feeRule.amount
              );
            }
            case 'PERCENTAGE': {
              const percentageAmount = feeRule.percentageAmount || 0;
              const disbursementAmount = tranche?.amount || 0;
              if (!disbursementAmount) return '';
              return (
                disbursementAmount * (percentageAmount / 100) || 0
              )?.toString();
            }
          }
        })(),
        enabled: true,
      },
    }),
    {}
  );

  const facilitiesAllocations = tranche.facilities?.map((facility) => ({
    allocation: facility.amount.toString(),
    facilityId: facility.facilityId,
  }));

  return {
    fees,
    facilitiesAllocations,
    counterpartyId: tranche.counterpartyId,
    applyInterestRetained: !!tranche?.expectedRetainedInterest,
  };
};

const getTrancheDate = (tranche: Tranche) => {
  if (!tranche?.disbursementDate) return;

  const disbursementDate = parseISOToZonedDate(tranche?.disbursementDate);

  const now = zonedNow();

  return isAfter(disbursementDate, now)
    ? 'future'
    : isSameDay(disbursementDate, now)
      ? 'today'
      : isAfter(now, disbursementDate)
        ? 'past'
        : undefined;
};

const ModalBackButton = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  return (
    <Modal.TopLeftButton asChild>
      <button {...props}>
        <ArrowSmLeftIcon className="w-[20px] h-[20px]" />
      </button>
    </Modal.TopLeftButton>
  );
};

const TrancheModal = {
  Disburse: DisburseTrancheModalContent,
  Edit: EditTrancheModalContent,
  Root: Modal.Root,
  Content: Modal.Content,
  BackButton: ModalBackButton,
  CloseButton: Modal.CloseButton,
};

export { TrancheModal, getTrancheDate };
