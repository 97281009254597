import { useEffect, useState } from 'react';
import { Option } from 'ui/components/Select';
import { arrayEmpty, isEmpty } from '@/utils/validation';
import { InvestmentType } from 'kennek/interfaces/accounts/investor';

export enum InvestmentsFormColumName {
  INVESTOR_NAME = 'INVESTOR_NAME',
  LENDER_OF_RECORDS = 'LENDER_OF_RECORDS',
  AMOUNT_INVESTED = 'AMOUNT_INVESTED',
  INVESTOR_RATE = 'INVESTOR_RATE',
  FREQUENCY = 'FREQUENCY',
}

export const InvestmentTypeLabel: Record<InvestmentType, string> = {
  [InvestmentType.SENIOR]: 'Senior',
  [InvestmentType.MEZZANINE]: 'Mezzanine',
  [InvestmentType.JUNIOR]: 'Junior',
  [InvestmentType.NONE]: 'None',
};

export const getInvestmentTypeLabel = (type?: InvestmentType): string => {
  switch (type) {
    case InvestmentType.SENIOR:
      return InvestmentTypeLabel[InvestmentType.SENIOR];
    case InvestmentType.MEZZANINE:
      return InvestmentTypeLabel[InvestmentType.MEZZANINE];
    case InvestmentType.JUNIOR:
      return InvestmentTypeLabel[InvestmentType.JUNIOR];
    case InvestmentType.NONE:
      return InvestmentTypeLabel[InvestmentType.NONE];
    default:
      return 'Unknown';
  }
};

export const INVESTMENTS_FORM_COLUMNS_WIDTHS: Record<
  InvestmentsFormColumName,
  number
> = {
  [InvestmentsFormColumName.INVESTOR_NAME]: 196,
  [InvestmentsFormColumName.LENDER_OF_RECORDS]: 248,
  [InvestmentsFormColumName.AMOUNT_INVESTED]: 164,
  [InvestmentsFormColumName.INVESTOR_RATE]: 116,
  [InvestmentsFormColumName.FREQUENCY]: 156,
};

export const INVESTMENT_TYPE_INPUT_OPTIONS: Option[] = [
  {
    label: InvestmentTypeLabel[InvestmentType.SENIOR],
    value: InvestmentType.SENIOR,
  },
  {
    label: InvestmentTypeLabel[InvestmentType.MEZZANINE],
    value: InvestmentType.MEZZANINE,
  },
  {
    label: InvestmentTypeLabel[InvestmentType.JUNIOR],
    value: InvestmentType.JUNIOR,
  },
  {
    label: InvestmentTypeLabel[InvestmentType.NONE],
    value: InvestmentType.NONE,
  },
];

export const getAddInvestorButtonLabel = (investmentsCount = 0) =>
  `+ Add ${investmentsCount > 0 ? 'another' : ''} investor`;

export const getLabelByValue = (
  options: Option[],
  lenderOfRecordId: string
): string => {
  if (arrayEmpty(options)) {
    return '';
  }

  const option = options.find((option) => option.value === lenderOfRecordId);

  if (isEmpty(option)) {
    return '';
  }

  return option.label;
};

export const createEmptyInvestment = () => ({
  investorId: undefined,
  amount: undefined,
  id: undefined,
  interestRate: undefined,
  lenderOfRecordId: undefined,
});

export const isAnyInvestmentTypeDefined = (
  investments: Array<{ type?: InvestmentType }>
): boolean => {
  return investments?.some((investment) => investment.type !== null);
};

/**
 * Wrapper for useState() for managing state of investment type setting toggle.
 * It sets initial toggle state to enabled if at least one investment has non-
 * empty type defined.
 * @param investments
 * @returns
 */
export const useInvestmentTypeSettingState = (
  investments: Array<{ type?: InvestmentType }>
) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setEnabled(isAnyInvestmentTypeDefined(investments));
  }, [investments, setEnabled]);

  return {
    investmentTypeSettingEnabled: enabled,
    setInvestmentTypeSettingEnabled: setEnabled,
  };
};
