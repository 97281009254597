import React, { createContext, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/outline';
import { NavigationContext } from './Navigation';
import { RootContext } from './Root';

type StepProps<T extends string> = {
  id: T;
  title: string;
  description?: string;
  children?: React.ReactNode;
  hidden?: boolean;
  showPosition?: boolean;
  accessible?: boolean;
};

type StepContext = {
  index: number;
  parentIndex: number;
  substepsCount: number;
  stepId: string;
};

const StepContext = createContext<StepContext>({} as StepContext);

const Step = <T extends string>({
  title,
  description,
  children,
  id: stepId,
  hidden = false,
  showPosition = false,
  accessible = true,
}: StepProps<T>) => {
  const { control, disableSkip } = useContext(RootContext);
  const { index: stepIndex } = useContext(NavigationContext);

  const isActiveStep = control.activeStepId?.split('.')[0] === stepId;

  const areSubstepsCompleted = ({ stepId }: { stepId: string }) => {
    const substeps = control.internal.getSubsteps({ stepId });

    if (substeps.length === 0) return false;

    return substeps.reduce(
      (acc, curr) => acc && control.internal.completed.has(curr),
      true
    );
  };

  const isCompleted =
    control.internal.completed.has(stepId) ||
    // changes in only `structureRef` won't update `isCompleted`
    areSubstepsCompleted({ stepId });

  const position = stepIndex;

  const substepsCount = React.Children.count(children);

  const substeps = React.Children.map(children, (element, index) => (
    <StepContext.Provider
      value={{
        index,
        parentIndex: stepIndex * 100,
        substepsCount,
        stepId,
      }}
    >
      {element}
    </StepContext.Provider>
  ));

  const hasSubsteps = substepsCount > 0;

  const {
    internal: { structureRef },
  } = control;

  useEffect(() => {
    if (hasSubsteps) return;
    const structure = structureRef.current;
    structure.add({
      stepId,
      order: stepIndex * 100,
      accessible,
    });
    return () => {
      structure.delete(stepId);
    };
  }, [stepId, structureRef, hasSubsteps, accessible]);

  const isButtonEnabled =
    !disableSkip && control.internal.isButtonEnabled(stepId);

  return (
    <div className={classNames({ hidden })}>
      <div className="flex flex-col">
        <button
          onClick={() => control.internal.onClick(stepId)}
          type="button"
          className={classNames('w-[241px] px-5 pt-6 flex items-center', {
            'cursor-pointer': isButtonEnabled,
            'cursor-default': !isButtonEnabled,
          })}
        >
          <div
            className={classNames(
              'bg-white text-neutral-600 heading-200 rounded-full flex min-w-[26px] w-[26px] h-[26px] justify-center items-center ml-2',
              {
                'text-primary-900': isActiveStep && !isCompleted,
                'text-primary-700': isCompleted,
              }
            )}
          >
            {isCompleted && !showPosition ? <CheckIcon /> : position}
          </div>
          <div className="ml-3 flex flex-col items-start">
            <span
              className={classNames(
                'heading-100 text-neutral-600 text-ellipsis overflow-hidden text-left',
                {
                  'text-primary-900': isActiveStep && !isCompleted,
                  'text-primary-700': isCompleted,
                }
              )}
            >
              {title}
            </span>
          </div>
        </button>
        {isActiveStep && description && (
          <div className="flex justify-end mt-1 pl-6 body-200 text-neutral-600 text-ellipsis overflow-hidden">
            <span className="basis-40 mr-3">{description}</span>
          </div>
        )}
      </div>

      <div
        className={classNames('ml-3 flex flex-col items-start pt-4', {
          hidden: !hasSubsteps || !isActiveStep,
        })}
      >
        {substeps}
      </div>
    </div>
  );
};

export { NavigationContext, Step, StepContext };
export type { StepProps };
