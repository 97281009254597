import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { ThemeContext } from 'ui/theme';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { formatLineBreaks } from '../utils/FormatText.utils';
import Tooltip from './Tooltip';

export type InputLayoutType = 'primary' | 'dark';

export interface InputProps extends React.ComponentPropsWithRef<'input'> {
  /**
   * Defines the color of the input
   */
  layout?: InputLayoutType;
  valid?: boolean;
  error?: boolean;
  /**
   * Defines if the input is disabled
   */
  disabled?: boolean;
  /**
   * Defines the type of the input
   */
  type?: string;
  tooltip?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  function Input(props, ref) {
    const {
      valid,
      error,
      disabled,
      className,
      type = 'text',
      layout = 'primary',
      tooltip = '',
      ...other
    } = props;

    const {
      theme: { input },
    } = useContext(ThemeContext);

    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const baseStyle = input.base;
    const activeStyle = input.active;
    const activeDarkStyle = input.activeDark;
    const disabledStyle = input.disabled;
    const validStyle = input.valid;
    const invalidStyle = input.invalid;
    const radioStyle = input.radio;
    const checkStyle = input.checkbox;

    function hasValidation(valid: boolean | undefined) {
      return valid !== undefined;
    }

    function validationStyle(valid: boolean | undefined): string {
      if (hasValidation(valid)) {
        return valid ? validStyle : invalidStyle;
      }
      return '';
    }

    function typeStyle(type: string): string {
      switch (type) {
        case 'radio':
          return radioStyle;
        case 'checkbox':
          return checkStyle;
        default:
          return baseStyle;
      }
    }

    function getActiveStyleBaseColorType() {
      return layout === 'dark' ? activeDarkStyle : activeStyle;
    }

    const cls = classNames(
      typeStyle(type),
      // don't apply activeStyle if has valid or disabled
      !hasValidation(valid) && !disabled && getActiveStyleBaseColorType(),
      // don't apply invalidStyle if has valid or disabled
      !hasValidation(valid) && !disabled && error && invalidStyle,
      // don't apply disabledStyle if has valid
      !hasValidation(valid) && disabled && disabledStyle,
      validationStyle(valid),
      className
    );

    if (type === 'number') {
      const onKeyPress = other.onKeyPress;
      other.onKeyPress = (e) => {
        const target = e.target as HTMLInputElement;
        const currentValue = target.value + e.key;
        if (e.key === '-' && !target.value) return;
        if (isNaN(+currentValue)) e.preventDefault();
        if (onKeyPress) onKeyPress(e);
      };
    }

    return (
      <div
        onMouseOver={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className="flex"
        data-testid={props.name}
      >
        <input
          className={cls}
          type={type}
          ref={ref}
          disabled={disabled}
          {...other}
        />
        {tooltip && (
          <div className="relative flex items-center mr-1">
            <QuestionMarkCircleIcon width={14} className="cursor-pointer" />
            {showTooltip && (
              <Tooltip position="right">{formatLineBreaks(tooltip)}</Tooltip>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default Input;
