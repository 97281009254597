import { useMemo } from 'react';
import { sum } from '@/utils/math';
import {
  getFeeFromRules,
  mapAndRemoveAmountFeesFromFactorLoan,
} from '../../onboarding/utils';
import {
  AmountToFundType,
  FeeTrigger,
  Loan,
  LoanProductRules,
} from '@/interfaces/loans';
import { Fees } from '@/interfaces/loans/fee-transaction';

export const useInvestmentsSummary = (
  loan: Loan,
  loanProductRules: LoanProductRules,
  amountToFundType: AmountToFundType,
  topUp?: number
) => {
  const capitalizedFees = useMemo(() => {
    return (loan?.tranches?.length ?? 0) > 0
      ? getFeesByTriggerFromTranches(
          loan,
          loanProductRules,
          'CAPITALIZED_DISBURSEMENT'
        )
      : getFeesByTrigger(loan, loanProductRules, 'CAPITALIZED_DISBURSEMENT');
  }, [loan, loanProductRules]);

  const deductedFees = useMemo(
    () =>
      (loan?.tranches?.length ?? 0) > 0
        ? getFeesByTriggerFromTranches(loan, loanProductRules, 'DISBURSEMENT')
        : getFeesByTrigger(loan, loanProductRules, 'DISBURSEMENT'),
    [loan, loanProductRules]
  );

  const originalLoanAmount = useMemo(() => {
    // here we want to show the original loan amount specified by user,
    // so in case if cap fees are included in the loan amount, we need to subtract them
    if (loan?.capFeesIncludedInLoanAmount) {
      const capFeesAmount = sum(capitalizedFees, (fee: Fees) => fee.amount);
      return (loan?.loanAmount ?? 0) - capFeesAmount;
    }

    return loan?.loanAmount;
  }, [capitalizedFees, loan?.capFeesIncludedInLoanAmount, loan?.loanAmount]);

  const amountWithCapitalizedFees = useMemo(() => {
    if (loan?.capFeesIncludedInLoanAmount) {
      return loan?.loanAmount;
    }
    return sum(capitalizedFees, (fee: Fees) => fee.amount, loan?.loanAmount);
  }, [capitalizedFees, loan?.loanAmount, loan?.capFeesIncludedInLoanAmount]);

  const amountWithoutDeductedFees = useMemo(() => {
    const deductedFeesAmount = sum(deductedFees, (fee: Fees) => fee.amount);

    return (originalLoanAmount ?? 0) - deductedFeesAmount;
  }, [deductedFees, originalLoanAmount]);

  const total = useMemo(() => {
    const loanAmountWithFees =
      (amountToFundType === AmountToFundType.GROSS
        ? amountWithCapitalizedFees
        : amountWithoutDeductedFees) ?? 0;

    return topUp ? loanAmountWithFees + topUp : loanAmountWithFees;
  }, [
    amountToFundType,
    amountWithCapitalizedFees,
    amountWithoutDeductedFees,
    topUp,
  ]);

  return {
    originalLoanAmount,
    capitalizedFees,
    deductedFees,
    total,
  };
};

const getFeesByTrigger = (
  loan: Loan,
  rules: LoanProductRules,
  trigger: FeeTrigger
): Fees[] =>
  mapAndRemoveAmountFeesFromFactorLoan(rules, loan?.disbursementDetails?.fees)
    .map(({ fee, feeFound }) => ({
      feeName: feeFound?.name,
      trigger: feeFound?.trigger,
      amount:
        fee?.amount ||
        getFeeFromRules(fee.predefinedFeeEncodedKey, loan?.loanAmount, rules),
    }))
    .filter((fee) => fee.trigger === trigger) || [];

const getFeesByTriggerFromTranches = (
  loan: Loan,
  rules: LoanProductRules,
  trigger: FeeTrigger
): Fees[] => {
  const feesArray = loan?.tranches?.map((tranche) =>
    mapAndRemoveAmountFeesFromFactorLoan(rules, tranche?.fees)
      .map(
        ({ fee, feeFound }) =>
          ({
            feeName: feeFound?.name,
            trigger: feeFound?.trigger,
            amount:
              fee?.amount ||
              getFeeFromRules(
                fee.predefinedFeeEncodedKey,
                tranche.amount,
                rules
              ),
          }) as Fees
      )
      .filter((fee) => fee.trigger === trigger)
  );
  const uniqueFeeNames = Array.from(
    new Set(feesArray.flat(1).map((fee) => fee.feeName))
  );
  return (
    uniqueFeeNames.map((feeName) => {
      return {
        feeName: feeName,
        amount: feesArray
          .flat(1)
          .reduce(
            (acc, curr) => (curr.feeName === feeName ? acc + curr.amount : acc),
            0
          ),
      };
    }) || []
  );
};
