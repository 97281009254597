import React, { memo } from 'react';
import classNames from 'classnames';
import { Option } from 'ui/components/Select';
import { ChevronDownIcon } from '@heroicons/react/outline';

export interface SelectorProps {
  isOpen: boolean;
  selected?: Option;
  noItemSelectedLabel?: string;
  disabled?: boolean;
}

const Selector: React.FC<SelectorProps> = ({
  selected,
  isOpen,
  noItemSelectedLabel,
  disabled = false,
}) => {
  return (
    <div
      className={classNames(
        'flex justify-between px-3 py-2 rounded-inputs cursor-pointer gap-1.5 relative text-secondary-400 border border-neutral-400',
        {
          'bg-neutral-100': isOpen,
          'cursor-not-allowed bg-neutral-200 border-neutral-400': disabled,
        }
      )}
    >
      <div className="flex gap-1.5">
        <p
          className={classNames(
            'body-400 overflow-hidden text-ellipsis whitespace-nowrap text-left',
            {
              'text-neutral-600': !selected,
              'text-black': selected,
            }
          )}
        >
          {selected ? selected?.label : noItemSelectedLabel}
        </p>
      </div>
      <ChevronDownIcon className="w-5 h-5 text-secondary-400" />
    </div>
  );
};

export default memo(Selector);
