import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import type { Cell, HeaderGroup, Row } from 'react-table';
import {
  useExpanded,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import classNames from 'classnames';
import * as Tooltip from '@radix-ui/react-tooltip';
import useDebouncedData from '../../../hooks/useDebouncedData';
import { useIsOverflow } from '../../../hooks/useIsOverflow';
import { AccordionRow } from '../AccordionRow';
import DropDownActions from '../DropDownActions';
import { EditCell, EditableCell } from '../EditableCell';
import Sort from '../Sort';
import { TableContext } from '../TableContext';
import { ExtendableBody } from './ExtendableTableBody';
import styles from './TableBody.module.css';
import { TableProps } from './TableProps';
import type { DropDownItemType } from 'ui/types';

export interface FiltersType {
  accessor: string;
  value: string;
}

export interface DropDownTableItem
  extends Omit<DropDownItemType, 'onClickHandler'> {
  onClickHandler?: (e: any) => void;
  actionRules?: (data: any) => boolean;
}

interface CustomHeader extends HeaderGroup<object> {
  category?: string;
}

export interface CustomObject {
  [key: string]: any;
  Cell: ({ value }: { value: string }) => Element;
}

const baseCellStyles = 'px-2';

const headerCellStyles =
  'text-left heading-200 first:rounded-l-lg last:rounded-r-lg bg-neutral-100 px-2 h-[var(--table-column-header-height)]';

const headerCellEmptyStyles =
  'text-left heading-200 first:rounded-l-lg last:rounded-r-lg px-2 h-[var(--table-column-header-height)]';

function Body<RowData>(props: TableProps<RowData>) {
  const {
    accordionElements,
    accordionFullWidth = false,
    columns,
    data,
    defaultSortBy = undefined,
    dropDownItems = [],
    dropDownProps = {},
    emptyTableMessage = 'There are no elements in the table',
    emptyTableShowColumnHeaders = false,
    expandable = false,
    filters = [],
    hiddenColumns = [],
    hooks = () => {},
    maxHeight = false,
    maxRows,
    noPaddingTop = false,
    rowsAreButtons = false,
    rowHeight = 'md',
    rowRedirection,
    sorting = false,
    verticalDotsBtn = false,
    onChangeSortBy,
    footer = false,
    footerProps = [],
    editableCells = [],
    customItemsFilter,
    showRowSubContent,
    rowSubContent,
    accordionShowAlways = false,
  } = props;

  const hasMaxRows = maxRows != null && maxRows > 0;

  const bodyCellStyles = classNames(
    baseCellStyles,
    'h-[var(--table-row-height)]'
  );

  const {
    registerPagination,
    isLoading,
    hasPagination,
    initialPageSize,
    hasSubheader,
    serverSideSort,
  } = useContext(TableContext) ?? {};

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    rows,
    setPageSize,
    gotoPage,
    setFilter,
    state: { pageIndex, pageSize, expanded, sortBy },
  } = useTable(
    {
      columns,
      data,
      disableSortBy: serverSideSort ? undefined : !sorting,
      manualSortBy: serverSideSort ? true : undefined,
      initialState: {
        pageSize: hasPagination ? initialPageSize : 10000, // TODO: remove after migrating to server side pagination
        ...(defaultSortBy && { sortBy: defaultSortBy }),
        hiddenColumns: [...hiddenColumns],
      },
      autoResetPage: false, //True make never ending rerender
      paginateExpandedRows: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    hooks
  );

  const onChangeSortByRef = useRef<typeof onChangeSortBy>();
  const debouncedPageDelay = filters?.length ? 100 : 0;
  const debouncedPage = useDebouncedData<Row<{}>[]>(page, debouncedPageDelay); // Prevent blinking rows during FE side filter

  useLayoutEffect(() => {
    onChangeSortByRef.current = onChangeSortBy;
  }, [onChangeSortBy]);

  useEffect(() => {
    onChangeSortByRef.current?.(sortBy);
  }, [sortBy]);

  useEffect(() => {
    if (initialPageSize) {
      setPageSize(initialPageSize);
    }
  }, [initialPageSize, setPageSize]);

  // this should be remove once pagination is moved to server side
  useEffect(() => {
    registerPagination?.({
      currentPage: pageIndex,
      pageSize: pageSize as any,
      onChangeCurrentPage: gotoPage,
      onChangePageSize: setPageSize,
      totalItems: rows.length,
    });
  }, [
    registerPagination,
    pageSize,
    pageIndex,
    gotoPage,
    setPageSize,
    rows.length,
  ]);

  useEffect(() => {
    if (!filters.length) return;
    const hasAnyFilterValue = filters.some((x) => !!x.value);
    filters.forEach((f) => {
      setFilter(f.accessor, f.value);
    });
    if (hasAnyFilterValue) gotoPage(0);
  }, [filters, setFilter]);

  const handleRowRedirection = useCallback(
    (e: React.MouseEvent, row: Row) => {
      e.stopPropagation();
      rowRedirection && rowRedirection(row.original as unknown as RowData);
    },
    [rowRedirection]
  );

  const {
    tableHeaderContainerRef,
    tableHeaderRef,
    tableBodyContainerRef,
    stickyScrollbar,
    scrollbarSentinel,
  } = useTableScroll([
    data.length === 0 || debouncedPage.length === 0,
    columns,
    verticalDotsBtn,
    isLoading,
  ]);

  if (isLoading) return null;

  if (data.length === 0 || debouncedPage.length === 0) {
    return (
      <div className={styles.container}>
        <Empty
          limitMaxHeight={maxHeight}
          message={
            data.length === 0
              ? emptyTableMessage
              : `We couldn't find any matches for that search`
          }
          columnHeaders={emptyTableShowColumnHeaders && renderColumnHeaders()}
        />
      </div>
    );
  }

  function getCells(row: Row<object>): Cell<object, any>[] {
    if (props.filterCells) {
      return props.filterCells(row);
    }

    return row.cells;
  }

  function specifyColumnSpan(
    rowDepth: number,
    columnSpan?: number
  ): number | undefined {
    if (!props.specifyColumnSpan) {
      return;
    }

    return props.specifyColumnSpan(rowDepth, columnSpan);
  }

  function renderColumnHeaders(): JSX.Element {
    const groupedColumns = headerGroups.map((headerGroup) => {
      const categoryRow = [];
      const headerRow: React.ReactNode[] = [];
      let currentCategory: string | undefined;
      let categoryColSpan = 0;

      (headerGroup.headers as CustomHeader[]).forEach((column, index) => {
        const category = column.category;

        if (category !== currentCategory) {
          if (currentCategory && categoryColSpan > 0) {
            // Push the previous category span if it's not null and valid
            categoryRow.push(
              <th
                key={`category-${currentCategory}`}
                colSpan={categoryColSpan}
                className={classNames(
                  headerCellStyles,
                  'text-center heading-300  text-neutral-700',
                  'border-l border-neutral-100',
                  'p-[3px]'
                )}
                style={{
                  width: `${categoryColSpan * (column.minWidth || 150)}px`,
                }}
              >
                {currentCategory}
              </th>
            );
          }

          // Reset or start a new category span
          currentCategory = category;
          categoryColSpan = category ? 1 : 0;
        } else if (category) {
          categoryColSpan += 1;
        }

        const { key: columnHeaderKey, ...columnHeaderProps } =
          column.getHeaderProps(
            column.getSortByToggleProps({
              style: {
                minWidth: column.minWidth,
                width: column.width,
                maxWidth: column.maxWidth,
              },
            })
          );

        // Apply the border logic only if the column has a category
        const isCategorized = !!column.category;
        const prevHeader = (headerGroup.headers as CustomHeader[])[index - 1];
        const nextHeader = (headerGroup.headers as CustomHeader[])[index + 1];

        const isFirstInCategory =
          isCategorized &&
          (index === 0 ||
            !prevHeader?.category ||
            prevHeader.category !== column.category);

        const isLastInCategory =
          isCategorized &&
          (index === headerGroup.headers.length - 1 ||
            !nextHeader?.category ||
            nextHeader.category !== column.category);

        headerRow.push(
          <th
            key={columnHeaderKey}
            {...columnHeaderProps}
            className={classNames(
              headerCellStyles,
              {
                'text-right': column.alignRight,
              },
              isFirstInCategory ? 'border-l border-neutral-100' : '',
              isLastInCategory ? 'border-r border-neutral-100' : ''
            )}
          >
            {column.render('Header')}
            {!(data.length === 0 || debouncedPage.length === 0) && (
              <Sort column={column} />
            )}
          </th>
        );
      });

      if (currentCategory && categoryColSpan > 0) {
        categoryRow.push(
          <th
            key={`category-${currentCategory}`}
            colSpan={categoryColSpan}
            className={classNames(
              headerCellStyles,
              'text-center heading-300 text-neutral-700',
              'border-r border-neutral-100'
            )}
            style={{ width: `${categoryColSpan * 199}px` }} // Adjust to match the sum of column widths
          >
            {currentCategory}
          </th>
        );
      }

      // Fill empty space for non-categorized columns
      const nonCategorizedColSpan =
        headerGroup.headers.filter((column: CustomHeader) => !column.category)
          ?.length || 0; // Adjust this to match the number of non-categorized columns
      if (categoryRow.length < headerRow.length) {
        categoryRow.unshift(
          <th
            key={`category-blank`}
            colSpan={nonCategorizedColSpan} // Use a fixed colspan value
            className={classNames(
              headerCellEmptyStyles,
              'border-r border-neutral-100'
            )}
          />
        );
      }

      const { key, ...rest } = headerGroup.getHeaderGroupProps();

      return (
        <Fragment key={headerGroup.id}>
          {!!headerGroup.headers.filter(
            (column: CustomHeader) => !!column.category
          )?.length && (
            <tr className="border-b-2 border-white">{categoryRow}</tr>
          )}
          <tr key={key} {...rest}>
            {headerRow}
            {verticalDotsBtn && (
              <th
                className={classNames(
                  baseCellStyles,
                  headerCellStyles,
                  `w-[1%]`
                )}
              >
                <div className="w-9" />
              </th>
            )}
          </tr>
        </Fragment>
      );
    });

    return (
      <table ref={tableHeaderRef} {...getTableProps()} className="min-w-full">
        <thead>{groupedColumns}</thead>
      </table>
    );
  }

  function drawRow(index: number, row: Row<object>): JSX.Element {
    const { key: rowKey, ...rowProps } = row.getRowProps();

    // Necessary because of 'object' type usage.
    // @refactor remove 'object' types in place of actual generic type R.
    const rowData = row.original as unknown as RowData;

    const shouldRenderRowSubContent =
      showRowSubContent && rowSubContent && showRowSubContent(rowData);

    return (
      <Fragment key={rowKey}>
        <tr
          {...rowProps}
          className={classNames(
            'relative',
            row.depth === 1 ? styles.subrow : '',
            {
              'hover:table-row-shadow transition ease-in duration-300 cursor-pointer select-none':
                rowsAreButtons,
            }
          )}
        >
          {getCells(row).map((cell, i) => {
            const { disableTooltip, alignRight, colSpan } = cell.column;
            const renderedCell = cell.render('Cell');
            const editableCell = editableCells?.filter(
              (editCell: EditableCell) => editCell.name === cell.column?.id
            )?.[0];
            const disabled =
              editableCell?.canEdit && !editableCell.canEdit(cell.row?.index);
            const { key: cellKey, ...cellProps } = cell.getCellProps({
              style: {
                minWidth: cell.column.minWidth,
                width: cell.column.width,
                maxWidth: cell.column.maxWidth,
              },
            });

            // Determine if this is the first or last column in a category and if the column has a category
            const currentCategory = (cell.column as CustomHeader).category;
            const prevCellCategory = (row.cells[i - 1]?.column as CustomHeader)
              ?.category;
            const nextCellCategory = (row.cells[i + 1]?.column as CustomHeader)
              ?.category;

            const isCategorized = !!currentCategory;

            const isFirstInCategory =
              isCategorized &&
              (i === 0 ||
                !prevCellCategory ||
                prevCellCategory !== currentCategory);

            const isLastInCategory =
              isCategorized &&
              (i === row.cells.length - 1 ||
                !nextCellCategory ||
                nextCellCategory !== currentCategory);

            const _cell = (
              <td
                colSpan={specifyColumnSpan(row.depth, colSpan)}
                {...cellProps}
                onClick={(e) => handleRowRedirection(e, row)}
                className={classNames(
                  'body-400 text-neutral-700 whitespace-nowrap text-ellipsis overflow-hidden',
                  {
                    'text-right': alignRight,
                  },
                  bodyCellStyles,
                  isFirstInCategory ? 'border-l border-neutral-300' : '',
                  isLastInCategory ? 'border-r border-neutral-300' : ''
                )}
              >
                {editableCell ? (
                  <EditCell
                    cell={cell}
                    editableCell={editableCell}
                    isLoading={isLoading ?? false}
                    index={i}
                    disabled={disabled ?? false}
                  />
                ) : (
                  renderedCell
                )}
              </td>
            );
            return disableTooltip ? (
              <Fragment key={cellKey}>{_cell}</Fragment>
            ) : (
              <CellTooltip key={cellKey} label={renderedCell}>
                {_cell}
              </CellTooltip>
            );
          })}

          {verticalDotsBtn && (
            <DropDownActions
              row={row}
              dropDownStyle={bodyCellStyles}
              verticalDotsBtn={verticalDotsBtn}
              dropDownItems={dropDownItems}
              dropDownProps={dropDownProps}
              customItemsFilter={customItemsFilter}
            />
          )}
        </tr>
        {shouldRenderRowSubContent && (
          <tr className="border-none">
            <td colSpan={columns.length} className="pb-4 px-2 pt-1">
              {rowSubContent(rowData)}
            </td>
          </tr>
        )}
        {expandable && (
          <AccordionRow
            row={row}
            show={expanded[index] || accordionShowAlways}
            fullWidth={accordionFullWidth}
          >
            {accordionElements}
          </AccordionRow>
        )}
      </Fragment>
    );
  }

  return (
    <div
      className={classNames('w-full', styles.container, {
        'overflow-y-auto scrollbar-thin scrollbar-thumb-neutral-400 scrollbar-track-neutral-200':
          hasMaxRows,
        'h-[calc(var(--table-max-rows)*var(--table-row-height)+var(--table-header-height))]':
          hasMaxRows,
      })}
      data-row-height={rowHeight}
      style={{ '--table-max-rows': maxRows } as React.CSSProperties}
    >
      <div
        ref={tableHeaderContainerRef}
        className={classNames('overflow-x-hidden sticky z-[2] bg-white', {
          'pt-8': !noPaddingTop && !hasSubheader,
          'top-[calc(var(--nav-header-height)+var(--table-header-height))]':
            !hasSubheader && !hasMaxRows,
          'top-[calc(var(--nav-header-height)+var(--table-header-height)+var(--table-subheader-height))]':
            hasSubheader && !hasMaxRows,
          'top-0': hasMaxRows,
        })}
      >
        {renderColumnHeaders()}
      </div>

      <div
        ref={tableBodyContainerRef}
        className="overflow-x-auto overflow-y-hidden"
      >
        <table {...getTableProps()} className="min-w-full">
          <tbody
            {...getTableBodyProps()}
            className="min-w-full divide-y divide-neutral-300"
          >
            {debouncedPage.map((row, i) => {
              prepareRow(row);
              return drawRow(debouncedPage[i]?.index, row);
            })}
          </tbody>
        </table>
        {footer && (
          <div
            className={classNames('overflow-x-hidden sticky z-[2] bg-white')}
          >
            <table {...getTableProps()} className="min-w-full">
              <thead>
                {footerGroups.map((footerGroup, i) => {
                  return (
                    <Fragment key={`footer${i}`}>
                      <tr {...footerGroup.getHeaderGroupProps()}>
                        {footerGroup.headers.map((column, i) => {
                          const {
                            style: customFooterStyle,
                            ...customFooterProps
                          } =
                            footerProps?.find(
                              (props) => props.key === column.id
                            ) ?? {};
                          return (
                            <Fragment key={`footerCell${i}`}>
                              <th
                                {...column.getFooterProps({
                                  style: {
                                    minWidth: column.minWidth,
                                    width: column.width,
                                    maxWidth: column.maxWidth,
                                    ...customFooterStyle,
                                  },
                                  ...customFooterProps,
                                })}
                                className={classNames(headerCellStyles, {
                                  'text-right': column.alignRight,
                                })}
                              >
                                {column.render('Footer')}
                              </th>
                            </Fragment>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })}
              </thead>
            </table>
          </div>
        )}

        {stickyScrollbar}

        {scrollbarSentinel}
      </div>
    </div>
  );
}

type CellTooltipProps = {
  children: React.ReactNode;
  label: React.ReactNode;
};

const CellTooltip = ({ children, label }: CellTooltipProps) => {
  const ref = useRef(null);
  const isOverflow = useIsOverflow(ref);
  return (
    <Tooltip.Root>
      <Tooltip.Trigger ref={ref} asChild>
        {children}
      </Tooltip.Trigger>
      {isOverflow && (
        <Tooltip.Portal>
          <Tooltip.Content
            avoidCollisions={false}
            side="left"
            className="translate-x-full rounded bg-neutral-900 px-3 py-1.5 leading-5 text-sm z-[1] text-white-wrapper"
          >
            {label}
          </Tooltip.Content>
        </Tooltip.Portal>
      )}
    </Tooltip.Root>
  );
};

const STICKY_SCROLL_PADDING = 3;

/**
 * This hook is used to synchronise the horizontal scroll of the table header and body.
 * Without it, you can scroll the table body horizontally, but the header will
 * stay in place, so the columns will no longer align with their column headers.
 */
const useTableScroll = (deps?: unknown[]) => {
  const tableHeaderContainerRef = useRef<HTMLDivElement>(null);
  const tableHeaderRef = useRef<HTMLTableElement>(null);
  const tableBodyContainerRef = useRef<HTMLDivElement>(null);
  const scrollbarContainerRef = useRef<HTMLDivElement>(null);
  const scrollbarPaddingFillRef = useRef<HTMLDivElement>(null);
  const scrollbarRef = useRef<HTMLDivElement>(null);
  const scrollbarSentinelRef = useRef<HTMLDivElement>(null);

  const scrollbarSentinel = <div ref={scrollbarSentinelRef} />;

  const stickyScrollbar = (
    <>
      <div
        ref={scrollbarContainerRef}
        className={`bottom-${STICKY_SCROLL_PADDING} z-[3] fixed overflow-x-auto invisible [&[data-table-visible='true'][data-scrollbar-sentinel-visible='false']]:visible`}
      >
        <div ref={scrollbarRef} className="h-2 bg-white" />
      </div>
      <div
        ref={scrollbarPaddingFillRef}
        className={`fixed bottom-0 invisible h-${STICKY_SCROLL_PADDING}`}
      />
    </>
  );

  useEffect(() => {
    const tableHeaderContainer = tableHeaderContainerRef.current;
    const tableHeader = tableHeaderRef.current;
    const tableBodyContainer = tableBodyContainerRef.current;
    const scrollbarContainer = scrollbarContainerRef.current;
    const scrollbar = scrollbarRef.current;
    const scrollbarSentinel = scrollbarSentinelRef.current;
    const scrollbarPaddingFill = scrollbarPaddingFillRef.current;

    const main = document.querySelector('main');

    if (
      !tableHeaderContainer ||
      !tableHeader ||
      !tableBodyContainer ||
      !scrollbarContainer ||
      !scrollbar ||
      !scrollbarSentinel ||
      !scrollbarPaddingFill ||
      !main
    ) {
      return;
    }

    const syncronizer = createEventsSyncronizer();

    const onScrollBody = syncronizer.withIgnore<Event>(() => {
      tableHeaderContainer.scrollLeft = tableBodyContainer.scrollLeft;
      scrollbarContainer.scrollLeft = tableBodyContainer.scrollLeft;
    });

    const onScrollScrollbar = syncronizer.withIgnore(() => {
      tableHeaderContainer.scrollLeft = scrollbarContainer.scrollLeft;
      tableBodyContainer.scrollLeft = scrollbarContainer.scrollLeft;
    });

    tableBodyContainer?.addEventListener('scroll', onScrollBody);
    scrollbarContainer?.addEventListener('scroll', onScrollScrollbar);

    const mainSizeObserver = new ResizeObserver(() => {
      scrollbarContainer.style.width = `${tableHeaderContainer.clientWidth}px`;
      scrollbar.style.width = `${tableHeader.clientWidth}px`;
      scrollbarSentinel.style.width = `${tableHeader.clientWidth}px`;
      scrollbarPaddingFill.style.width = `${tableHeaderContainer.clientWidth}px`;
    });

    mainSizeObserver.observe(main);

    const tableObserver = new IntersectionObserver(
      ([entry]) => {
        if (!scrollbarContainerRef.current) return;
        scrollbarContainerRef.current.dataset.tableVisible = `${entry.isIntersecting}`;
      },
      { threshold: 0 }
    );

    tableObserver.observe(tableBodyContainer);

    const sentinelObserver = new IntersectionObserver(([entry]) => {
      if (!scrollbarContainerRef.current) return;
      scrollbarContainerRef.current.dataset.scrollbarSentinelVisible = `${entry.isIntersecting}`;
    });

    sentinelObserver.observe(scrollbarSentinel);

    return () => {
      tableBodyContainer?.removeEventListener('scroll', onScrollBody);
      scrollbarContainer?.removeEventListener('scroll', onScrollScrollbar);
      mainSizeObserver.unobserve(main);
      tableObserver.unobserve(tableBodyContainer);
      sentinelObserver.unobserve(scrollbarSentinel);
    };
  }, deps);

  return {
    tableHeaderContainerRef,
    tableHeaderRef,
    tableBodyContainerRef,
    scrollbarSentinel,
    stickyScrollbar,
  };
};

const createEventsSyncronizer = () => {
  let ignoreEvents = false;

  const withIgnore =
    <T,>(fn: (e: T) => unknown) =>
    (e: T) => {
      const ignore = ignoreEvents;
      ignoreEvents = false;
      if (ignore) return;
      ignoreEvents = true;

      requestAnimationFrame(() => fn(e));
    };

  return { withIgnore };
};

type EmptyProps = {
  limitMaxHeight?: boolean;
  message: string;
  columnHeaders?: React.ReactNode;
};

const Empty = ({ limitMaxHeight, message, columnHeaders }: EmptyProps) => {
  return (
    <div
      className={classNames({
        'pb-12': limitMaxHeight,
        'pb-96': !limitMaxHeight && !columnHeaders,
        'pb-80': !limitMaxHeight && columnHeaders,
      })}
    >
      {columnHeaders && <div className="mt-8">{columnHeaders}</div>}
      <p
        className={classNames(
          'heading-500 text-neutral-500 text-center w-full',
          columnHeaders ? 'pt-16' : 'pt-24'
        )}
      >
        {message}
      </p>
    </div>
  );
};

export { Body, Empty, ExtendableBody };
