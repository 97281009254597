import React from 'react';
import { Control, Controller } from 'react-hook-form';
import Select from 'ui/components/Select';
import { InvestmentsFormData } from '../InvestmentsForm.types';
import { INVESTMENT_TYPE_INPUT_OPTIONS } from '../InvestmentsForm.utils';
import { InvestmentType } from 'kennek/interfaces/accounts/investor';

type InvestmentsFormTypeInputProps = {
  index: number;
  control: Control<InvestmentsFormData>;
};

export const InvestmentsFormTypeInput: React.FC<
  InvestmentsFormTypeInputProps
> = (props) => {
  return (
    <Controller
      control={props.control}
      name={`investments.${props.index}.type`}
      defaultValue={InvestmentType.SENIOR}
      render={({ field }) => (
        <Select
          {...field}
          height="40px"
          label="Investment type"
          className="w-[200px]"
          options={INVESTMENT_TYPE_INPUT_OPTIONS}
        />
      )}
    />
  );
};
