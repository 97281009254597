import { ProductComponentVisibility } from '@/hooks/useProductComponentConfiguration';
import { getSingularOrPluralInstalments } from '@/utils/helpers';
import SummaryDetail from './Summary';
import { type LoanSummary } from '@/interfaces/loans/queries';

interface PrincipalCollectionFrequencyDetailsProps {
  loanSummary: LoanSummary;
  loanDetailsConfig?: ProductComponentVisibility;
}
export const PrincipalCollectionFrequencyDetails = ({
  loanSummary,
  loanDetailsConfig,
}: PrincipalCollectionFrequencyDetailsProps) => {
  if (loanSummary ? loanSummary?.principalCollectionFrequency <= 1 : false)
    return;
  return (
    <>
      <SummaryDetail
        title="Principal collection frequency"
        detail={`${
          loanSummary?.principalCollectionFrequency
        } ${getSingularOrPluralInstalments(
          loanSummary?.principalCollectionFrequency
        )}`}
        show={loanDetailsConfig?.showPrincipalCollectionFrequency}
      />
      <SummaryDetail
        title="Payment holiday"
        detail={`${
          loanSummary?.paymentHoliday
        } ${getSingularOrPluralInstalments(loanSummary?.paymentHoliday)}`}
        show={loanDetailsConfig?.showPaymentHoliday}
      />
    </>
  );
};
