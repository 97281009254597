import React, { useEffect, useState } from 'react';
import { SvgIcon } from 'kennek/icons';
import { Select, Title } from 'ui';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import useLoanOptions from '@/hooks/useLoanOptions';
import { AmountToFundType } from '@/interfaces/loans';

interface AmountToFundTypeSelectProps {
  value: AmountToFundType;
  onChange: (value: AmountToFundType) => void;
  isReadOnly?: boolean;
}

export const AmountToFundTypeSelect: React.FC<AmountToFundTypeSelectProps> = ({
  value,
  onChange,
  isReadOnly = false,
}) => {
  const [selectedValue, setSelectedValue] = useState<AmountToFundType>(value);
  const { amountToFundOptions } = useLoanOptions();
  const { loanUpper, loanLower } = useGetLabelsConfig();

  useEffect(() => {
    setSelectedValue(value); // Ensure internal state is updated if props change
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value as AmountToFundType;
    setSelectedValue(newValue);
    onChange(newValue);
  };

  return (
    <div className="select-none">
      <Title
        title="Investor"
        icon={<SvgIcon name="InvestorIcon" />}
        titleSize="lg"
        className="mt-2 mb-3"
      />
      <p>Add any funding source related to this {loanLower}</p>

      <Select
        value={selectedValue}
        label="Select the amount to fund"
        placeholder={`Select Gross or Net ${loanUpper} amount`}
        onChange={handleChange}
        options={amountToFundOptions}
        disabled={isReadOnly}
        className="mt-8 w-72"
      />
    </div>
  );
};

export default AmountToFundTypeSelect;
