import React, { memo, useMemo, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import classNames from 'classnames';
import { Breadcrumbs, Button, Table } from 'ui';
import { DocumentTextIcon, DownloadIcon } from '@heroicons/react/outline';
import { ROUTES } from '@/constants/routes';
import DocumentManagementRowIcon from '@/features/document-management/components/DocumentManagementRowIcon';
import { DMTableRow, useBrowseDocuments } from '@/hooks/useBrowseDocuments';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useHandleDocumentManagementFiles } from '@/hooks/useHandleDocumentManagementFiles';
import { DocumentManagementRowType } from '@/utils/documentManagement';
import { formatDate } from '@/utils/formatters';
import { getRouterQuery } from '@/utils/helpers';
import { CellProps, Column } from 'ui/types';

export interface DocumentationTableProps {
  loanEncodedKey: string;
  selectedLoanId?: string;
  originatorId?: string;
}

const DocumentationTable = ({
  loanEncodedKey,
  selectedLoanId,
  originatorId,
}: DocumentationTableProps) => {
  const searchParams = useSearchParams();
  const labelsConfig = useGetLabelsConfig();
  const loanId = selectedLoanId ? selectedLoanId : searchParams.get('loanId');
  const [currentPath, setCurrentPath] = useState([
    {
      id: 'loan',
      label: `${labelsConfig.loanUpper} ID: ${loanId}`,
      originalName: `${labelsConfig.loanUpper} ID: ${loanId}`,
    },
  ]);

  const router = useRouter();
  const { downloadFile } = useHandleDocumentManagementFiles();
  const { isLoadingDocuments, rows } = useBrowseDocuments(
    currentPath,
    loanEncodedKey,
    labelsConfig,
    originatorId
  );

  const onClickRow = (id: string, name: string) => {
    setCurrentPath((prev) => [
      ...prev,
      { id, label: name, originalName: name },
    ]);
  };

  const handleChangePath = (id: string) => {
    let isClickedPath = false;
    const newPath = currentPath
      .map((pathItem) => {
        if (isClickedPath) return null;
        if (pathItem.id === id) isClickedPath = true;
        return pathItem;
      })
      .filter((item) => item !== null);
    setCurrentPath(newPath);
  };

  const tableColumns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 350,
        maxWidth: 350,
        Cell: ({
          value,
          row,
        }: {
          value: string;
          row: CellProps<DMTableRow>;
        }) => {
          const rowType = row?.original?.rowType as DocumentManagementRowType;
          const isFolder =
            rowType === DocumentManagementRowType.FOLDER ||
            rowType === DocumentManagementRowType.USER_FOLDER;
          return (
            <div
              className={classNames('flex items-center', {
                'cursor-pointer': isFolder,
              })}
              onClick={() => {
                if (isFolder) onClickRow(row?.original?.id, value);
              }}
            >
              <DocumentManagementRowIcon rowType={rowType} />
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Date added',
        accessor: 'dateAdded',
        maxWidth: 100,
        minWidth: 100,
        Cell: ({ value }: { value: string }) => formatDate(value) || '',
      },
      {
        Header: '',
        accessor: 'downloadId',
        maxWidth: 100,
        minWidth: 100,
        Cell: ({
          value,
          row,
        }: {
          value: string;
          row: CellProps<DMTableRow>;
        }) => {
          if (!value) return;
          return (
            <Button
              layout="ghost"
              size="small"
              className="w-full"
              onClick={() => {
                downloadFile(value, {
                  fileName: row?.original?.name,
                });
              }}
            >
              <DownloadIcon className="w-4 h-4 text-secondary-400" />
              <span className="ml-2 text-xs text-secondary-400">Download</span>
            </Button>
          );
        },
      },
    ];
  }, []);

  return (
    <Table.Container variant="compact" isLoading={isLoadingDocuments}>
      <Table.Header
        className={classNames({
          'mb-8': !!rows.length,
        })}
      >
        <Table.Header.Left>
          <Table.Name title="Documentation" leftIcon={<DocumentTextIcon />} />
        </Table.Header.Left>

        <Table.Header.Right>
          <Button
            layout="ghost"
            size="small"
            onClick={() => {
              const path = getRouterQuery(ROUTES.MANAGE_DOCUMENTATION, {
                loanId,
              });
              router.push(path);
            }}
          >
            Manage
          </Button>
        </Table.Header.Right>
        <Table.Header.Bottom>
          <Breadcrumbs
            items={currentPath}
            onClick={(name) => handleChangePath(name)}
            className="mb-6"
          />
        </Table.Header.Bottom>
      </Table.Header>

      <Table.Body
        columns={tableColumns as Column<object>[]}
        noPaddingTop
        maxRows={rows.length >= 5 ? 5 : null}
        data={rows}
        maxHeight
        sorting
        defaultSortBy={[{ id: 'name', desc: true }]}
        emptyTableMessage="No documents available"
      />
    </Table.Container>
  );
};

export default memo(DocumentationTable);
