import { useMemo } from 'react';
import useGetInterestTypeLabel from '@/hooks/useGetInterestTypeLabel';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useLoanDetailsOverwriteLabel } from '@/hooks/useLoanDetailsOverwriteLabel';
import { useGetConfigurationProductQuery } from '@/services/kennek';
import { useSelectUser } from '@/store/user/selectors';
import { Loan } from '@/interfaces/loans';

export const useGetLoanDetailsConfiguration = (loan?: Loan) => {
  const user = useSelectUser();
  const { loanUpper } = useGetLabelsConfig();

  const { data: configurationProductData } = useGetConfigurationProductQuery(
    {
      branchEncodedKey: user?.mambuUser?.[0]?.mambuBranchEncodedKey,
      productEncodedKey: loan?.productTypeKey,
    },
    {
      skip:
        !user?.mambuUser?.[0]?.mambuBranchEncodedKey || !loan?.productTypeKey,
    }
  );
  const overwriteLabel = useLoanDetailsOverwriteLabel();
  const interestType = useGetInterestTypeLabel({
    interestType: loan?.interestSettings?.interestType,
    chargeFrequency: loan?.interestSettings?.chargeFrequency,
  });

  const { loanAmountLabel, deductedInterestLabel, loanAmountTooltip } =
    useMemo(() => {
      const loanAmountLabel = overwriteLabel.getPropertyLabel(
        `${loanUpper} Amount`,
        'LOAN_AMOUNT',
        configurationProductData?.grace
      );

      const loanAmountTooltip = overwriteLabel.getPropertyTooltip(
        `Initial ${loanUpper} Amount`,
        'LOAN_AMOUNT'
      );

      const deductedInterestLabel = overwriteLabel.getPropertyLabel(
        'Deducted Interest',
        'DEDUCTED_INTREST_SETUP',
        configurationProductData?.grace
      );
      return { loanAmountLabel, deductedInterestLabel, loanAmountTooltip };
    }, [configurationProductData?.grace, overwriteLabel, loanUpper]);

  return {
    interestType,
    loanAmountLabel,
    deductedInterestLabel,
    loanAmountTooltip,
    configurationProductData,
  };
};
