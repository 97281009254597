import { endOfMonth, isAfter, isSameDay } from 'date-fns';
import {
  RawTranche,
  TranchesSumErrors,
  getTranchesSum,
} from '@/components/sections/tranches/utils';
import { LOAN_MESSAGES } from '@/constants/onboarding';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { addDays, utc, zonedDate } from '@/utils/datetime';
import { formatDate } from '@/utils/formatters';
import { Currencies } from 'kennek/interfaces/loans';
import { HolidaysResponse, NonWorkingDaysEnum } from '@/interfaces/loans';

export function useTranchesValidator(mambuProductType: string = null) {
  const isRevolvingCredit = mambuProductType === 'REVOLVING_CREDIT';
  const labelsConfig = useGetLabelsConfig();
  const validateTranchesSumToLoanAmount = (
    tranches: RawTranche[],
    loanAmount: number
  ): TranchesSumErrors | true => {
    const tranchesSum = getTranchesSum(tranches);

    if (loanAmount == null) return 'LOAN_AMOUNT_UNDEFINED';

    if (tranchesSum < loanAmount && !isRevolvingCredit) return 'LESS';

    if (tranchesSum > loanAmount) return 'MORE';

    return true;
  };

  const monthlyRepaymentDayIfFirstRepaymentDateIsNotLastDayOfMonth = (
    firstRepaymentDate: string
  ) => firstRepaymentDate && zonedDate(firstRepaymentDate).getDate();

  const isHolidayDate = (
    validatedDate: string,
    holidays: HolidaysResponse
  ): boolean => {
    const DAYS_OF_WEEK: Partial<Record<number, NonWorkingDaysEnum>> = {
      0: NonWorkingDaysEnum.SUNDAY,
      1: NonWorkingDaysEnum.MONDAY,
      2: NonWorkingDaysEnum.TUESDAY,
      3: NonWorkingDaysEnum.WEDNESDAY,
      4: NonWorkingDaysEnum.THURSDAY,
      5: NonWorkingDaysEnum.FRIDAY,
      6: NonWorkingDaysEnum.SATURDAY,
    };
    const formattedDate = zonedDate(validatedDate);

    return (
      (holidays?.nonWorkingDays?.includes(
        DAYS_OF_WEEK[formattedDate.getDay()]
      ) ??
        false) ||
      (holidays?.dates?.filter(
        (date) =>
          date?.date &&
          ((formatDate(zonedDate(date?.date), 'yyyy-MM-dd') ===
            formatDate(formattedDate, 'yyyy-MM-dd') &&
            !date.isAnnuallyRecurring) ||
            (formatDate(zonedDate(date?.date), 'MM-dd') ===
              formatDate(formattedDate, 'MM-dd') &&
              date.isAnnuallyRecurring))
      )?.length ?? 0) > 0
    );
  };

  const isFirstRepaymentDateLastDayOfMonth = (firstRepaymentDate: string) =>
    firstRepaymentDate &&
    isSameDay(
      zonedDate(firstRepaymentDate),
      endOfMonth(zonedDate(firstRepaymentDate))
    );

  const getMonthlyRepaymentDay = (firstRepaymentDate: string) =>
    isFirstRepaymentDateLastDayOfMonth(firstRepaymentDate)
      ? 31
      : firstRepaymentDate &&
        monthlyRepaymentDayIfFirstRepaymentDateIsNotLastDayOfMonth(
          firstRepaymentDate
        );

  const isMonthlyRepaymentDisabled = (firstRepaymentDate: string) =>
    isFirstRepaymentDateLastDayOfMonth(firstRepaymentDate) &&
    monthlyRepaymentDayIfFirstRepaymentDateIsNotLastDayOfMonth(
      firstRepaymentDate
    ) !== 31;

  const isAfterFirstRepaymentDate = (
    firstRepaymentDate: string,
    validatedDate: string
  ) =>
    firstRepaymentDate &&
    isAfter(
      zonedDate(validatedDate),
      addDays(zonedDate(utc(firstRepaymentDate)), -1)
    );

  const validateSingleDisbursmentDate = (
    validatedDate: string,
    previousDate: string,
    holidays: HolidaysResponse,
    firstRepaymentDate: string,
    formFlow: string,
    firstRepaymentDayLabel: string
  ): string | true => {
    if (
      formFlow !== 'REFINANCE' &&
      firstRepaymentDate &&
      !previousDate &&
      isAfterFirstRepaymentDate(firstRepaymentDate, validatedDate)
    ) {
      return LOAN_MESSAGES.DISBURSEMENT_DATE_AFTER_FIRST_REPAYMENT_DATE(
        firstRepaymentDayLabel
      );
    }

    if (isRevolvingCredit && isHolidayDate(validatedDate, holidays))
      return LOAN_MESSAGES.NON_HOLIDAY_DISBURSTMENT_DATE_REVOLVING_CREDIT(
        formatDate(validatedDate, 'dd/MM/yyyy')
      );

    if (!previousDate) return true;

    if (
      !isAfter(zonedDate(validatedDate), zonedDate(previousDate)) &&
      !isSameDay(zonedDate(validatedDate), zonedDate(previousDate))
    )
      return LOAN_MESSAGES.DISBURSTMENT_DATES_MUST_BE_ASC;

    return true;
  };

  const validateTranchesSummaryFields = (
    tranches: RawTranche[],
    loanAmount: number,
    currency: Currencies,
    lastDueDate: string
  ): string | true => {
    // check tranches sum against loanAmount
    switch (validateTranchesSumToLoanAmount(tranches, loanAmount)) {
      case 'LESS':
        return LOAN_MESSAGES.DISBURSTMENT_SUM_LESSER_THAN_LOAN_AMOUNT(
          loanAmount,
          currency,
          labelsConfig
        );
      case 'MORE':
        return isRevolvingCredit
          ? LOAN_MESSAGES.DISBURSTMENT_SUM_GREATER_THAN_LOAN_AMOUNT_REVOLVING_CREDIT(
              loanAmount,
              currency,
              labelsConfig
            )
          : LOAN_MESSAGES.DISBURSTMENT_SUM_GREATER_THAN_LOAN_AMOUNT(
              loanAmount,
              currency,
              labelsConfig
            );
      case 'LOAN_AMOUNT_UNDEFINED':
        return LOAN_MESSAGES.DISBURSTMENT_SUM_LOAN_AMOUNT_UNDEFINED(
          labelsConfig
        );
      case true:
        break;
    }

    if (isRevolvingCredit) {
      type DateGroupedTranchesType = Record<string, RawTranche[]>;
      const groupedTranchesByDates = tranches.reduce<DateGroupedTranchesType>(
        (groups, tranch) => {
          const date = tranch?.disbursementDate?.split('T')[0];
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(tranch);
          return groups;
        },
        {}
      );

      // check lastest disburstment date against last due date
      if (
        (Object.keys(groupedTranchesByDates).filter(
          (disburstmentDate) => lastDueDate && disburstmentDate > lastDueDate
        )?.length ?? 0) > 0
      )
        return LOAN_MESSAGES.LAST_DISBURSTMENT_DATE_BEFORE_LAST_DUE_DATE_REVOLVING_CREDIT;
    }

    return true;
  };

  const getMaxInstallmentErrorMessage = (max: number) => {
    return isRevolvingCredit
      ? LOAN_MESSAGES.MAX_VALUE_REPAYMENT_INSTALLMENTS_REVOLVING_CREDIT(
          max,
          labelsConfig
        )
      : LOAN_MESSAGES.MAX_VALUE_REPAYMENT_INSTALLMENTS(max);
  };

  return {
    getMonthlyRepaymentDay,
    isMonthlyRepaymentDisabled,
    isFirstRepaymentDateLastDayOfMonth,
    validateSingleDisbursmentDate,
    validateTranchesSummaryFields,
    getMaxInstallmentErrorMessage,
  };
}
