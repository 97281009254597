import { useEffect, useMemo, useState } from 'react';
import { Breadcrumbs, Button, DropDown, Input, Table } from 'ui';
import { v4 as uuid } from 'uuid';
import { SearchIcon } from '@heroicons/react/outline';
import { SNACKBAR_DOCUMENT_MANAGEMENT } from '@/constants/snackbar-messages';
import { DMTableRow, useBrowseDocuments } from '@/hooks/useBrowseDocuments';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useHandleDocumentManagementFiles } from '@/hooks/useHandleDocumentManagementFiles';
import { useRole } from '@/hooks/useRole';
import { useSnackbar } from '@/hooks/useSnackbar';
import {
  useUpdateDocumentMutation,
  useUpdateFolderMutation,
} from '@/services/documentManagement';
import { useGetLoanQuery } from '@/services/loans';
import { useSelectUiSettingsIsMainColorSet } from '@/store/user/selectors';
import { PathData } from '@/utils/documentManagement';
import {
  allFileType,
  allFolderType,
  filterDropDownItems,
  getDocumentNameValue,
  getDropDownTableItems,
  getNewPath,
  getTableColumns,
} from './EditDocumentsTable.utils';
import DeleteDocumentDialog from './modals/DeleteDocumentDialog';
import EditFileNameModal from './modals/EditFileNameModal';
import FolderModal, { FolderModalType } from './modals/FolderModal';
import UploadFileDMModal from './modals/UploadFileDMModal';
import { Column, DropDownTableItem } from 'ui/types';

interface EditDocumentsTableProps {
  loanId: string;
  narrow?: boolean;
  readOnly?: boolean;
  noPaddingX?: boolean;
  showBorder?: boolean;
  isEditLoan?: boolean;
}

const EditDocumentsTable = ({
  loanId,
  narrow = false,
  readOnly = false,
  noPaddingX = false,
  showBorder = false,
  isEditLoan = false,
}: EditDocumentsTableProps) => {
  const labelsConfig = useGetLabelsConfig();
  const basePath = {
    id: 'loan',
    label: `${labelsConfig.loanUpper} ID: ${loanId}`,
    originalName: `${labelsConfig.loanUpper} ID: ${loanId}`,
  };
  const { role } = useRole();
  const [search, setSearch] = useState('');
  const [openFolderModal, setOpenFolderModal] = useState(false);
  const [editFileNameModal, setOpenEditFileNameModal] = useState(false);
  const [openUploadFileModal, setOpenUploadFileModal] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isPendingDelete, setIsPendingDelete] = useState(false);
  const [isPendingRestore, setIsPendingRestore] = useState(false);
  const [documentDeleteData, setDocumentDeleteData] =
    useState<DMTableRow>(null);
  const [folderModalType, setFolderModalType] =
    useState<FolderModalType>('new');
  const [documentEditData, setDocumentEditData] = useState<DMTableRow>(null);
  const [currentPath, setCurrentPath] = useState<PathData[]>([basePath]);
  const [dropNewButtonItems, setDropNewButtonItems] =
    useState<DropDownTableItem[]>();
  const [manageAccessFolderName, setManageAccessFolderName] = useState('');

  const isOriginator =
    role === 'ORIGINATOR_ADMIN' || role === 'ORIGINATOR_ANALYST';

  const isMainPrimaryColorSet = useSelectUiSettingsIsMainColorSet();
  const snackbar = useSnackbar();
  const { data: loan, isLoading: isLoadingLoan } = useGetLoanQuery(loanId, {
    skip: !loanId,
  });
  const loanEncodedKey = loan?.encodedKey;
  const originatorId = loan?.assignedBranchKey;
  const {
    isLoadingDocuments,
    rows,
    refetchDocuments,
    debouncesSearchValue,
    immediatelyChangeSearch,
    searchItemsFound,
    currentFolderId,
    folderAccess,
  } = useBrowseDocuments(
    currentPath,
    loanEncodedKey,
    labelsConfig,
    originatorId,
    search,
    isEditLoan
  );

  const isLoading =
    isLoadingDocuments || isLoadingLoan || isPendingDelete || isPendingRestore;
  const { downloadFile } = useHandleDocumentManagementFiles();
  const [updateDocument] = useUpdateDocumentMutation();
  const [updateFolder] = useUpdateFolderMutation();

  const goToPath = (id: string, label: string, originalName: string) => {
    setCurrentPath((prev) => [...prev, { id, label, originalName }]);
  };

  const currentSelectedFolder = useMemo(() => {
    if (currentPath.length <= 1) return null;
    return [...currentPath].pop();
  }, [currentPath]);

  useEffect(() => {
    if (debouncesSearchValue) setCurrentPath([basePath]);
  }, [debouncesSearchValue]);

  const handleChangePath = (id: string) => {
    const newPath = getNewPath(currentPath, id);
    setCurrentPath(newPath);
  };

  const handleMenageAccess = (row: DMTableRow) => {
    setDocumentEditData(row);
    setManageAccessFolderName(row.name);
    setFolderModalType('manageAccess');
    setOpenFolderModal(true);
  };

  const handleRename = (row: DMTableRow) => {
    setDocumentEditData(row);
    if (allFolderType.includes(row.rowType)) {
      setFolderModalType('rename');
      setOpenFolderModal(true);
    }
    if (allFileType.includes(row.rowType)) {
      setOpenEditFileNameModal(true);
    }
  };

  const handleDeleteRowAction = (row: DMTableRow) => {
    setDocumentDeleteData(row);
    setDeleteModalVisible(true);
  };

  const restoreDocument = (row: DMTableRow) => {
    const isFileRow = allFileType.includes(row.rowType);
    isFileRow ? restoreFile(row) : restoreFolder(row);
  };

  const restoreFile = (row: DMTableRow) => {
    setIsPendingRestore(true);
    updateDocument({
      id: row.id,
      body: {
        ...row.documentData,
        isDraftDeleted: false,
      },
    })
      .unwrap()
      .then(() => {
        snackbar.show({
          severity: 'success',
          title: SNACKBAR_DOCUMENT_MANAGEMENT.FILE_RESTORED,
        });
        refetchDocuments();
      })
      .catch(() => {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_DOCUMENT_MANAGEMENT.FILE_RESTORED_FAILED,
        });
      })
      .finally(() => setIsPendingRestore(false));
  };

  const restoreFolder = (row: DMTableRow) => {
    setIsPendingRestore(true);
    updateFolder({
      id: row.id,
      body: {
        ...row.documentData,
        isDraftDeleted: false,
      },
    })
      .unwrap()
      .then(() => {
        snackbar.show({
          severity: 'success',
          title: SNACKBAR_DOCUMENT_MANAGEMENT.FOLDER_RESTORED,
        });
        refetchDocuments();
      })
      .catch(() => {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_DOCUMENT_MANAGEMENT.FOLDER_RESTORED_FAILED,
        });
      })
      .finally(() => setIsPendingRestore(false));
  };

  const handleOpen = (row: DMTableRow) => {
    const path = [
      basePath,
      ...row.pathSplitted.map((x: string) => ({
        id: uuid(),
        label: x,
        originalName: x,
      })),
      {
        id: uuid(),
        label: getDocumentNameValue(isEditLoan, row.name, row.draftName),
        originalName: row.name,
      },
    ];
    clearSearch();
    setCurrentPath(path);
  };

  const clearSearch = () => {
    setSearch('');
    immediatelyChangeSearch('');
  };

  const tableColumns = useMemo(() => {
    return getTableColumns(
      {
        goToPath,
        setCurrentPath,
        clearSearch,
        basePath,
      },
      !!debouncesSearchValue,
      narrow,
      isEditLoan
    );
  }, [debouncesSearchValue]);

  const dropDownTableItems: DropDownTableItem[] = useMemo(() => {
    return getDropDownTableItems(
      {
        handleOpen,
        handleRename,
        handleDeleteRowAction,
        handleMenageAccess,
        restoreDocument,
        downloadFile,
      },
      role,
      readOnly,
      isEditLoan
    );
  }, [currentPath, readOnly, isEditLoan]);

  useEffect(() => {
    if (isLoading) return;

    const items = [];
    if (isOriginator) {
      items.push({
        text: 'Create folder',
        onClickHandler: () => {
          setDocumentEditData(null);
          setFolderModalType('new');
          setOpenFolderModal(true);
        },
      });
    }
    if (currentSelectedFolder && folderAccess === 'readUpload') {
      items.push({
        text: 'Upload file',
        onClickHandler: () => setOpenUploadFileModal(true),
      });
    }
    setDropNewButtonItems(items);
  }, [folderAccess, isOriginator, isLoading]);

  const showNewButton = !!dropNewButtonItems?.length && role !== 'INVESTOR';

  return (
    <>
      <EditFileNameModal
        show={editFileNameModal}
        setShow={setOpenEditFileNameModal}
        documentData={documentEditData}
        folderId={currentFolderId}
        refreshData={refetchDocuments}
        isEditLoan={isEditLoan}
      />
      <FolderModal
        mode={folderModalType}
        folderData={documentEditData}
        show={openFolderModal}
        setShow={setOpenFolderModal}
        loanEncodedKey={loanEncodedKey}
        path={currentPath}
        refreshData={refetchDocuments}
        manageAccessFolderName={manageAccessFolderName}
        isEditLoan={isEditLoan}
      />
      <UploadFileDMModal
        show={openUploadFileModal}
        setShow={setOpenUploadFileModal}
        loanEncodedKey={loanEncodedKey}
        folderName={currentSelectedFolder?.originalName}
        folderId={currentFolderId}
        refreshData={refetchDocuments}
        isEditLoan={isEditLoan}
      />
      <DeleteDocumentDialog
        deleteModalVisible={deleteModalVisible}
        setDeleteModalVisible={setDeleteModalVisible}
        documentDeleteData={documentDeleteData}
        setIsPendingDelete={setIsPendingDelete}
        refreshData={refetchDocuments}
      />
      <Table.Container
        isLoading={isLoading}
        loaderStandardColor={isMainPrimaryColorSet}
        loadingHeight={narrow ? 'h-40' : 'h-72'}
        noPaddingX={noPaddingX}
        border={showBorder ? 'light' : 'none'}
      >
        {!readOnly && (
          <Table.Header className="mb-8">
            <Table.Header.Left>
              <Table.Name title="Documents list" active={true} />
            </Table.Header.Left>

            <Table.Header.Right>
              {!isEditLoan && (
                <label className="relative">
                  <SearchIcon
                    width={16}
                    className="text-neutral-700 absolute top-3 left-3"
                  />

                  <Input
                    id="searchByCompany"
                    name="searchByCompany"
                    type="search"
                    autoComplete="off"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    placeholder="Search"
                    autoFocus={false}
                    className="border-neutral-400 rounded-md body-400 h-10 min-w-[280px] pl-9"
                  />
                </label>
              )}

              {showNewButton && (
                <DropDown
                  anchor="middle"
                  button={
                    <Button className="h-10 min-w-40 whitespace-nowrap">
                      + New
                    </Button>
                  }
                  dropDownItems={dropNewButtonItems}
                  separator={false}
                  size="lg"
                  textClasses="body-400 text-black"
                />
              )}
            </Table.Header.Right>
          </Table.Header>
        )}

        <Table.Header.Bottom>
          {debouncesSearchValue ? (
            <div className="text-neutral-600">
              {searchItemsFound} results found for &quot;{debouncesSearchValue}
              &quot; in this loan
            </div>
          ) : (
            <Breadcrumbs
              items={currentPath}
              onClick={(name) => handleChangePath(name)}
              className="mb-6 flex-wrap"
            />
          )}
        </Table.Header.Bottom>

        <Table.Body<DMTableRow>
          columns={tableColumns as unknown as Column<object>[]}
          hideTableHeader={!!debouncesSearchValue}
          noPaddingTop
          maxRows={rows.length >= 5 ? 5 : null}
          data={rows}
          maxHeight
          sorting
          verticalDotsBtn
          dropDownItems={dropDownTableItems}
          emptyTableMessage="No documents available"
          defaultSortBy={[{ id: 'name', desc: true }]}
          customItemsFilter={filterDropDownItems}
        />
      </Table.Container>
    </>
  );
};

export default EditDocumentsTable;
