import { Fragment, useEffect, useState } from 'react';
import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { SvgIcon } from 'kennek/icons';
import { Alert, Button, Dialog, FormInput, ToggleSwitch, Watch } from 'ui';
import { ABSOLUTE_MIN_DATE } from '@/constants/numeric';
import { LOAN_MESSAGES } from '@/constants/onboarding';
import { CounterpartyForm } from '@/features/counterparty/CounterpartyForm';
import { CounterpartySelectInput } from '@/features/counterparty/CounterpartySelectInput';
import { Tranches } from '@/features/onboarding';
import { useSnackbar } from '@/hooks/useSnackbar';
import {
  useCreateCounterpartyMutation,
  useGetOriginatorCounterpartiesQuery,
} from '@/services/loans/counterparty';
import { checkDateIsInThePast } from '@/utils/dates';
import { zonedDate } from '@/utils/datetime';
import { formatAmount, formatDate } from '@/utils/formatters';
import { getValueOrDefault, toFloatOrDefault } from '@/utils/helpers';
import {
  EditTranchesFacilitiesForm,
  EditTranchesFacilitiesOptions,
} from './editTranchesFacilities';
import { useTranchesValidator } from './useTranchesValidator';
import {
  RawTranche,
  editTrancheDisabledInputEdit,
  editTrancheDisabledTrancheRemoval,
  editTrancheGetMaxDisbursementDate,
  editTrancheGetMinDisbursementDate,
  flattenAndSumFacilities,
  getTranchesSumValues,
} from './utils';
import {
  CreateCounterpartyDto,
  Loan,
  LoanProductRules,
  ProductTypesNames,
} from '@/interfaces/loans';
import { LoanFacility, RowLoanFacility } from '@/interfaces/loans/facility';

interface Props {
  firstRepaymentDayLabel: string;
  fields: UseFieldArrayReturn<
    {
      tranches: RawTranche[];
    },
    'tranches',
    'id'
  >;
  form: UseFormReturn<{ tranches: RawTranche[] }>;
  mambuProductType: string;
  loanProductRules: LoanProductRules;
  emptyTranche: RawTranche;
  firstRepaymentDate: string;
  loanAmount: number;
  variant: 'onboarding' | 'tranches';
  facilitiesOptions: RowLoanFacility[] | LoanFacility[];
  formFlow?: 'ONBOARDING' | 'REFINANCE';
  originalLoan?: Loan;
}

const EditTranchesContent = ({
  firstRepaymentDayLabel,
  fields,
  form,
  mambuProductType,
  loanProductRules,
  emptyTranche,
  firstRepaymentDate,
  loanAmount,
  variant,
  facilitiesOptions,
  formFlow,
  originalLoan,
}: Props) => {
  const changeType =
    originalLoan?.productType !== loanProductRules?.productType;
  const tranchesValidator = useTranchesValidator(mambuProductType);
  //  facilities begin
  const [facilityOptions, setFacilityOptions] = useState<
    EditTranchesFacilitiesOptions[]
  >([]);

  const showCapFee =
    mambuProductType !== ProductTypesNames.REVOLVING_CREDIT ||
    variant === 'onboarding';

  const tranchesArray = useWatch({
    control: form.control,
    name: 'tranches',
    defaultValue: [] as RawTranche[],
  });

  useEffect(() => {
    const flattenSumFacilities = flattenAndSumFacilities(
      form.getValues()?.tranches
    );
    setFacilityOptions(
      facilitiesOptions?.map((f) => ({
        value: f.facilityId,
        facilityId: f.facilityId,
        name: f.name,
        label: f.name,
        initial: parseFloat(f.amount),
        amount: (
          parseFloat(f.amount) -
          (flattenSumFacilities.find((fac) => fac.facilityId === f.facilityId)
            ?.amount || 0)
        ).toString(),
      })) || []
    );
  }, [tranchesArray, facilitiesOptions]);

  const [initialStateValues, setInitialStateValues] = useState<RawTranche[]>(
    []
  );

  const getInitialStateValues = (index: number) => {
    return initialStateValues[index]?.facilities || [];
  };

  useEffect(() => {
    if (!form?.getValues()?.tranches?.length) {
      return;
    }
    setInitialStateValues(
      form.getValues().tranches.map((t) => ({
        ...t,
        facilities: t?.facilities ? Object.values(t.facilities) : [],
      }))
    );
  }, []);
  // facilities end
  const [tranchesDisbursementAmounts, setTranchesDisbursementAmounts] =
    useState(
      fields.fields.map((field) => parseFloat(field.disbursementAmount) || 0)
    );

  const elementWidth = variant === 'tranches' ? 'w-[195px]' : 'w-[165px]';
  const disbursementAmountLeftMargin = variant === 'tranches' ? 'ml-1' : '';

  const snackbar = useSnackbar();

  // ? COUNTERPARTIES HANDLING START
  const [counterpartyDialogOpen, setCounterpartyDialogOpen] = useState(false);

  const [addNewCounterpartyTrancheIndex, setAddNewCounterpartyTrancheIndex] =
    useState<number | null>(null);

  const getCounterpartiesQuery = useGetOriginatorCounterpartiesQuery(
    {},
    { skip: !loanProductRules?.disbursementBankAccountDetails }
  );

  const [createCounterparty, createCounterpartyMutationState] =
    useCreateCounterpartyMutation();

  const createCounterpartySubmitHandler = async (
    data: CreateCounterpartyDto
  ) => {
    try {
      const newCounterparty = await createCounterparty(data).unwrap();
      form.setValue(
        `tranches.${addNewCounterpartyTrancheIndex}.counterpartyId`,
        newCounterparty.id
      );
      snackbar.show({
        severity: 'success',
        title: 'Counterparty created successfully.',
      });
      setCounterpartyDialogOpen(false);
      getCounterpartiesQuery.refetch();
    } catch (err) {
      snackbar.show({
        severity: 'error',
        title: 'Failed to create counterparty.',
      });
    }
  };
  // ! COUNTERPARTIES HANLDING END

  return (
    <>
      {loanProductRules?.disbursementBankAccountDetails && (
        <Dialog
          open={counterpartyDialogOpen}
          onClose={() => setCounterpartyDialogOpen(false)}
        >
          <CounterpartyForm
            isLoading={createCounterpartyMutationState.isLoading}
            onCancel={() => setCounterpartyDialogOpen(false)}
            onSubmit={(data) => createCounterpartySubmitHandler(data)}
          />
        </Dialog>
      )}
      <Tranches.TranchesContainer>
        {fields.fields.map((field, index) => (
          <Tranches.TrancheContainer key={field.id}>
            <Tranches.TrancheLabel>Tranche {index + 1}</Tranches.TrancheLabel>

            <Tranches.TrancheContent>
              <FormInput
                label="Disbursement date"
                placeholder="Pick a date"
                type="date"
                containerClassName={elementWidth}
                disabled={editTrancheDisabledInputEdit(
                  changeType,
                  index,
                  formFlow,
                  field.disbursed
                )}
                className="w-full"
                {...form.register(`tranches.${index}.disbursementDate`, {
                  value: field.disbursementDate,
                  required: 'Select disbursement date',
                  min: !(index === 0 && formFlow === 'REFINANCE') && {
                    value: ABSOLUTE_MIN_DATE,
                    message: LOAN_MESSAGES.MIN_DATE_ABSOLUTE(
                      formatDate(ABSOLUTE_MIN_DATE, 'dd-MM-yyyy')
                    ),
                  },
                  validate: (current) => {
                    if (
                      editTrancheDisabledInputEdit(
                        changeType,
                        index,
                        formFlow,
                        field.disbursed
                      )
                    )
                      return null;

                    const prev = form.getValues(
                      `tranches.${index - 1}.disbursementDate`
                    );

                    return tranchesValidator.validateSingleDisbursmentDate(
                      current,
                      prev,
                      loanProductRules?.holidays,
                      firstRepaymentDate,
                      formFlow,
                      firstRepaymentDayLabel
                    );
                  },
                })}
                withSpacing={
                  !!form?.formState?.errors?.tranches?.[index]?.disbursementDate
                }
                dynamicHeightErrorBanner={
                  !!form?.formState?.errors?.tranches?.[index]?.disbursementDate
                }
                errors={
                  form?.formState?.errors?.tranches?.[index]?.disbursementDate
                    ?.message
                }
                max={editTrancheGetMaxDisbursementDate(
                  index,
                  firstRepaymentDate
                )}
                min={editTrancheGetMinDisbursementDate(
                  index,
                  form.watch(`tranches.${index - 1}.disbursementDate`)
                )}
              />
              <Controller
                control={form.control}
                name={`tranches.${index}.disbursementAmount`}
                render={({ field: amountField, fieldState: { error } }) => (
                  <FormInput
                    currencyInput={loanProductRules?.currency}
                    label="Disbursement amount"
                    errors={error?.message}
                    type="number"
                    containerClassName={`${elementWidth} ${disbursementAmountLeftMargin}`}
                    disabled={editTrancheDisabledInputEdit(
                      changeType,
                      index,
                      formFlow,
                      field.disbursed
                    )}
                    ref={amountField.ref}
                    name={amountField.name}
                    value={amountField.value}
                    onValueChange={(value) => {
                      setTranchesDisbursementAmounts((prevAmounts) => {
                        const newAmounts = [...prevAmounts];
                        newAmounts[index] = parseFloat(value) || 0;
                        return newAmounts;
                      });

                      amountField.onChange(getValueOrDefault(value, ''));
                    }}
                    onBlur={amountField.onBlur}
                    withSpacing={!!error}
                    dynamicHeightErrorBanner={!!error}
                    autoComplete="off"
                  />
                )}
                rules={{
                  required: 'Disbursement amount cannot be empty',
                  validate: {
                    min: (value) =>
                      parseFloat(value) > 0
                        ? true
                        : `Disbursement amount cannot be less than ${formatAmount(
                            0,
                            false,
                            loanProductRules?.currency
                          )}`,
                  },
                }}
              />
              {(index === 0 ||
                checkDateIsInThePast(
                  zonedDate(form.watch(`tranches.${index}.disbursementDate`))
                )) &&
                showCapFee &&
                loanProductRules?.fees?.map(
                  ({
                    id,
                    calculationMethod,
                    name,
                    percentageAmount,
                    amount,
                    required,
                  }) => (
                    <Fragment key={id}>
                      <Tranches.TrancheWithSideElement>
                        {calculationMethod === 'PERCENTAGE' && (
                          <Watch
                            control={form.control}
                            name={`tranches.${index}.disbursementAmount`}
                          >
                            {(rawDisbursementAmount) => (
                              <FormInput
                                currencyInput={loanProductRules?.currency}
                                label={name}
                                value={(() => {
                                  const disbursementAmount = toFloatOrDefault(
                                    rawDisbursementAmount
                                  );
                                  if (
                                    !disbursementAmount ||
                                    !form.watch(
                                      `tranches.${index}.fees.${id}.enabled`
                                    )
                                  )
                                    return '';
                                  return (
                                    disbursementAmount *
                                      (percentageAmount / 100) || 0
                                  );
                                })()}
                                disabled
                                type="number"
                                bottomLabel={`${
                                  percentageAmount || 0
                                }% of disbursement amount`}
                              />
                            )}
                          </Watch>
                        )}

                        {calculationMethod === 'FLAT' && (
                          <Controller
                            control={form.control}
                            name={`tranches.${index}.fees.${id}.amount`}
                            render={({ field, fieldState }) => (
                              <FormInput
                                currencyInput={loanProductRules?.currency}
                                label={name}
                                errors={fieldState?.error?.message}
                                type="number"
                                name={field.name}
                                disabled={
                                  !form.watch(
                                    `tranches.${index}.fees.${id}.enabled`
                                  ) || !!amount
                                }
                                value={(() => {
                                  if (variant === 'tranches')
                                    return field.value || amount;
                                  return form.watch(
                                    `tranches.${index}.fees.${id}.enabled`
                                  )
                                    ? field.value || amount
                                    : '';
                                })()}
                                onValueChange={(value) =>
                                  field.onChange(getValueOrDefault(value, ''))
                                }
                                onBlur={field.onBlur}
                                autoComplete="off"
                                withSpacing={!!fieldState?.error}
                                dynamicHeightErrorBanner={!!fieldState?.error}
                              />
                            )}
                            rules={{
                              required: {
                                value:
                                  amount > 0
                                    ? false
                                    : form.watch(
                                        `tranches.${index}.fees.${id}.enabled`
                                      ),
                                message: `${name} cannot be empty`,
                              },
                              validate: {
                                min: (value) =>
                                  parseFloat(value) <= 0 &&
                                  form.watch(
                                    `tranches.${index}.fees.${id}.enabled`
                                  )
                                    ? `${name} cannot be less than ${formatAmount(
                                        0,
                                        false,
                                        loanProductRules?.currency
                                      )}`
                                    : true,
                              },
                            }}
                          />
                        )}

                        {!field.disbursed &&
                          !required &&
                          ((index !== 0 &&
                            checkDateIsInThePast(
                              zonedDate(
                                form.watch(`tranches.${index}.disbursementDate`)
                              )
                            )) ||
                            index === 0) && (
                            <Tranches.TrancheSideElement>
                              <Controller
                                control={form.control}
                                name={`tranches.${index}.fees.${id}.enabled`}
                                render={({ field }) => {
                                  return (
                                    <ToggleSwitch
                                      size="sm"
                                      enabled={field.value}
                                      onChange={(value) => {
                                        form.clearErrors(
                                          `tranches.${index}.fees.${id}.amount`
                                        );
                                        field.onChange(value);
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Tranches.TrancheSideElement>
                          )}
                      </Tranches.TrancheWithSideElement>
                    </Fragment>
                  )
                )}
              {loanProductRules?.disbursementBankAccountDetails && (
                <Controller
                  control={form.control}
                  name={`tranches.${index}.counterpartyId`}
                  rules={{
                    validate: (value) =>
                      !!value || 'Counterparty account cannot be empty.',
                  }}
                  render={({
                    field: counterpartyField,
                    fieldState: { error },
                  }) => {
                    return (
                      <CounterpartySelectInput
                        disabled={
                          getCounterpartiesQuery.isFetching || field.disbursed
                        }
                        counterparties={getValueOrDefault(
                          getCounterpartiesQuery.currentData,
                          []
                        )}
                        onValueChange={(value) => {
                          form.clearErrors(`tranches.${index}.counterpartyId`);
                          counterpartyField.onChange(value);
                        }}
                        value={form.watch(`tranches.${index}.counterpartyId`)}
                        error={error?.message}
                        onAddNewClick={() => {
                          setCounterpartyDialogOpen(true);
                          setAddNewCounterpartyTrancheIndex(index);
                        }}
                      />
                    );
                  }}
                />
              )}

              {!!facilityOptions.length && (
                <EditTranchesFacilitiesForm
                  form={form}
                  availableAmount={tranchesDisbursementAmounts[index]}
                  facilitiesOptions={facilityOptions}
                  formKey={`tranches.${index}.facilities`}
                  currency={loanProductRules?.currency}
                  initialStateValues={getInitialStateValues(index)}
                  disabled={field.disbursed}
                  elementWidth={elementWidth}
                />
              )}
            </Tranches.TrancheContent>

            {editTrancheDisabledTrancheRemoval(
              index,
              fields.fields,
              field.disbursed
            ) && (
              <Tranches.TrancheDeleteButton>
                <Button
                  icon={<SvgIcon name="DeleteIcon" />}
                  onClick={() => fields.remove(index)}
                  layout="link"
                  aria-label="Remove tranche"
                />
              </Tranches.TrancheDeleteButton>
            )}
          </Tranches.TrancheContainer>
        ))}

        <Tranches.AddTrancheContainer>
          <Button
            className="text-[#227AB0] !body-400 !p-0"
            onClick={() => fields.append(emptyTranche)}
            disabled={
              fields.fields.length >= loanProductRules?.maxNumberOfTranches
            }
            layout="link"
          >
            + Add another tranche
          </Button>
        </Tranches.AddTrancheContainer>

        <Tranches.TranchesSummaryContainer>
          <div>
            <p className="text-neutral-600 body-100">Max. tranche amount</p>
            <p className="text-neutral-800 body-200">
              {loanProductRules?.maxNumberOfTranches}
            </p>
          </div>

          <div>
            <p className="text-neutral-600 body-100">Disbursed amount</p>
            <p className="text-neutral-800 body-200 whitespace-nowrap">
              <Watch
                control={form.control}
                name="tranches"
                defaultValue={
                  form.formState?.defaultValues?.tranches as RawTranche[]
                }
              >
                {(tranches) =>
                  getTranchesSumValues(tranches, loanProductRules, loanAmount)
                }
              </Watch>
            </p>
          </div>
        </Tranches.TranchesSummaryContainer>

        {form.formState.errors?.tranches?.root?.message && (
          <div className="w-full">
            <Alert type="error" className="w-full">
              <span className="body-400 mt-1">
                {form.formState.errors?.tranches?.root?.message}
              </span>
            </Alert>
          </div>
        )}
      </Tranches.TranchesContainer>
    </>
  );
};

export default EditTranchesContent;
