import { useEffect, useMemo, useRef, useState } from 'react';
import { useSession } from 'next-auth/react';
import { useRouter, useSearchParams } from 'next/navigation';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { Container, Path } from 'ui';
import { defineStepper, useStepperControl } from 'ui/components/Stepper';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { DEFAULT_CURRENCY } from '@/constants/currency';
import { ROUTES } from '@/constants/routes';
import { env } from '@/env/public';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useRole } from '@/hooks/useRole';
import { useGetUserByEmailQuery } from '@/services/accounts';
import { useGetOnboardingTaskStatusesQuery } from '@/services/accounts/borrower';
import {
  useGetConfigurationProductQuery,
  useGetDisabledFeaturesQuery,
} from '@/services/kennek';
import {
  useGetLoanProductRulesQuery,
  useGetOnboardingLoansQuery,
} from '@/services/loans';
import { useAppDispatch } from '@/store/hooks';
import { useSelectUser } from '@/store/user/selectors';
import { getRouterQuery } from '@/utils/helpers';
import { findLastIndex } from '@/utils/list';
import { CompanyInformationContainer as CompanyInformation } from './CompanyInformationContainer';
import { LoanReporting } from './LoanReporting';
import { StepperSurface } from './StepperSurface';
import UploadDocumentationForm from './UploadDocumentationForm';
import UserInformationForm from './UserInformationForm';
import { CustomInformation } from './custom-information/custom-information';
import { Investments } from './investments/InvestmentsContainer';
import {
  AdditionalDetailsContainer as AdditionalDetails,
  LoanInformationContainer as LoanInformation,
  Preview,
  ScheduleContainer as Schedule,
} from './loan-details';
import LoanAssets from './loanAssets/LoanAssets';
import {
  onBorrowerClientCreated,
  onContinueWithExistingCompany,
  onEnterOnboarding,
  onExitOnboarding,
  useSelectBorrowerCompanyExternalId,
  useSelectOnboardingFlow,
  useSelectOriginatorExternalId,
} from './onboardingSlice';
import OnboardingSummary from './summary/OnboardingSummary';
import { OptionalFeatures } from 'kennek/interfaces/kennek';
import type { TaskStatus } from '@/interfaces/loans';
import { GetLoans } from '@/interfaces/loans/queries';

export type OnboardingSteps =
  | 'COMPANY'
  | 'USER'
  | `LOAN_DETAILS.${
      | 'LOAN_INFORMATION'
      | 'SCHEDULE'
      | 'ADDITIONAL_DETAILS'
      | 'FACILITIES'
      | 'SECURITIES'
      | 'PREVIEW'}`
  | 'LOAN_REPORTING'
  | 'UPLOAD_DOCUMENTATION'
  | 'INVESTOR'
  | 'CUSTOM_INFORMATION'
  | 'SUMMARY';

export type BackendSteps =
  | 'COMPANY'
  | 'USER'
  | 'LOAN_DETAILS'
  | 'LOAN_REPORTING'
  | 'FACILITIES'
  | 'SECURITIES'
  | 'UPLOAD_DOCUMENTATION'
  | 'INVESTOR'
  | 'CUSTOM_INFORMATION'
  | 'SUMMARY';

const Stepper = defineStepper<OnboardingSteps>();

const Onboarding = () => {
  const [productKey, setProductKey] = useState<string>();
  const [areAssetsInSummary, setAreAssetsInSummary] = useState<boolean>(false);
  const [summaryPathTitle, setSummaryPathTitle] = useState('');
  // initial state on false cause flickering
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();
  const loanId = searchParams.get('loanId');
  const executionId = searchParams.get('executionId') ?? '';
  const borrowerEmailQuery = searchParams.get('borrowerEmail');
  const { loanUpper, borrowerUpper, borrowerLower } = useGetLabelsConfig();

  const onboardingFlow = useSelectOnboardingFlow();
  const borrowerCompanyExternalId = useSelectBorrowerCompanyExternalId();
  const originatorExternalId = useSelectOriginatorExternalId();
  const stepper = useStepperControl<OnboardingSteps>({
    initialStep: 'COMPANY',
  });

  const isSummary = stepper.activeStepId === 'SUMMARY';
  const pathTitle = isSummary
    ? summaryPathTitle
    : `Create new ${borrowerLower}`;

  const isReview = stepper.isVisited('SUMMARY');
  const [showCompanyInitValuesForm, setShowCompanyInitValuesForm] =
    useState(false);

  const router = useRouter();
  const { isOriginator } = useRole();

  const [borrowerEmail, setBorrowerEmail] =
    useState<string>(borrowerEmailQuery);

  const onChangeLoanId = (loanId: string) => {
    const path = getRouterQuery(ROUTES.ONBOARDING_NEW, { loanId, executionId });
    router.replace(path);
  };

  const { data: borrowerUser, ...borrowerUserQuery } = useGetUserByEmailQuery(
    { email: borrowerEmail },
    { skip: !borrowerEmail }
  );

  const lastCompanyId = borrowerUser?.borrowerCompanyIds?.at(-1);

  const isNew = useRef(!loanId);

  const { data: session } = useSession();

  // TODO this request needs to handle errors. Right now, it just keeps showing
  // a spinner forever
  const { data: { data: [onboardingLoan] = [] } = {}, ...onboardingLoanQuery } =
    useGetOnboardingLoansQuery(
      {
        originatorEmail: session?.user?.email,
        orderBy: 'CREATION_DATE',
        orderCriteria: 'DESC',
        // when loading page with only `loanId` as query param in URL, `borrowerEmail` will be undefined
        // so, query onboarding loans and filter by `loanId` in frontend (until filter is added in backend)
        // then, in useEffect below set `borrowerEmail` as query param in URL
        // next time, `borrowerEmail` will be defined
        // filtering by `borrowerEmail` is faster because is done in server side
        // in case of having both `loanId` and `borrowerEmail`, prefer `borrowerEmail` for performance
      },
      {
        skip: !loanId,
        refetchOnMountOrArgChange: true,
        // simulates filtering in frontend
        selectFromResult: (res) => {
          if (!loanId) return res;
          return {
            ...res,
            currentData: getLoanIdData({ loanData: res.currentData, loanId }),
            data: getLoanIdData({ loanData: res.data, loanId }),
          };
        },
      }
    );
  const isBorrowerLoading =
    borrowerUserQuery.isUninitialized || borrowerUserQuery.isLoading;

  const { data: onboardingTasks = [], ...onboardingTasksQuery } =
    useGetOnboardingTaskStatusesQuery(
      {
        loanId: loanId ?? undefined,
        borrowerEmail: borrowerEmail ?? undefined,
      },
      {
        skip: (!loanId && !borrowerEmail) || isBorrowerLoading,
        refetchOnMountOrArgChange: true,
      }
    );

  const productEncodedKey = productKey || onboardingLoan?.loanProductEncodedKey;
  const { data: rules } = useGetLoanProductRulesQuery(
    {
      id: productEncodedKey,
    },
    { skip: !productEncodedKey }
  );
  const user = useSelectUser();

  const disabledFeaturesQuery = useGetDisabledFeaturesQuery(
    { branchEncodedKey: user?.mambuUser?.[0]?.mambuBranchEncodedKey },
    { skip: !user?.mambuUser?.[0]?.mambuBranchEncodedKey }
  );

  const showCustomInformation = !disabledFeaturesQuery?.currentData?.includes(
    OptionalFeatures.LOAN_ONBOARDING_CUSTOM_INFORMATION
  );

  useEffect(() => {
    if (
      onboardingLoan &&
      !onboardingLoan.initialLoan &&
      !borrowerCompanyExternalId
    ) {
      dispatch(
        onContinueWithExistingCompany({
          borrowerCompanyExternalId: onboardingLoan.accountHolderKey,
          originatorExternalId: onboardingLoan.assignedBranchKey,
        })
      );
    }
    if (onboardingLoan && onboardingLoan.initialLoan) {
      setBorrowerEmail(onboardingLoan.borrowerEmail);
      dispatch(
        onBorrowerClientCreated({
          borrowerCompanyExternalId: onboardingLoan.accountHolderKey,
          originatorExternalId: onboardingLoan.assignedBranchKey,
        })
      );
    }
    if (!onboardingLoan && borrowerEmail && borrowerUser) {
      setBorrowerEmail(borrowerEmail);
      dispatch(
        onBorrowerClientCreated({
          borrowerCompanyExternalId:
            borrowerUser.mambuUser?.at(0)?.mambuClientEncodedKey,
          originatorExternalId:
            borrowerUser.mambuUser?.at(0)?.mambuBranchEncodedKey,
        })
      );
    }
  }, [onboardingLoan, dispatch, setBorrowerEmail, borrowerEmail, borrowerUser]);

  const onContinueStep = () => {
    stepper.markCurrentAsCompleted();

    if (
      stepper.isVisited('SUMMARY') &&
      !(
        stepper.activeStepId === 'LOAN_DETAILS.LOAN_INFORMATION' ||
        stepper.activeStepId === 'LOAN_DETAILS.SCHEDULE' ||
        stepper.activeStepId === 'LOAN_DETAILS.SECURITIES' ||
        stepper.activeStepId === 'LOAN_DETAILS.ADDITIONAL_DETAILS' ||
        stepper.activeStepId === 'CUSTOM_INFORMATION'
      )
    ) {
      stepper.setActiveStep('SUMMARY');
    } else {
      stepper.onNext();
    }
  };

  const onBackStep = () => {
    if (
      stepper.isVisited('SUMMARY') &&
      !(
        stepper.activeStepId === 'LOAN_DETAILS.SCHEDULE' ||
        stepper.activeStepId === 'LOAN_DETAILS.SECURITIES' ||
        stepper.activeStepId === 'LOAN_DETAILS.ADDITIONAL_DETAILS' ||
        stepper.activeStepId === 'LOAN_DETAILS.PREVIEW' ||
        stepper.activeStepId === 'CUSTOM_INFORMATION'
      )
    ) {
      stepper.setActiveStep('SUMMARY');
    } else {
      stepper.onPrevious();
    }
  };

  const hasUpdatedStatus = useRef(false);

  const { setActiveStep, markAsCompleted } = stepper;

  const showAssets =
    areAssetsInSummary ||
    (!env.NEXT_PUBLIC_HIDE_ONBOARDING_ASSET_STEP && rules?.assetType);
  // after retrieving steps status from backend, update frontend stepper state, only once

  useEffect(() => {
    if (!onboardingTasksQuery.isSuccess || isLoading) return;
    if (hasUpdatedStatus.current) return;

    const steps = onboardingTasks.reduce((acc, prev) => {
      return { ...acc, [prev.name]: prev.status };
    }, INITIAL_STEP_STATUS);

    const completed: [OnboardingSteps, boolean][] = [
      // using list to guarantee insertion order
      [
        'COMPANY',
        steps.COMPANY === 'COMPLETED' ||
          !!borrowerCompanyExternalId ||
          steps.LOAN_DETAILS === 'COMPLETED',
      ],
      [
        'USER',
        steps.USER === 'COMPLETED' ||
          !!borrowerCompanyExternalId ||
          steps.LOAN_DETAILS === 'COMPLETED',
      ],
      ['LOAN_DETAILS.LOAN_INFORMATION', steps.LOAN_DETAILS === 'COMPLETED'],
      ['LOAN_DETAILS.SCHEDULE', steps.LOAN_DETAILS === 'COMPLETED'],
      ['LOAN_DETAILS.ADDITIONAL_DETAILS', steps.LOAN_DETAILS === 'COMPLETED'],
      ['LOAN_DETAILS.SECURITIES', steps.SECURITIES === 'COMPLETED'],
      [
        'LOAN_DETAILS.PREVIEW',
        steps.LOAN_DETAILS === 'COMPLETED' &&
          (showAssets ? steps.SECURITIES === 'COMPLETED' : true),
      ],
      ['LOAN_REPORTING', steps.LOAN_REPORTING === 'COMPLETED'],
      ['UPLOAD_DOCUMENTATION', steps.UPLOAD_DOCUMENTATION === 'COMPLETED'],
      ['INVESTOR', steps.INVESTOR === 'COMPLETED'],
      [
        'CUSTOM_INFORMATION',
        showCustomInformation ? steps.CUSTOM_INFORMATION === 'COMPLETED' : true,
      ],
      ['SUMMARY', false],
    ];

    const completedFiltered = completed.filter((step) => {
      if (step[0] === 'LOAN_DETAILS.SECURITIES') return showAssets;
      if (step[0] === 'CUSTOM_INFORMATION') return showCustomInformation;
      return true;
    });

    const nextIndex =
      findLastIndex(completedFiltered, ([_, isCompleted]) => isCompleted) + 1;
    const nextStep = completedFiltered[nextIndex]?.[0];

    if (nextStep) {
      setActiveStep(nextStep);
    } else {
      // first step
      setActiveStep('COMPANY');
    }

    completedFiltered
      .filter(([_, isCompleted]) => isCompleted)
      .forEach(([stepId]) => {
        markAsCompleted({ stepId });
      });

    hasUpdatedStatus.current = true;
  }, [
    setActiveStep,
    onboardingTasksQuery?.isFetching,
    onboardingTasksQuery?.isSuccess,
    onboardingTasks,
    markAsCompleted,
    isLoading,
    showAssets,
    borrowerCompanyExternalId,
  ]);

  // prefetch data to avoid loading inside steps

  const setLoader = debounce((val: boolean) => {
    setIsLoading(val);
  }, 1000);

  useEffect(() => {
    if (isNew.current) {
      setLoader(false);
      return;
    }

    const isLoading =
      onboardingLoanQuery.isLoading ||
      onboardingTasksQuery.isLoading ||
      borrowerUserQuery.isLoading;

    setLoader(isLoading);
  }, [
    isNew,
    onboardingLoanQuery.isLoading,
    onboardingTasksQuery.isLoading,
    borrowerEmail,
    borrowerUserQuery.isLoading,
  ]);

  const { data: configurationProductData } = useGetConfigurationProductQuery(
    {
      branchEncodedKey: user?.mambuUser?.[0]?.mambuBranchEncodedKey,
      productEncodedKey: onboardingLoan?.loanProductEncodedKey,
    },
    {
      skip:
        !user?.mambuUser?.[0]?.mambuBranchEncodedKey ||
        !onboardingLoan?.loanProductEncodedKey,
    }
  );

  const productType = useMemo(() => {
    return configurationProductData?.payment?.blockPaymentTillLoanMatured
      ? 'blockedUntilMatured'
      : 'default';
  }, [configurationProductData?.payment?.blockPaymentTillLoanMatured]);

  useEffect(() => {
    dispatch(onEnterOnboarding());
    return () => {
      dispatch(onExitOnboarding());
    };
  }, [dispatch]);

  const handleBackArrow = () => {
    router.push(ROUTES.SERVICING_LOANS);
  };

  const content = (
    <Stepper.Root control={stepper}>
      <Stepper.Navigation
        hidden={
          stepper.isVisited('SUMMARY') &&
          (stepper.activeStepId === 'COMPANY' ||
            stepper.activeStepId === 'USER' ||
            stepper.activeStepId === 'LOAN_REPORTING' ||
            stepper.activeStepId === 'UPLOAD_DOCUMENTATION' ||
            stepper.activeStepId === 'INVESTOR' ||
            stepper.activeStepId === 'CUSTOM_INFORMATION' ||
            stepper.activeStepId === 'SUMMARY')
        }
      >
        <Stepper.Step
          id="COMPANY"
          title="Company"
          description={borrowerUpper}
          hidden={isReview}
        />

        {onboardingFlow === 'FirstLoan' && (
          <Stepper.Step
            id="USER"
            title="User"
            description={`${borrowerUpper} information to access Kennek platform`}
            hidden={isReview}
          />
        )}

        <Stepper.Step
          id="LOAN_DETAILS"
          title={`${loanUpper} Details`}
          showPosition={isReview}
        >
          <Stepper.Substep
            id="LOAN_DETAILS.LOAN_INFORMATION"
            title={`${loanUpper} information`}
          />

          <Stepper.Substep id="LOAN_DETAILS.SCHEDULE" title="Schedule" />

          <Stepper.Substep
            id="LOAN_DETAILS.ADDITIONAL_DETAILS"
            title="Additional details"
          />

          {showAssets && (
            <Stepper.Substep
              id="LOAN_DETAILS.SECURITIES"
              title="Asset information"
            />
          )}
          <Stepper.Substep id="LOAN_DETAILS.PREVIEW" title="Preview" />
        </Stepper.Step>

        <Stepper.Step
          id="LOAN_REPORTING"
          title={`${loanUpper} Reporting`}
          description="Configure Reports"
          hidden={isReview}
        />

        <Stepper.Step
          id="UPLOAD_DOCUMENTATION"
          title="Documentation"
          description={`${loanUpper} documentation`}
          hidden={isReview}
        />

        <Stepper.Step id="INVESTOR" title="Investor" hidden={isReview} />

        {showCustomInformation && isOriginator && (
          <Stepper.Step
            id="CUSTOM_INFORMATION"
            title="Additional information"
            hidden={isReview}
          />
        )}

        <Stepper.Step id="SUMMARY" title="Summary" hidden={isReview} />
      </Stepper.Navigation>

      <Stepper.Content id="COMPANY">
        <CompanyInformation
          borrowerEmail={borrowerEmail}
          loanId={loanId}
          companyNumber={onboardingLoan?.companyNumber}
          borrowerCompanyExternalId={borrowerCompanyExternalId}
          isReview={isReview}
          onBack={onBackStep}
          onContinue={onContinueStep}
          showInitialValuesForm={showCompanyInitValuesForm}
        />
      </Stepper.Content>

      <Stepper.Content id="USER">
        <UserInformationForm
          borrowerEmail={borrowerEmail}
          isReview={isReview}
          loanId={loanId}
          onChangeBorrowerEmail={setBorrowerEmail}
          onBack={onBackStep}
          onContinue={onContinueStep}
          onCompanyValidationError={() => setShowCompanyInitValuesForm(true)}
        />
      </Stepper.Content>

      <Stepper.Content id="LOAN_DETAILS.LOAN_INFORMATION">
        <LoanInformation
          borrowerCompanyExternalId={borrowerCompanyExternalId}
          borrowerEmail={borrowerEmail}
          loanId={loanId}
          setProductKey={setProductKey}
          isReview={isReview}
          onBack={onBackStep}
          onContinue={onContinueStep}
        />
      </Stepper.Content>

      <Stepper.Content id="LOAN_DETAILS.SCHEDULE">
        <Schedule
          loanId={loanId}
          isReview={isReview}
          onBack={onBackStep}
          onContinue={onContinueStep}
        />
      </Stepper.Content>
      <Stepper.Content id="LOAN_DETAILS.ADDITIONAL_DETAILS">
        <AdditionalDetails
          borrowerCompanyExternalId={borrowerCompanyExternalId}
          originatorExternalId={originatorExternalId}
          loanId={loanId}
          borrowerEmail={borrowerEmail}
          onChangeLoanId={onChangeLoanId}
          onContinue={onContinueStep}
          onBack={onBackStep}
          executionId={executionId}
        />
      </Stepper.Content>

      {showAssets && (
        <Stepper.Content id="LOAN_DETAILS.SECURITIES">
          <LoanAssets
            loanId={onboardingLoan?.encodedKey}
            borrowerCompanyId={lastCompanyId}
            isReview={isReview}
            onContinue={onContinueStep}
            onBack={onBackStep}
            loanCurrency={rules?.currency ?? DEFAULT_CURRENCY}
          />
        </Stepper.Content>
      )}
      <Stepper.Content id="LOAN_DETAILS.PREVIEW">
        <Preview
          loanId={loanId}
          companyName={onboardingLoan?.companyName}
          isReview={isReview}
          onContinue={onContinueStep}
          onBack={onBackStep}
          setActiveStep={(step) => stepper.setActiveStep(step)}
        />
      </Stepper.Content>

      <Stepper.Content id="LOAN_REPORTING">
        <LoanReporting
          borrowerCompanyExternalId={borrowerCompanyExternalId}
          originatorExternalId={originatorExternalId}
          loanId={loanId}
          isReview={isReview}
          onContinue={onContinueStep}
          onBack={onBackStep}
        />
      </Stepper.Content>

      <Stepper.Content id="UPLOAD_DOCUMENTATION">
        <UploadDocumentationForm
          loanId={loanId}
          isReview={isReview}
          onContinue={onContinueStep}
          onBack={onBackStep}
        />
      </Stepper.Content>

      <Stepper.Content id="INVESTOR">
        <Investments
          loanId={loanId}
          isReview={isReview}
          onContinue={onContinueStep}
          onBack={onBackStep}
        />
      </Stepper.Content>

      {showCustomInformation && isOriginator && (
        <Stepper.Content id="CUSTOM_INFORMATION">
          <CustomInformation
            loanId={loanId}
            onContinue={onContinueStep}
            onBack={onBackStep}
            branchEncodedKey={user?.mambuUser?.[0]?.mambuBranchEncodedKey}
          />
        </Stepper.Content>
      )}

      <Stepper.Content id="SUMMARY">
        <OnboardingSummary
          companyNumber={onboardingLoan?.companyNumber}
          onboardingLoanIsFetching={onboardingLoanQuery?.isFetching}
          originatorExternalId={originatorExternalId}
          borrowerEmail={borrowerEmail}
          loanId={loanId}
          setActiveStep={(step) => stepper.setActiveStep(step)}
          productType={productType}
          setAreAssetsInSummary={setAreAssetsInSummary}
          setSummaryPathTitle={setSummaryPathTitle}
          showCustomInformation={showCustomInformation}
        />
      </Stepper.Content>
    </Stepper.Root>
  );

  return (
    <Container page>
      {/* Cannot return <LoadingAnimationPlat />, because that would make Stepper not register internal structure */}
      {isLoading && <LoadingAnimationPlat />}

      <div
        className={`${classNames({
          hidden: isLoading,
        })}  min-w-[1152px] w-[1152px]`}
      >
        <div className="flex justify-between">
          {pathTitle && (
            <Path title={pathTitle} onArrowBackClick={handleBackArrow} />
          )}
        </div>

        {isSummary ? (
          content
        ) : (
          <StepperSurface>
            <div className="flex gap-x-7 [&>:last-child]:min-w-0 [&>:last-child]:w-full">
              {content}
            </div>
          </StepperSurface>
        )}
      </div>
    </Container>
  );
};

const INITIAL_STEP_STATUS: Record<BackendSteps, TaskStatus> = {
  COMPANY: 'PENDING',
  USER: 'PENDING',
  LOAN_DETAILS: 'PENDING',
  LOAN_REPORTING: 'PENDING',
  UPLOAD_DOCUMENTATION: 'PENDING',
  FACILITIES: 'PENDING',
  SECURITIES: 'PENDING',
  INVESTOR: 'PENDING',
  CUSTOM_INFORMATION: 'PENDING',
  SUMMARY: 'PENDING',
};

// simulates filtering in frontend
const getLoanIdData = ({
  loanId,
  loanData,
}: {
  loanId: string;
  loanData: GetLoans;
}): GetLoans => {
  const loan = loanData?.data?.find((e) => e.id === loanId);
  return {
    ...loanData,
    total: loan ? 1 : 0,
    data: loan ? [loan] : [],
  };
};

export { Onboarding };
