import React, { useMemo, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { SvgIcon } from 'kennek/icons';
import { Button, Surface, Title } from 'ui';
import { ROUTES } from '@/constants/routes';
import { env } from '@/env/public';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { ProductComponentVisibility } from '@/hooks/useProductComponentConfiguration';
import { useRole } from '@/hooks/useRole';
import { getRouterQuery } from '@/utils/helpers';
import { DirectDebitEditLoanAlert } from '../DirectDebitEditLoanAlert';
import { isLoanSummaryListTypeChecker } from './LoanDetailWidget.utils';
import LoanDetail from './components/LoanDetail';
import LoanDetailGroup from './components/LoanDetailGroup';
import { UserTypes } from 'kennek/interfaces/accounts';
import { Loan } from '@/interfaces/loans';
import {
  DirectDebitMandateStatuses,
  type LoanSummary,
} from '@/interfaces/loans/queries';

export interface Props extends React.ComponentProps<React.FC> {
  loanSummary: LoanSummary | LoanSummary[];
  loanDetailsConfig?: ProductComponentVisibility;
  groupLoanValue?: number;
  loan?: Loan;
  productType?: string;
  isEditLoanEnabled?: boolean;
}

export const LoanDetailWidget: React.FC<Props> = ({
  loanSummary,
  loanDetailsConfig,
  loan,
  productType,
  groupLoanValue,
  isEditLoanEnabled,
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const loanId = searchParams.get('loanId');
  const { role } = useRole();
  const { loanUpper } = useGetLabelsConfig();

  const permissions = ROLES_PERMISSIONS[role];
  const hasTranches = loan?.tranches ? loan?.tranches?.length > 0 : false;

  const isLoanSummaryList = isLoanSummaryListTypeChecker(loanSummary);

  const canEdit = useMemo(() => {
    if (role === 'BORROWER' || isLoanSummaryList) return;

    const hasEditPermission = permissions?.includes('EDIT');
    const isEditEnabledInEnv = env.NEXT_PUBLIC_PERMISSIONS_DETAILS_EDIT;
    const isEditDisabledWithTranches =
      env.NEXT_PUBLIC_PERMISSIONS_DISABLE_EDIT_WITH_TRANCHES && hasTranches;
    const isLoanStateValid =
      !isLoanSummaryList &&
      loanSummary?.state !== 'REPAID' &&
      loanSummary?.state !== 'RESTRUCTURED';
    const isLoanSubStateValid =
      loan?.subState !== 'REFINANCED' && loan?.subState !== 'RESCHEDULED';
    const isInterestAccrualDisabled =
      !loanSummary?.productConfiguration?.otherConfigurations
        ?.interestAccrualAtDisbursement;
    const isEditNotLocked = !loanSummary?.locks?.edit;

    return (
      hasEditPermission &&
      isEditEnabledInEnv &&
      !isEditDisabledWithTranches &&
      isLoanStateValid &&
      isLoanSubStateValid &&
      isInterestAccrualDisabled &&
      isEditNotLocked &&
      isEditLoanEnabled
    );
  }, [
    permissions,
    hasTranches,
    isLoanSummaryList,
    loanSummary,
    loan?.subState,
    role,
    isEditLoanEnabled,
  ]);

  const [modal, setModal] = useState<'directDebitEditLoanAlert' | false>(false);

  const onClickEdit = () => {
    if (!canEdit) {
      return;
    }
    if (
      !isLoanSummaryList &&
      loanSummary?.directDebitMandateStatus ===
        DirectDebitMandateStatuses.ACTIVE
    ) {
      setModal('directDebitEditLoanAlert');
      return;
    }
    onEdit();
  };

  const onEdit = () => {
    if (!loanId) return;
    const path = getRouterQuery(ROUTES.LOAN_EDIT, { loanId });
    router.push(path);
  };

  return (
    <>
      <Surface padding="sm" border="light">
        <div className="w-full bg-white flex gap-4">
          <div className="flex-grow flex flex-col">
            <div className="flex justify-between">
              <Title
                icon={<SvgIcon name="StatementIcon" />}
                title={`${loanUpper} details`}
                insideCard
              />

              {canEdit && (
                <div className="-mt-1">
                  <Button
                    layout="ghost"
                    size="small"
                    onClick={() => {
                      onClickEdit();
                    }}
                  >
                    Edit
                  </Button>
                </div>
              )}
            </div>
            {isLoanSummaryList ? (
              <>
                {loanSummary.map((summary, i) => (
                  <LoanDetailGroup
                    key={summary.id}
                    loanSummary={summary}
                    divider={loanSummary.length !== i + 1}
                    groupLoanValue={groupLoanValue}
                  />
                ))}
              </>
            ) : (
              <>
                {loan && (
                  <LoanDetail
                    loan={loan}
                    loanDetailsConfig={loanDetailsConfig}
                    loanSummary={loanSummary}
                    productType={productType}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Surface>

      <DirectDebitEditLoanAlert
        open={modal === 'directDebitEditLoanAlert'}
        onClose={() => setModal(false)}
        onContinue={() => {
          onEdit();
        }}
      />
    </>
  );
};

const PERMISSIONS = ['EDIT'] as const;

const ROLES_PERMISSIONS: Partial<
  Record<UserTypes, (typeof PERMISSIONS)[number][]>
> = {
  ORIGINATOR_ADMIN: ['EDIT'],
  ORIGINATOR_ANALYST: ['EDIT'],
};
