import useGetLabelsConfig from './useGetLabelsConfig';
import { UserTypes } from 'kennek/interfaces/accounts';

export type PropertyType =
  | 'DEDUCTED_INTREST'
  | 'FIRST_REPAYMENT_DATE'
  | 'LOAN_AMOUNT'
  | 'DEDUCTED_INTREST_SETUP';
export type SupportedFlow = 'ONBOARDING' | 'REFINANCE' | 'RESCHEDULE';
export interface OverwritedLabels {
  property: PropertyType;
  supportedFlows?: SupportedFlow[];
  supportedUsers?: UserTypes[];
  labelValue: string;
  tooltipValue?: string;
}

export interface OverwritedLabelStates extends OverwritedLabels {
  interestGraceEnabled?: boolean;
  principalGraceEnabled?: boolean;
}

export function useLoanDetailsOverwriteLabel() {
  const { loanUpper, loanLower } = useGetLabelsConfig();

  const labelsToOverwrite: Record<PropertyType, string> = {
    ['DEDUCTED_INTREST']: 'Retain interest',
    ['FIRST_REPAYMENT_DATE']: 'First interest payment',
    ['LOAN_AMOUNT']: `${loanUpper} amount`,
    ['DEDUCTED_INTREST_SETUP']: 'Interest retained',
  };

  const tooltipsToOverwrite: Record<string, string> = {
    ['LOAN_AMOUNT']: `Amount from which the fees will be added or deducted according to fee treatment.\\nIf fees are capitalised, then ${loanLower} amount means "Net ${loanLower} amount".\\nIf fees are deducted then ${loanLower} amount means "Gross ${loanLower} amount"`,
  };

  const loanPropertyOverwriteStates: OverwritedLabelStates[] = [
    {
      property: 'DEDUCTED_INTREST',
      interestGraceEnabled: true,
      labelValue: labelsToOverwrite['DEDUCTED_INTREST'],
    },
    {
      property: 'FIRST_REPAYMENT_DATE',
      principalGraceEnabled: true,
      labelValue: labelsToOverwrite['FIRST_REPAYMENT_DATE'],
    },
    {
      property: 'LOAN_AMOUNT',
      labelValue: labelsToOverwrite['LOAN_AMOUNT'],
      tooltipValue: tooltipsToOverwrite['LOAN_AMOUNT'],
    },
    {
      property: 'DEDUCTED_INTREST_SETUP',
      interestGraceEnabled: true,
      labelValue: labelsToOverwrite['DEDUCTED_INTREST_SETUP'],
    },
  ];

  const getPropertyLabel = (
    baseLabel: string,
    propertyType: PropertyType,
    grace: {
      interestGrace: boolean;
      principalGracePeriod: boolean;
    }
  ) => {
    const overwritenLabel = loanPropertyOverwriteStates.find(
      (state) =>
        isGraceConditionValid(state, grace) && state.property === propertyType
    )?.labelValue;

    return !overwritenLabel ? baseLabel : overwritenLabel;
  };

  const getPropertyTooltip = (
    baseTooltip: string,
    propertyType: PropertyType
  ) => {
    const overwritenTooltip = loanPropertyOverwriteStates.find(
      (state) => state.property === propertyType
    )?.tooltipValue;

    return !overwritenTooltip ? baseTooltip : overwritenTooltip;
  };

  const isGraceConditionValid = (
    selectedState: OverwritedLabelStates,
    grace: {
      interestGrace: boolean;
      principalGracePeriod: boolean;
    }
  ) => {
    const isGraceDisabled =
      !selectedState.interestGraceEnabled &&
      !selectedState.principalGraceEnabled;
    const isAnyGraceValiad =
      !!grace &&
      (!selectedState.interestGraceEnabled ||
        selectedState.interestGraceEnabled === grace?.interestGrace) &&
      (!selectedState.principalGraceEnabled ||
        selectedState.principalGraceEnabled === grace?.principalGracePeriod);

    return isGraceDisabled || isAnyGraceValiad;
  };

  return { getPropertyLabel, getPropertyTooltip } as const;
}
