import AccruedInterest from './AccruedInterest';
import ContextWrapper from './ContextWrapper';
import DirectDebitStateText from './DirectDebitStateText';
import MakePaymentModal from './MakePayemntModal';
import NextInstallment from './NextInstallment';
import NextInstallmentRow from './NextInstallmentRow';
import NextInstallments from './NextInstallments';
import PaymentButton from './PaymentButton';
import ProcessingPaymentBanner from './ProcessingPaymentBanner';

const NextPayment = {
  ContextWrapper,
  DirectDebitStateText,
  MakePaymentModal,
  NextInstallmentRow,
  NextInstallments,
  PaymentButton,
  ProcessingPaymentBanner,
  NextInstallment,
  AccruedInterest,
};

export { NextPayment };
