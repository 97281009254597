const myTheme = {
  alert: {
    base: 'space-x-2 rounded-md leading-0 text-black',
    type: {
      warning: 'bg-warning-200 border border-warning-300 dark:bg-warning-300',
      error:
        'bg-error-200 border border-error-300 dark:bg-error-700 dark:text-white',
      success:
        'bg-success-200 border border-success-300 dark:bg-success-700 dark:text-white',
      info: 'bg-alert-info-100 border border-alert-info-300',
      neutral:
        'bg-neutral-200 border border-neutral-300 dark:bg-neutral-700 dark:text-neutral-300',
      primary:
        'bg-primary-200 border border-primary-300 dark:bg-primary-600 dark:text-white',
      secondary:
        'bg-secondary-200 border border-secondary-300 dark:bg-secondary-600 dark:text-white',
    },
    size: {
      sm: 'p-2 leading-5 text-sm',
      md: 'p-4 leading-5 text-base',
      lg: 'p-6 leading-5 text-lg',
    },
    icon: {
      base: 'h-5 w-5',
      warning: 'text-warning-500 dark:text-warning-900',
      error: 'text-error-500 dark:text-error-300',
      success: 'text-success-700 dark:text-success-300',
      info: 'text-secondary-500 dark:text-info-300',
      neutral: 'text-neutral-700 dark:text-neutral-300',
      primary: 'text-secondary-500 dark:text-primary-300',
      secondary: 'text-secondary-700 dark:text-secondary-300',
    },
    title: {
      base: 'heading-200',
    },
    message: {
      base: 'body-400',
    },
    inlineAction: {
      base: 'hover:underline px-1',
    },
    close: {
      base: 'h-4 w-4 transition-all',
      warning:
        'text-neutral-700 hover:text-warning-700 dark:text-warning-300 dark:hover:text-warning-100',
      error:
        'text-neutral-700 hover:text-error-700 dark:text-error-300 dark:hover:text-error-100',
      success:
        'text-neutral-700 hover:text-success-700 dark:text-success-300 dark:hover:text-success-100',
      info: 'text-neutral-700 hover:text-info-700 dark:text-info-300 dark:hover:text-info-100',
      neutral:
        'text-neutral-700 hover:text-neutral-700 dark:text-neutral-300 dark:hover:text-neutral-100',
      primary:
        'text-primary-700 hover:text-primary-700 dark:text-primary-300 dark:hover:text-primary-100',
      seconday:
        'text-secondary-700 hover:text-secondary-700 dark:text-secondary-300 dark:hover:text-secondary-100',
    },
  },

  // Avatar
  avatar: {
    base: 'relative rounded-full inline-block',
    size: {
      large: 'w-16 h-16',
      regular: 'w-8 h-8',
      small: 'w-6 h-6',
    },
  },

  // Badge
  badge: {
    base: 'inline-flex px-2 py-1 heading-100 rounded',
    neutral:
      'text-neutral-700 bg-neutral-200 dark:text-neutral-100 dark:bg-neutral-700',
    primary:
      'text-primary-700 bg-primary-200 dark:text-white dark:bg-primary-600',
    secondary:
      'text-secondary-700 bg-primary-200 dark:text-white dark:bg-primary-600',
    info: 'text-info-700 bg-primary-200 dark:text-white dark:bg-primary-600',
    success:
      'text-success-700 bg-success-200 dark:bg-success-700 dark:text-success-100',
    warning:
      'text-warning-700 bg-warning-200 dark:text-white dark:bg-warning-500',
    error: 'text-error-700 bg-error-200 dark:text-error-100 dark:bg-error-700',
  },

  // Button
  button: {
    base: 'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-semibold focus:outline-none select-none',
    block: 'w-full',
    size: {
      larger: 'px-10 py-4 rounded',
      large: 'px-5 py-3 rounded',
      regular: 'px-4 py-2 rounded text-sm',
      small: 'px-3 py-1 rounded text-sm',
      limitedPaddingX: 'p-2 rounded text-sm',
      icon: {
        larger: 'p-4 rounded-lg',
        large: 'p-3 rounded-lg',
        regular: 'p-2 rounded-lg',
        small: 'p-2 rounded-md',
      },
      pagination: 'px-3 py-1 rounded-md text-xs',
    },

    // styles applied to the SVG icon
    icon: {
      larger: 'h- 6 w-6',
      large: 'h-6 w-6',
      regular: 'h-5 w-5',
      small: 'h-4 w-4',
      left: 'mr-2 -ml-1',
      right: 'ml-2 -mr-1',
    },
    primary: {
      base: 'text-secondary-500 border border-transparent bg-primary-500 dark:bg-primary-700 dark:text-white rounded-full',
      active:
        'active:bg-primary-800 hover:bg-primary-600 focus:ring-2 focus:ring-secondary-500 border-white',
      disabled: 'opacity-50 cursor-not-allowed',
    },
    secondary: {
      base: 'text-white bg-secondary-500 border border-transparent rounded-full',
      active:
        'active:bg-secondary-800 hover:bg-secondary-600 focus:ring focus:ring-secondary-300',
      disabled: 'opacity-50 cursor-not-allowed',
    },
    outline: {
      base: 'text-secondary-500 border border-primary-500 bg-transparent border dark:text-neutral-400 focus:outline-none rounded-full',
      active:
        'active:bg-primary-100 active:border-primary-300 hover:bg-primary-100 hover:border-primary-500 focus:border-primary-500 focus:ring focus:ring-primary-300',
      disabled: 'opacity-50 cursor-not-allowed',
    },
    ghost: {
      base: 'text-secondary-400 dark:text-primary-400 focus:outline-none border border-transparent rounded-full',
      active:
        'active:bg-primary-200 active:text-primary-700 hover:bg-primary-100 focus:ring focus:ring-primary-300 dark:hover:bg-primary-500 dark:hover:text-primary-300 dark:hover:bg-opacity-10',
      disabled: 'opacity-50 cursor-not-allowed',
    },
    link: {
      base: 'text-secondary-400 heading-200',
      active: 'active:text-secondary-700',
      disabled: 'opacity-50 cursor-not-allowed',
    },
    destructive: {
      base: 'text-white bg-error-500 border border-transparent rounded-full',
      active:
        'active:bg-error-800 hover:bg-error-700 focus:ring focus:ring-primary-300',
      disabled: 'opacity-50 cursor-not-allowed',
    },
    dark: {
      base: 'bg-black text-white focus:outline-none rounded-full',
      active: 'bg-black text-white',
      disabled: 'opacity-50 cursor-not-allowed',
    },
    transparent: {
      base: 'text-black focus:outline-none border border-transparent rounded-full',
      active:
        'text-black focus:outline-none border border-transparent rounded-full hover:bg-neutral-200',
      disabled: 'opacity-50 cursor-not-allowed',
    },

    // this is the button that lives inside the DropdownItem
    dropdownItem: {
      base: 'inline-flex items-center cursor-pointer w-full px-2 py-1 text-sm font-medium transition-colors duration-150 rounded-md hover:bg-neutral-100 hover:text-neutral-800 dark:hover:bg-neutral-800 dark:hover:text-neutral-200',
    },
  },

  // Container
  container: {
    base: 'w-container h-full w-full',
    page: 'flex flex-col gap-3',
  },

  // Heading
  heading: {
    base: 'font-bold',
    type: {
      h1: 'text-8xl ',
      h2: 'text-6xl ',
      h3: 'text-5xl ',
      h4: 'text-4xl ',
      h5: 'text-2xl ',
      h6: 'text-lg ',
    },
  },

  // HelperText
  helperText: {
    base: 'text-xs',
    valid: 'text-success-500 dark:text-success-300',
    invalid: 'text-error-500 dark:text-error-300',
  },

  // Text
  text: {
    base: '',
    type: {
      p: 'mb-4 ',
      strong: 'font-bold ',
      em: 'font-italic ',
      div: ' ',
      span: ' ',
    },
  },

  // Pagination
  pagination: {
    base: 'flex flex-col justify-between text-xs sm:flex-row text-neutral-600 dark:text-neutral-400',
  },

  // TableFooter
  tableFooter: {
    base: 'px-4 py-3 border-t dark:border-neutral-700 bg-neutral-50 text-neutral-500 dark:text-neutral-400 dark:bg-neutral-800',
  },

  // TableRow
  tableRow: {
    base: '',
  },

  // TableHeader
  tableHeader: {
    base: 'text-xs font-semibold tracking-wide text-left text-neutral-500 uppercase border-b dark:border-neutral-700 bg-neutral-50 dark:text-neutral-400 dark:bg-neutral-800',
  },

  // TableContainer
  tableContainer: {
    base: 'w-full overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5',
  },

  // TableCell
  tableCell: {
    base: 'px-4 py-3',
  },

  // TableBody
  tableBody: {
    base: 'bg-white divide-y dark:divide-neutral-700 dark:bg-neutral-800 text-neutral-700 dark:text-neutral-400',
  },

  // DropdownItem

  // this is the <li> that lives inside the Dropdown <ul>

  // you're probably looking for the dropdownItem style inside button
  dropdownItem: {
    base: 'mb-2 last:mb-0',
  },

  // Dropdown
  dropdown: {
    base: 'absolute w-56 p-2 mt-2 text-neutral-600 bg-white border border-neutral-100 rounded-lg shadow-md min-w-max-content dark:text-neutral-300 dark:border-neutral-700 dark:bg-neutral-700',
    align: {
      left: 'left-0',
      right: 'right-0',
    },
  },

  // Modal
  modal: {
    base: 'w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-neutral-800 sm:rounded-lg sm:m-4 sm:max-w-xl',
  },

  // ModalBody
  modalBody: {
    base: 'mb-6 text-sm text-neutral-700 dark:text-neutral-400',
  },

  // ModalFooter
  modalFooter: {
    base: 'flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-neutral-50 dark:bg-neutral-800',
  },

  // ModalHeader
  modalHeader: {
    base: 'mt-4 mb-2 text-lg font-semibold text-neutral-700 dark:text-neutral-300',
  },

  // Textarea
  textarea: {
    base: 'block w-full text-sm focus:outline-none dark:text-neutral-300 leading-5 rounded-2sm ',
    active:
      'focus:border-primary-100 border-neutral-300 dark:border-neutral-600 focus:ring focus:ring-primary-100 dark:focus:border-neutral-600 dark:focus:ring-neutral-300 dark:bg-neutral-700',
    disabled:
      'cursor-not-allowed opacity-50 bg-neutral-300 dark:bg-neutral-800',
    valid:
      'border-success-500 dark:bg-neutral-700 focus:border-success-300 dark:focus:border-success-300 focus:ring focus:ring-success-200 dark:focus:ring-success-200',
    invalid:
      'border-error-500 dark:bg-neutral-700 focus:border-error-300 dark:focus:border-error-300 focus:ring focus:ring-error-100 dark:focus:ring-error-100',
  },

  // Select
  select: {
    base: 'relative w-full h-[40px] body-400 pl-3 pr-7 rounded-inputs border border-neutral-400 text-left cursor-default focus:outline-none dark:text-neutral-300 focus:ring focus:ring-primary-100 focus:border-primary-100',
    active: 'focus:ring-0 focus:outline-primary-500 outline-offset-2 outline-1',
    select: 'leading-5',
    selected: 'bg-primary-300',
    disabled:
      'cursor-not-allowed bg-neutral-200 border-neutral-400 dark:bg-neutral-800',
    valid:
      'border-success-500 dark:bg-neutral-700 focus:border-success-300 dark:focus:border-success-300 focus:ring focus:ring-success-200 dark:focus:ring-success-200',
    invalid:
      'outline outline-error-300 outline-offset-2 rounded-inputs focus:ring focus:ring-error-100 dark:bg-neutral-700 dark:focus:border-error-300 dark:focus:ring-error-100',
    option:
      'bg-white rounded-inputs mt-1 max-h-[170px] overflow-auto shadow-lg border border-neutral-400 focus:outline-none focus-visible:outline-none absolute min-w-max w-full z-50 cursor-pointer body-400',
    icon: 'absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none',
  },

  // Autocomplete
  autocomplete: {
    base: 'block w-full h-[40px] body-400 px-3 bg-white rounded-inputs border border-neutral-400 text-left cursor-default focus:outline-none focus:ring focus:ring-primary-100 focus:border-primary-100',
    active:
      'focus:ring focus:ring-primary-100 focus:border-primary-100 border-neutral-400 bg-primary-100 dark:border-neutral-600 dark:focus:border-neutral-600 dark:focus:ring-neutral-300 dark:bg-neutral-700',
    select: 'leading-5',
    selected: 'bg-primary-200',
    disabled: 'cursor-not-allowed bg-neutral-300 dark:bg-neutral-800',
    valid:
      'border-success-500 dark:bg-neutral-700 focus:border-success-300 dark:focus:border-success-300 focus:ring focus:ring-success-200 dark:focus:ring-success-200',
    invalid: 'text-error-700 outline outline-error-300 outline-offset-2 pr-9',
    option:
      'bg-white rounded-inputs mt-1 max-h-[170px] overflow-auto space-y-1 shadow-lg border border-neutral-400 focus:outline-none focus-visible:outline-none absolute min-w-max w-full z-[100]',
    icon: 'absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none',
  },

  // Status Pill
  statuspill: {
    base: 'inline-flex heading-100 rounded-xl text-white py-1 px-2',
    neutral: 'bg-neutral-700 dark:text-neutral-100 dark:bg-neutral-700',
    primary: 'bg-primary-700 dark:text-white dark:bg-primary-600',
    secondary: 'bg-primary-500 dark:text-white dark:bg-primary-600',
    info: 'text-info-700 bg-primary-500 dark:text-white dark:bg-primary-600',
    success: 'bg-success-500 dark:bg-success-700 dark:text-success-100',
    warning: 'bg-warning-500 dark:text-white dark:bg-warning-500',
    error: 'bg-error-500 dark:text-error-100 dark:bg-error-700',
    light: 'bg-secondary-100 !text-black',
  },

  // Input
  input: {
    base: 'block w-full h-[40px] body-400 rounded-inputs border border-neutral-400 focus:outline-none dark:text-neutral-300',
    active:
      'focus:ring-0 focus:border-neutral-400 focus:outline-primary-500 outline-offset-2 outline-1',
    activeDark:
      'focus:ring-0 focus:border-neutral-400 focus:outline-black outline-offset-2 outline-1',
    disabled:
      'cursor-not-allowed bg-neutral-200 border-neutral-400 dark:bg-neutral-800',
    valid:
      'border-success-500 dark:bg-neutral-700 focus:border-success-300 dark:focus:border-success-300 focus:ring focus:ring-success-100 dark:focus:ring-success-100',
    invalid:
      'border-error-500 dark:bg-neutral-700 focus:border-error-300 dark:focus:border-error-300 focus:ring focus:ring-error-100 dark:focus:ring-error-100',
    radio:
      'text-primary-600 form-radio focus:border-primary-400 focus:outline-none focus:ring focus:ring-primary-300 focus:ring-offset-0 dark:focus:ring-neutral-300',
    checkbox:
      'text-primary-600 form-checkbox focus:border-primary-400 focus:outline-none focus:ring focus:ring-primary-300 focus:ring-offset-0 rounded dark:focus:ring-neutral-300',
  },
  //tooltip
  tooltip: {
    base: 'absolute bg-neutral-700 text-white body-300 p-2 z-10 rounded shadow-lg w-fit whitespace-nowrap text-center',
    left: 'right-4',
    right: 'left-4',
    center: 'top-0 left-0',
    top: 'bottom-4',
  },

  // Card
  card: {
    base: 'min-w-0 rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden',
    default: 'bg-white dark:bg-neutral-800',
  },
  cardBody: {
    base: 'p-4',
  },
};

export default myTheme;
