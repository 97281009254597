import React from 'react';
import classNames from 'classnames';
import { ModalTitleWithIcon } from 'ui';
import { XIcon } from '@heroicons/react/outline';
import * as Dialog from '@radix-ui/react-dialog';
import style from './Dialog.module.css';
import { Loading } from './DialogLoading';

type DialogContainerProps = {
  children: React.ReactNode;
  dialogIconColor?: 'primary' | 'destructive' | 'warning' | 'success';
  dialogIcon?: React.ReactElement;
  dialogIconTitle?: string;
  dialogTitle?: React.ReactElement;
  isLoading?: boolean;
  open?: boolean;
  onClose: (arg: boolean) => void;
};

const DialogContainer = ({
  children,
  dialogIconColor,
  dialogIcon,
  dialogIconTitle,
  dialogTitle,
  isLoading = false,
  open = false,
  onClose,
}: DialogContainerProps) => {
  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay
          className={classNames(
            'fixed inset-0 grid place-items-center bg-black bg-opacity-40 z-[102] h-screen overflow-y-auto',
            style.overlay
          )}
        >
          <Dialog.Content
            asChild
            onPointerDownOutside={(e) => {
              const currentTarget = e.currentTarget as HTMLElement;

              if (e.detail.originalEvent.offsetX > currentTarget.clientWidth) {
                e.preventDefault();
              }
            }}
          >
            <div className="flex flex-col items-center justify-center text-center rounded-md bg-white my-10 py-8 px-6 shadow-xl z-[103]">
              <div className="flex flex-row text-left items-start w-full">
                <div className="grow">{dialogTitle}</div>
                <button
                  onClick={() => onClose(false)}
                  className="cursor-pointer text-neutral-700 font-normal hover:text-black"
                >
                  <XIcon className="w-4" />
                </button>
              </div>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <Dialog.Title>
                    {dialogIcon && (
                      <ModalTitleWithIcon
                        icon={dialogIcon}
                        color={dialogIconColor}
                        title={dialogIconTitle || ''}
                      />
                    )}
                  </Dialog.Title>
                  {children}
                </>
              )}
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default DialogContainer;
