import { InferType, boolean, number, object, string } from 'yup';

const envSchema = object({
  // SESSION
  NEXT_PUBLIC_SESSION_DURATION: number().required(),
  NEXT_PUBLIC_REFETCH_SESSION_INTERVAL: number().required(),
  NEXT_PUBLIC_REFRESH_TOKEN_MARGIN: number().required(),

  // SIGN OUT MODAL
  NEXT_PUBLIC_SIGN_OUT_MODAL_TIMEOUT: number().required(),
  NEXT_PUBLIC_CONFIRM_SIGN_OUT_MODAL_TIMEOUT: number().required(),

  // LOOKER
  NEXT_PUBLIC_LD_MAIN: string(),
  NEXT_PUBLIC_LD_BORROWER_PERFORMANCE: string(),
  NEXT_PUBLIC_LOOKER_POLLING_INTERVAL: number().required(),

  // INTERCOM
  NEXT_PUBLIC_INTERCOM_ID: string(),

  // REDUX
  NEXT_PUBLIC_REDUX_DEVTOOLS: boolean().default(false),

  // RTK QUERY
  NEXT_PUBLIC_RTK_QUERY_KEEP_UNUSED_DATA_FOR: number().default(60),
  NEXT_PUBLIC_RTK_QUERY_KEEP_UNUSED_DATA_FOR_ONBOARDING_LOANS:
    number().default(60),
  NEXT_PUBLIC_RTK_QUERY_KEEP_UNUSED_DATA_FOR_APPROVED_LOANS:
    number().default(60),

  // CODAT
  NEXT_PUBLIC_CODAT_BASE_LINK_URL: string(),

  // WISERFUNDING
  NEXT_PUBLIC_WISERFUNDING_COMPANY_ID: string(),
  NEXT_PUBLIC_WISERFUNDING_CONNECTION_ID: string(),
  NEXT_PUBLIC_WISERFUNDING_PERIOD_LENGTH: number().default(3),
  NEXT_PUBLIC_WISERFUNDING_START_MONTH: string().default('2022-12'),

  // FEATURE FLAGS
  NEXT_PUBLIC_PERMISSIONS_ACTIVATE_DIRECT_DEBIT: boolean().default(false),
  NEXT_PUBLIC_PERMISSIONS_DETAILS_EDIT: boolean().default(false),
  NEXT_PUBLIC_PERMISSIONS_DISABLE_EDIT_COLUMNS: boolean().default(false),
  NEXT_PUBLIC_PERMISSIONS_DISABLE_INVITE_USERS_BUTTON: boolean().default(false),
  NEXT_PUBLIC_PERMISSIONS_DISABLE_EDIT_WITH_TRANCHES: boolean().default(false),
  NEXT_PUBLIC_BORROWER_CAN_SEE_CODAT_LINK_BUTTON: boolean().default(false),
  NEXT_PUBLIC_CAN_SEE_WISERFUNDING_LINK: boolean().default(false),
  NEXT_PUBLIC_HIDE_RESTRUCTURE_STEP: boolean().default(false),
  NEXT_PUBLIC_HIDE_UNDERWRITING_SECTION: boolean().default(false),
  NEXT_PUBLIC_HIDE_ONBOARDING_ASSET_STEP: boolean().default(false),
  NEXT_PUBLIC_HIDE_REPAYMENTS_TAB: boolean().default(false),
  NEXT_PUBLIC_CONSOLIDATE: boolean().default(true),
  NEXT_PUBLIC_HIDE_INTERCOM_CHATBOT: boolean().default(true),
  // SANDBOX
  NEXT_PUBLIC_SANDBOX_TERMS_URL: string(),
  NEXT_PUBLIC_LOOKER_DASH_ALL_KEY: string(),
  NEXT_PUBLIC_LOOKER_DASH_INTEREST_KEY: string(),
  NEXT_PUBLIC_LOOKER_DASH_TRANCHES_KEY: string(),
  NEXT_PUBLIC_LOOKER_DASH_SINGLE_KEY: string(),
  NEXT_PUBLIC_INVESTOR_BORROWER_TERMS_URL: string(),

  // MODULR
  NEXT_PUBLIC_MODULR_DISCLAIMER_URL: string(),

  // 2FA
  MANDATORY_2FA: boolean().default(false),

  // GENERAL
  NEXT_PUBLIC_ENVIRONMENT: string(),
  // CONST URLs
  NEXT_PUBLIC_TEMPLATE_URL: string(),
});

const env: InferType<typeof envSchema> = envSchema.validateSync(
  // NEXT_PUBLIC_ env variables need to be referenced statically (as process.env.NEXT_PUBLIC_{VARIABLE}) to be inlined by Next at build time
  {
    // SESSION
    NEXT_PUBLIC_SESSION_DURATION: process.env.NEXT_PUBLIC_SESSION_DURATION,
    NEXT_PUBLIC_REFETCH_SESSION_INTERVAL:
      process.env.NEXT_PUBLIC_REFETCH_SESSION_INTERVAL,
    NEXT_PUBLIC_REFRESH_TOKEN_MARGIN:
      process.env.NEXT_PUBLIC_REFRESH_TOKEN_MARGIN,

    // SIGN OUT MODAL
    NEXT_PUBLIC_SIGN_OUT_MODAL_TIMEOUT:
      process.env.NEXT_PUBLIC_SIGN_OUT_MODAL_TIMEOUT,
    NEXT_PUBLIC_CONFIRM_SIGN_OUT_MODAL_TIMEOUT:
      process.env.NEXT_PUBLIC_CONFIRM_SIGN_OUT_MODAL_TIMEOUT,

    // LOOKER
    NEXT_PUBLIC_LD_MAIN: process.env.NEXT_PUBLIC_LD_MAIN,
    NEXT_PUBLIC_LD_BORROWER_PERFORMANCE:
      process.env.NEXT_PUBLIC_LD_BORROWER_PERFORMANCE,
    NEXT_PUBLIC_LOOKER_POLLING_INTERVAL:
      process.env.NEXT_PUBLIC_LOOKER_POLLING_INTERVAL,

    // INTERCOM
    NEXT_PUBLIC_INTERCOM_ID: process.env.NEXT_PUBLIC_INTERCOM_ID,

    // REDUX
    NEXT_PUBLIC_REDUX_DEVTOOLS: process.env.NEXT_PUBLIC_REDUX_DEVTOOLS,

    // RTK QUERY
    NEXT_PUBLIC_RTK_QUERY_KEEP_UNUSED_DATA_FOR:
      process.env.NEXT_PUBLIC_RTK_QUERY_KEEP_UNUSED_DATA_FOR,
    NEXT_PUBLIC_RTK_QUERY_KEEP_UNUSED_DATA_FOR_ONBOARDING_LOANS:
      process.env.NEXT_PUBLIC_RTK_QUERY_KEEP_UNUSED_DATA_FOR_ONBOARDING_LOANS,
    NEXT_PUBLIC_RTK_QUERY_KEEP_UNUSED_DATA_FOR_APPROVED_LOANS:
      process.env.NEXT_PUBLIC_RTK_QUERY_KEEP_UNUSED_DATA_FOR_APPROVED_LOANS,

    //CODAT
    NEXT_PUBLIC_CODAT_BASE_LINK_URL:
      process.env.NEXT_PUBLIC_CODAT_BASE_LINK_URL,

    // WISERFUNDING
    NEXT_PUBLIC_WISERFUNDING_COMPANY_ID:
      process.env.NEXT_PUBLIC_WISERFUNDING_COMPANY_ID,
    NEXT_PUBLIC_WISERFUNDING_CONNECTION_ID:
      process.env.NEXT_PUBLIC_WISERFUNDING_CONNECTION_ID,
    NEXT_PUBLIC_WISERFUNDING_PERIOD_LENGTH:
      process.env.NEXT_PUBLIC_WISERFUNDING_PERIOD_LENGTH,
    NEXT_PUBLIC_WISERFUNDING_START_MONTH:
      process.env.NEXT_PUBLIC_WISERFUNDING_START_MONTH,

    // FEATURE FLAGS
    NEXT_PUBLIC_PERMISSIONS_ACTIVATE_DIRECT_DEBIT:
      process.env.NEXT_PUBLIC_PERMISSIONS_ACTIVATE_DIRECT_DEBIT,
    NEXT_PUBLIC_PERMISSIONS_DETAILS_EDIT:
      process.env.NEXT_PUBLIC_PERMISSIONS_DETAILS_EDIT,
    NEXT_PUBLIC_PERMISSIONS_DISABLE_EDIT_COLUMNS:
      process.env.NEXT_PUBLIC_PERMISSIONS_DISABLE_EDIT_COLUMNS,
    NEXT_PUBLIC_PERMISSIONS_DISABLE_INVITE_USERS_BUTTON:
      process.env.NEXT_PUBLIC_PERMISSIONS_DISABLE_INVITE_USERS_BUTTON,
    NEXT_PUBLIC_PERMISSIONS_DISABLE_EDIT_WITH_TRANCHES:
      process.env.NEXT_PUBLIC_PERMISSIONS_DISABLE_EDIT_WITH_TRANCHES,
    NEXT_PUBLIC_BORROWER_CAN_SEE_CODAT_LINK_BUTTON:
      process.env.NEXT_PUBLIC_BORROWER_CAN_SEE_CODAT_LINK_BUTTON,
    NEXT_PUBLIC_CAN_SEE_WISERFUNDING_LINK:
      process.env.NEXT_PUBLIC_CAN_SEE_WISERFUNDING_LINK,
    NEXT_PUBLIC_HIDE_RESTRUCTURE_STEP:
      process.env.NEXT_PUBLIC_HIDE_RESTRUCTURE_STEP,
    NEXT_PUBLIC_HIDE_UNDERWRITING_SECTION:
      process.env.NEXT_PUBLIC_HIDE_UNDERWRITING_SECTION,
    NEXT_PUBLIC_HIDE_REPAYMENTS_TAB:
      process.env.NEXT_PUBLIC_HIDE_REPAYMENTS_TAB,
    NEXT_PUBLIC_HIDE_ONBOARDING_ASSET_STEP:
      process.env.NEXT_PUBLIC_HIDE_ONBOARDING_ASSET_STEP,
    NEXT_PUBLIC_HIDE_INTERCOM_CHATBOT:
      process.env.NEXT_PUBLIC_HIDE_INTERCOM_CHATBOT,

    // SANDBOX
    NEXT_PUBLIC_SANDBOX_TERMS_URL: process.env.NEXT_PUBLIC_SANDBOX_TERMS_URL,
    NEXT_PUBLIC_LOOKER_DASH_ALL_KEY:
      process.env.NEXT_PUBLIC_LOOKER_DASH_ALL_KEY,
    NEXT_PUBLIC_LOOKER_DASH_INTEREST_KEY:
      process.env.NEXT_PUBLIC_LOOKER_DASH_INTEREST_KEY,
    NEXT_PUBLIC_LOOKER_DASH_TRANCHES_KEY:
      process.env.NEXT_PUBLIC_LOOKER_DASH_TRANCHES_KEY,
    NEXT_PUBLIC_LOOKER_DASH_SINGLE_KEY:
      process.env.NEXT_PUBLIC_LOOKER_DASH_SINGLE_KEY,
    NEXT_PUBLIC_INVESTOR_BORROWER_TERMS_URL:
      process.env.NEXT_PUBLIC_INVESTOR_BORROWER_TERMS_URL,

    // MODULR
    NEXT_PUBLIC_MODULR_DISCLAIMER_URL:
      process.env.NEXT_PUBLIC_MODULR_DISCLAIMER_URL,

    // 2FA
    MANDATORY_2FA: process.env.MANDATORY_2FA,

    // GENERAL
    NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    // CONST URLs
    NEXT_PUBLIC_TEMPLATE_URL: process.env.NEXT_PUBLIC_TEMPLATE_URL,
  },
  { stripUnknown: true }
);

export { env };
