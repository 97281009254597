import Head from 'next/head';
import { kennekFavicon } from 'kennek/assets';
import { useBranchEncodedKey } from '@/hooks/useBranchEncodedKey';
import useGetBrandData from '@/hooks/useGetBrandData';

const PAGE_TITLE_DEFAULT_LOGGED_IN = 'Kennek';
const PAGE_TITLE_DEFAULT_AUTH = 'Kennek solutions';

interface MetaHeaderProps {
  isAuth?: boolean;
}

export const MetaHeader = ({ isAuth = false }: MetaHeaderProps) => {
  const { branchEncodedKey } = useBranchEncodedKey();
  const { brandData } = useGetBrandData(branchEncodedKey);

  const defaultTitle = isAuth
    ? PAGE_TITLE_DEFAULT_AUTH
    : PAGE_TITLE_DEFAULT_LOGGED_IN;
  const logoUrl = brandData?.favicon?.url ?? kennekFavicon.src;
  const pageTitle = brandData?.name ?? defaultTitle;

  return (
    <Head>
      <title>{pageTitle}</title>
      <link rel="icon" href={logoUrl} />
    </Head>
  );
};
