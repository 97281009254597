import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { SvgIcon } from 'kennek/icons';
import { Alert, Button, Modal, ModalTitleWithIcon } from 'ui';
import { CopyButton } from '@/components/common/CopyButton';
import { SNACKBAR_PAYMENT } from '@/constants/snackbar-messages';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useNoticeBorrowerPaymentMutation } from '@/services/accounts/borrower';
import { getHoursDifferenceFromCurrentDate } from '@/utils/dates';
import { formatAmount, formatDate } from '@/utils/formatters';
import { LoanSummaryContext } from './ContextWrapper';
import { Loan } from 'kennek/interfaces/loans';

type Props = {
  showPaymentModal: boolean;
  setShowPaymentModal: (val: boolean) => void;
  loan?: Loan;
  totalDueAmount: string;
};

const MakePaymentModal: React.FC<Props> = ({
  showPaymentModal,
  setShowPaymentModal,
  loan,
  totalDueAmount,
}) => {
  const [isPaymentConfirmed, setIdPaymentConfirmed] = useState(false);
  const [noticePayment, { isLoading }] = useNoticeBorrowerPaymentMutation();
  const snackbar = useSnackbar();

  const {
    currency,
    nextInstallmentPaymentDue,
    nextInstallmentDueDate,
    totalOverdueAmount,
    nextPaymentData: { customerName = '' },
  } = useContext(LoanSummaryContext);

  useEffect(() => {
    if (loan?.borrowerLastPaymentNotice) {
      const hoursFromLastPaymentNotice = getHoursDifferenceFromCurrentDate(
        loan.borrowerLastPaymentNotice
      );
      if (hoursFromLastPaymentNotice < 24) setIdPaymentConfirmed(true);
    }
  }, [loan?.borrowerLastPaymentNotice]);

  const onConfirmPayment = () => {
    noticePayment({ loanEncodedKey: loan?.encodedKey })
      .unwrap()
      .then(() => {
        snackbar.show({
          severity: 'success',
          title: SNACKBAR_PAYMENT.PAYMENT_CONFIRMATION_SUCCESS,
        });
      })
      .catch(() => {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_PAYMENT.PAYMENT_CONFIRMATION_FAILED,
        });
      })
      .finally(() => {
        setShowPaymentModal(false);
      });
  };

  return (
    <Modal
      onClose={() => setShowPaymentModal(false)}
      paymentModal={true}
      isVisible={showPaymentModal}
      includeHeader="withCloseButton"
      bgOpacity="bg-opacity-95"
      className="p-[20px] h-[100vh]"
    >
      <div>
        {isPaymentConfirmed && (
          <>
            <div className="flex justify-center">
              <div
                className={classNames(
                  'w-12 h-12 rounded-full flex justify-center items-center mb-2 bg-neutral-200'
                )}
              >
                <SvgIcon name="BankIcon" className="inline w-6 h-6" />
              </div>
            </div>
            <Alert type={'info'} className="w-full mt-4 mb-5">
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <span className="body-400-semibold">
                    You have confirmed a payment.
                  </span>
                </div>
                <span className="body-400 mt-1">
                  We are currently processing your payment it may take up to 2
                  days to process.
                </span>
              </div>
            </Alert>
          </>
        )}

        <ModalTitleWithIcon
          color="neutral"
          icon={isPaymentConfirmed ? null : <SvgIcon name="BankIcon" />}
          mb=""
          title={'Please send a payment to the following account:'}
        />
        <p className="text-[12px] flex justify-center text-center leading-3">
          It may take up to 2 business days to process the payment to be
          reflected on the schedule.
        </p>

        <p className="heading-100 mt-5">Bank information</p>
        {loan?.modulrAccount?.sortCode && (
          <div className="flex justify-between my-[10px]">
            <p>Sort code</p>
            <div className="flex">
              <p>{loan?.modulrAccount?.sortCode}</p>
              <CopyButton valueToCopy={loan?.modulrAccount?.sortCode} />
            </div>
          </div>
        )}

        {loan?.modulrAccount?.accountNumber && (
          <div className="flex justify-between my-[10px]">
            <p>Account number</p>
            <div className="flex">
              <p>{loan?.modulrAccount?.accountNumber}</p>
              <CopyButton valueToCopy={loan?.modulrAccount?.accountNumber} />
            </div>
          </div>
        )}
        {customerName && (
          <div className="flex justify-between my-[10px]">
            <p>Account name</p>
            <div className="flex">
              <p>{customerName}</p>
              <CopyButton valueToCopy={customerName} />
            </div>
          </div>
        )}

        <p className="heading-100 mt-5">Amounts</p>

        {totalOverdueAmount > 0 && (
          <div className="flex justify-between my-[10px] text-error-500">
            <p>Amount overdue</p>
            <div className="flex">
              {formatAmount(totalOverdueAmount, true, currency)}
              <CopyButton
                valueToCopy={formatAmount(totalOverdueAmount, true, currency)}
              />
            </div>
          </div>
        )}

        {totalOverdueAmount > 0 && (
          <div className="flex justify-between my-[10px]">
            <p>Next instalment</p>
            <div className="flex">
              <div className="flex flex-col items-end">
                {formatAmount(nextInstallmentPaymentDue, true, currency)}
                <span className="body-400 text-[12px] font-medium">
                  {formatDate(nextInstallmentDueDate, 'dd/MM/yyyy')}
                </span>
              </div>

              <div className="mb-[5px]">
                <CopyButton
                  valueToCopy={formatAmount(
                    nextInstallmentPaymentDue,
                    true,
                    currency
                  )}
                />
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-between my-[10px]">
          <p>Total amount</p>
          <div className="flex">
            {totalDueAmount}
            <CopyButton valueToCopy={totalDueAmount} />
          </div>
        </div>

        <div className="flex mt-8">
          <Button
            layout="ghost"
            className="mr-4 w-full h-8"
            onClick={() => setShowPaymentModal(false)}
          >
            Confirm later
          </Button>
          <Button
            className="w-full h-8"
            onClick={onConfirmPayment}
            loading={isLoading}
            disabled={isPaymentConfirmed || isLoading}
          >
            Confirm payment
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MakePaymentModal;
