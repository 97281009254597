export const POSTAL_CODE_REGEX =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z]\d{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y]\d{1,2})|(([A-Za-z]\d[A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y]\d[A-Za-z]?))))\s?\d[A-Za-z]{2})$/;

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const formatKeyIntoLabel = (key: string) => {
  return (
    key
      // Insert a space before any capital letter that is preceded by a lowercase letter or another capital letter.
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
      // Insert a space before a sequence of digits that is preceded by a letter.
      .replace(/([a-zA-Z])(\d+)/g, '$1 $2')
      // Capitalize the very first letter and make the rest lowercase.
      .replace(/^./, function (char) {
        return char.toUpperCase();
      })
  );
};
