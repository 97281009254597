import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SvgIcon } from 'kennek/icons';
import BREAKPOINTS from 'ui/utils/breakpoints';
import {
  ClipboardListIcon,
  PlusCircleIcon,
  SwitchHorizontalIcon,
  TemplateIcon,
} from '@heroicons/react/outline';
import { ROUTES } from '@/constants/routes';
import { env } from '@/env/public';
import {
  useGetCodatKeyQuery,
  useGetDisabledFeaturesQuery,
} from '@/services/kennek';
import { useGetUnderwritingRequestQuery } from '@/services/underwriting';
import { onSetSideBarPreference, setCodatKey } from '@/store/user';
import { useSelectSideBarPref, useSelectUser } from '@/store/user/selectors';
import useGetLabelsConfig from './useGetLabelsConfig';
import { useRole } from './useRole';
import useUserRole from './useUserRole';
import { OptionalFeatures } from 'kennek/interfaces/kennek';
import type { SideBarStatus } from 'ui/types';
import { UNDERWRITING_STATUS } from '@/interfaces/underwriting';

interface SideBarItem {
  text?: string;
  title?: string;
  route?: string;
  icon?: JSX.Element;
  roles?: string[];
  hide?: boolean;
  disabledBy?: OptionalFeatures[];
  items?: {
    text: string;
    route: string;
    icon: JSX.Element;
    specialAlignment?: string;
    iconSize?: string;
    roles?: string[];
    hide?: boolean;
  }[];
}

export function useSideBar() {
  const dispatch = useDispatch();
  const labelsConfig = useGetLabelsConfig();

  const { role: baseRole, isLoading } = useRole();

  const preference = useSelectSideBarPref();

  const [status, setStatus] = useState<SideBarStatus>('collapsed');

  const user = useSelectUser();

  // pass approvedLoans as empty array as for sidebar role
  // UNDERWRITING_BORROWER should only be based on executionIds occurrance
  const role = useUserRole(baseRole, user, []);

  const { data: acceptedLoans, isLoading: isLoadingLoan } =
    useGetUnderwritingRequestQuery(
      {
        branchEncodedKey: user?.mambuUser?.[0]?.mambuBranchEncodedKey,
        page: 1,
        pageSize: 1,
        status: UNDERWRITING_STATUS.ACCEPTED,
      },
      {
        skip:
          !user?.mambuUser?.[0]?.mambuBranchEncodedKey ||
          role !== 'BORROWER_UNDERWRITING',
      }
    );

  const branchEncodedKey = user.mambuUser?.[0]?.mambuBranchEncodedKey;

  const { data: codatData, isLoading: isLoadingCodatKey } = useGetCodatKeyQuery(
    { branchEncodedKey },
    { skip: !branchEncodedKey }
  );

  const disabledFeaturesQuery = useGetDisabledFeaturesQuery(
    { branchEncodedKey },
    { skip: !branchEncodedKey }
  );

  useEffect(() => {
    if (preference === null) {
      if (typeof window !== undefined) {
        setStatus(
          window?.innerWidth <= BREAKPOINTS.Lg ? 'collapsed' : 'expanded'
        );
      }
    } else {
      setStatus(preference);
    }
  }, [preference]);

  useEffect(() => {
    if (isLoadingCodatKey) return;
    dispatch(setCodatKey(codatData?.codatApiKey ?? ''));
  }, [codatData, isLoadingCodatKey]);

  const onCollapsedChange = (status: SideBarStatus) => {
    dispatch(onSetSideBarPreference(status));
  };

  const items = useMemo((): SideBarItem[] => {
    if (isLoading || isLoadingCodatKey || isLoadingLoan) return [];
    const { loanUpper, loanLower, loansUpper } = labelsConfig;

    const borrowerTasksRoles = ['BORROWER'];
    const showTasksForUnderwritingBorrower =
      acceptedLoans?.result?.totalCount > 0;
    if (showTasksForUnderwritingBorrower)
      borrowerTasksRoles.push('BORROWER_UNDERWRITING');

    const items: SideBarItem[] = [
      {
        text: 'Dashboard',
        route: ROUTES.DASHBOARD,
        icon: <TemplateIcon />,
      },
      ...(env.NEXT_PUBLIC_HIDE_UNDERWRITING_SECTION
        ? []
        : [
            {
              title: 'Underwriting',
              roles: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN'],
              items: [
                // {
                //   text: 'Create new loan',
                //   route: ROUTES.UNDERWRITING_NEW,
                //   icon: <PlusCircleIcon />,
                //   roles: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN'],
                // },
                {
                  text: `${loanUpper} applications`,
                  route: ROUTES.UNDERWRITING_REQUESTS,
                  icon: <SvgIcon name="UnderwritingIcon" />,
                  roles: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN'],
                },
              ],
              disabledBy: [OptionalFeatures.UNDERWRITING],
            },
          ]),
      {
        title: 'Servicing\u00a0&\u00a0Monitoring',
        roles: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN', 'INVESTOR'],
        items: [
          {
            text: `Add ${loanLower}`,
            route: ROUTES.ONBOARDING_NEW,
            icon: <PlusCircleIcon />,
            roles: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN'],
          },
          {
            text: loansUpper,
            route: ROUTES.SERVICING_LOANS,
            icon: <SvgIcon name="LoansIcon" />,
            specialAlignment: 'loansIcon',
            roles: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN', 'INVESTOR'],
          },
          {
            text: 'Risk analysis',
            route: ROUTES.SERVICING_RISK_ANALYSIS,
            icon: <SvgIcon name="RiskAnalysisIcon" />,
            roles: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN'],
            hide: !codatData?.codatApiKey,
          },
          {
            text: 'Tasks',
            route: ROUTES.SERVICING_TASKS,
            icon: <ClipboardListIcon />,
            roles: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN'],
          },
          {
            text: 'Repayments',
            route: ROUTES.SERVICING_REPAYMENTS,
            icon: <SvgIcon name="RepaymentsIcon" />,
            specialAlignment: 'repaymentsIcon',
            roles: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN', 'INVESTOR'],
            hide: env.NEXT_PUBLIC_HIDE_REPAYMENTS_TAB,
          },
          {
            text: 'Transactions',
            route: ROUTES.SERVICING_TRANSACTIONS,
            icon: <SwitchHorizontalIcon />,
            roles: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN'],
          },
        ],
      },
      {
        title: 'Investor Reporting',
        roles: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN', 'INVESTOR'],
        items: [
          {
            text: 'Individual',
            route: ROUTES.INDIVIDUAL,
            icon: <SvgIcon name="IndividualIcon" />,
            iconSize: 'sm',
            specialAlignment: 'individualIcon',
            roles: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN', 'INVESTOR'],
          },
          {
            text: 'Portfolio',
            route: ROUTES.PORTFOLIO,
            icon: <SvgIcon name="PortfolioIcon" />,
            roles: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN', 'INVESTOR'],
          },
        ],
      },
      {
        text: 'Tasks',
        route: ROUTES.TASKS,
        icon: <ClipboardListIcon />,
        roles: borrowerTasksRoles,
      },
      {
        text: 'Documentation',
        route: ROUTES.BORROWER_DOCUMENTATION,
        icon: <SvgIcon name="DocumentsIcon" />,
        roles: ['BORROWER', 'BORROWER_UNDERWRITING'],
      },
      ...(env.NEXT_PUBLIC_HIDE_UNDERWRITING_SECTION
        ? []
        : [
            {
              text: `${loanUpper} applications`,
              route: ROUTES.UNDERWRITING_REQUESTS,
              icon: <SvgIcon name="UnderwritingApplicationsIcon" />,
              roles: ['BORROWER_UNDERWRITING'],
            },
          ]),
    ];

    const roleFilteredItems = items.filter((item) =>
      item.roles ? item.roles.includes(role) : true
    );

    const subItemFiltered = roleFilteredItems.map((item) => ({
      ...item,
      items: item.items?.filter((subItem) =>
        subItem.roles ? subItem.roles.includes(role) : true
      ),
    }));

    const optionalFeaturesFiltered = subItemFiltered.filter((item) => {
      const isProtectedRoute =
        disabledFeaturesQuery.currentData?.includes(
          DISABLED_FEATURES[item.route]
        ) ?? false;

      const isDisabledRoute =
        item?.disabledBy &&
        (disabledFeaturesQuery.currentData?.some((route) =>
          item.disabledBy.includes(route)
        ) ??
          false);

      return !isProtectedRoute && !isDisabledRoute;
    });

    return optionalFeaturesFiltered.map((item) => ({
      ...item,
      items: item.items?.filter(
        (subItem) =>
          !disabledFeaturesQuery.currentData?.includes(
            DISABLED_FEATURES[subItem.route]
          ) ?? true
      ),
    }));
  }, [
    disabledFeaturesQuery.currentData,
    isLoading,
    role,
    isLoadingCodatKey,
    acceptedLoans,
    labelsConfig,
  ]);

  return {
    items,
    disabledRoute: ROUTES.COMING_SOON,
    collapsed: status,
    onCollapsedChange,
    hidden: false,
  };
}

const DISABLED_FEATURES: Record<string, OptionalFeatures> = {
  [ROUTES.SERVICING_RISK_ANALYSIS]: OptionalFeatures.RISK_ANALYSIS,
  [ROUTES.UNDERWRITING_NEW]: OptionalFeatures.UNDERWRITING,
  [ROUTES.UNDERWRITING_REQUESTS]: OptionalFeatures.UNDERWRITING,
  [ROUTES.SERVICING_REPAYMENTS]: OptionalFeatures.REPAYMENTS,
};
