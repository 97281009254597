import React, { forwardRef, useContext } from 'react';
import classNames from 'classnames';
import { SvgIcon } from 'kennek/icons';
import { ThemeContext } from '../theme';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  position?: 'left' | 'right' | 'center' | 'top' | 'custom' | 'top-center';
  customPosition?: string;
  text?: string;
  withoutArrow?: boolean;
  customClasses?: string;
}

const Tooltip = forwardRef<HTMLDivElement, Props>(function Tooltip(props, ref) {
  const {
    children,
    position = 'left',
    withoutArrow = false,
    customPosition,
    customClasses = '',
  } = props;

  const {
    theme: { tooltip },
  } = useContext(ThemeContext);

  const baseStyle = tooltip.base;
  const typeStyle = {
    left: tooltip.left,
    right: tooltip.right,
    center: tooltip.center,
    top: tooltip.top,
    'top-center': tooltip['top-center'],
    custom: customPosition,
  };

  const cls = classNames(baseStyle, typeStyle[position], customClasses);
  return (
    <>
      <div ref={ref} className={classNames(cls)}>
        {children}
      </div>
      {!withoutArrow && (
        <SvgIcon
          name="TooltipIcon"
          className={classNames(
            'absolute z-10 w-6 h-6 transform -translate-x-12 -translate-y-3 fill-current stroke-current',
            {
              'top-8 right-14': position === 'left',
              'top-3 left-12': position === 'right',
              'top-3 left-10 ': position === 'top',
              'top-3 left-0 ': position === 'top-center',
            }
          )}
        />
      )}
    </>
  );
});

export default Tooltip;
