import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { SvgIcon } from 'kennek/icons';
import { Button, FormInput, Select } from 'ui';
import { v4 as uuid } from 'uuid';
import { FACILITY_MESSAGE } from '@/constants/messages';
import { formatAmount } from '@/utils/formatters';
import { flattenAndSumFacilities } from './utils';
import { Currencies } from 'kennek/interfaces/loans';
import {
  Facility,
  LoanFacility,
  RowLoanFacility,
} from '@/interfaces/loans/facility';

const getNestedProperty = (object, key) => {
  return key.split('.').reduce((acc, curr) => acc && acc[curr], object);
};

export interface EditTranchesFacilitiesOptions {
  value: string;
  name: string;
  label: string;
  amount: string;
  initial: number;
  facilityId: string;
}

export interface EditTranchesFacilitiesFormProps {
  availableAmount: number;
  form: UseFormReturn<any>;
  initialStateValues?: RowLoanFacility[];
  facilitiesOptions?: EditTranchesFacilitiesOptions[];
  formKey: `tranches.${number}.facilities`;
  currency?: Currencies;
  disabled?: boolean;
  elementWidth?: string;
}
/**
 * @component EditTranchesFacilitiesForm
 * This component is designed to manage an array of inputs for filling in facility details for each tranche, used during onboarding and edit flows.
 *
 * @param availableAmount - The amount available for allocation.
 * @param form - The form instance to which facilities inputs will be added as an extension.
 * @param formKey - The key structure used to select facilities within the form example: tranches.${number}.facilities.
 * @param currency - Used currency.
 * @param initialStateValues - Initial values to prepopulate the form with, typically used for edit flows.
 * @param disabled - Disable all fields.
 * @param elementWidth - Onboarding or loan tranches view element width.
 *
 * @returns JSX.Element - Returns the JSX representation of the EditTranchesFacilitiesForm.
 */
const EditTranchesFacilitiesForm = ({
  form: {
    setError,
    clearErrors,
    setValue,
    register,
    getValues,
    unregister,
    trigger,
    formState: { errors },
  },
  formKey,
  facilitiesOptions,
  currency,
  initialStateValues = [],
  availableAmount = 0,
  disabled,
  elementWidth,
}: EditTranchesFacilitiesFormProps) => {
  const availableAmountRef = useRef(availableAmount);

  useEffect(() => {
    availableAmountRef.current = availableAmount;
    trigger(formKey);
  }, [availableAmount]);

  const [usedFacilities, setUsedFacilities] = useState<number>(0);
  const [totalUsedAmount, setTotalUsedAmount] = useState<number>(0);

  useEffect(() => {
    if (!initialStateValues || !initialStateValues.length) {
      return;
    }
    unregister(formKey);
    // Use initialStateValues to set the initial state
    const result = initialStateValues.map((o) => {
      const rowLoanFacility: RowLoanFacility = {
        facilityId: o.facilityId,
        amount: o.amount?.toString() || '0',
        name: o.name,
      };
      onAddFacility(rowLoanFacility);
      return rowLoanFacility;
    });

    setTotalUsedAmount(getTotalAmount(result));
    setUsedFacilities(result.length);
  }, [initialStateValues]);

  const formValues = getValues();

  const rowLoanFacilities = Object.keys(
    getNestedProperty(formValues, formKey) || {}
  ).map((selectId) => {
    return { ...getNestedProperty(formValues, formKey)[selectId], selectId };
  });

  const calculateTotalUsedAmount = (
    loanFacilitiesList: RowLoanFacility[] = []
  ) => {
    const amount = getTotalAmount(loanFacilitiesList);
    setTotalUsedAmount(amount);
  };

  const getTotalAmount = (
    loanFacilities: RowLoanFacility[] | LoanFacility[]
  ): number => {
    return loanFacilities?.length
      ? loanFacilities
          .map((f) => parseFloat(f.amount))
          .reduce((a, b = 0) => a + b)
      : 0;
  };
  useEffect(() => {
    setUsedFacilities(rowLoanFacilities?.length || 0);
    calculateTotalUsedAmount(rowLoanFacilities);
  }, [rowLoanFacilities]);

  const initialState = useRef<{
    hasChanged: boolean;
    numberOfLoanFacilities: number;
    loanFacilities: Pick<
      RowLoanFacility,
      'facilityId' | 'name' | 'amount' | 'productEncodedKey'
    >[];
  }>({ hasChanged: false, numberOfLoanFacilities: 0, loanFacilities: [] });

  const availableFacilitiesOptions = useMemo(() => {
    return (
      facilitiesOptions.filter(
        (o) => !rowLoanFacilities?.map((r) => r.facilityId).includes(o.value)
      ) || []
    );
  }, [facilitiesOptions, rowLoanFacilities]);

  const onAddFacility = (loadedRow?: RowLoanFacility) => {
    const selectId = uuid();
    register(`${formKey}.${selectId}.name`);
    register(`${formKey}.${selectId}.amount`, {
      required: FACILITY_MESSAGE.FACILITY_AMOUNT_REQUIRED,
      pattern: {
        value: /^\d*\.?\d*$/i,
        message: FACILITY_MESSAGE.FACILITY_AMOUNT_REQUIRED,
      },
      validate: {
        greaterThanZero: (value) =>
          parseFloat(value) > 0 ||
          `Amount must be greater than ${formatAmount(0, true, currency)}`,

        checkAmountExceededPerAvailableAmount: () => {
          const formValues = getValues();
          const total =
            Object.values(getNestedProperty(formValues, formKey)).reduce(
              (acc: number, data: { amount: string }) =>
                acc + (parseFloat(data?.amount) || 0),
              0
            ) || 0;
          if (
            typeof total === 'number' &&
            +total.toFixed(2) > availableAmountRef.current
          ) {
            return FACILITY_MESSAGE.AMOUNT_EXCEEDED_FOR_DISBURSEMENT_AMOUNT;
          }
        },
        checkAmountExceededPerFacility: () => {
          const rows: RowLoanFacility[] = Object.entries(
            getNestedProperty(getValues(), formKey)
          ).map((r) => r[1]);
          const row = rows.find((r) => r.selectId === selectId);

          const flattenSumFacilities = flattenAndSumFacilities(
            getValues().tranches
          );

          const allocatedSumFacility = flattenSumFacilities.find(
            (f) => f.facilityId === row.facilityId
          );

          const initialAvailableToAllocate =
            facilitiesOptions.find((f) => f.facilityId === row.facilityId)
              ?.initial || 0;

          const leftAmount =
            initialAvailableToAllocate - allocatedSumFacility?.amount || 0;

          if (leftAmount !== undefined && leftAmount < 0)
            return FACILITY_MESSAGE.AMOUNT_EXCEEDED_FOR_FACILITY;
        },
      },
    });

    register(`${formKey}.${selectId}.facilityId`, {
      required: FACILITY_MESSAGE.FACILITY_NAME_REQUIRED,
    });

    if (loadedRow) {
      setValue(`${formKey}.${selectId}.facilityId`, loadedRow.facilityId);
      setValue(`${formKey}.${selectId}.name`, loadedRow.name);
      setValue(`${formKey}.${selectId}.amount`, loadedRow.amount);
    }

    setValue(`${formKey}.${selectId}.selectId`, selectId);
  };

  const onDeleteLoanFacility = (row: RowLoanFacility) => {
    clearErrors(`${formKey}.${row.selectId}.facilityId`);
    clearErrors(`${formKey}.${row.selectId}.amount`);
    clearErrors(`${formKey}.${row.selectId}.name`);
    initialState.current.hasChanged = true;
    removeRow(row.selectId);
  };

  const clearExceededAmountErrors = (rows: RowLoanFacility[] = []) => {
    // Clear errors for all inputs with the 'value' type, exceeded amount
    rows.forEach((r) => {
      const error = getNestedProperty(errors, formKey)?.[r.selectId]?.amount;
      if (error && error.type === 'value') {
        clearErrors(`${formKey}.${r.selectId}.amount`);
      }
    });
  };

  const removeRow = (selectId: RowLoanFacility['selectId']) => {
    const filteredRows = rowLoanFacilities.filter((o: RowLoanFacility) => {
      if (o.selectId === selectId) {
        unregister(`${formKey}.${selectId}`);
      }
      return o.selectId !== selectId;
    });
    setValue(
      `${formKey}`,
      Object.fromEntries(filteredRows.map((row) => [row.selectId, row]))
    );
    setUsedFacilities(filteredRows?.length || 0);
    calculateTotalUsedAmount(filteredRows);
    const updatedTotalUsedAmount = getTotalAmount(filteredRows);
    if (updatedTotalUsedAmount <= availableAmountRef.current) {
      clearExceededAmountErrors(filteredRows);
    }
  };

  const handleSetLoanFacilityValue = async (
    facilityId: Facility['_id'],
    selectId: RowLoanFacility['selectId']
  ) => {
    if (!facilityId || !selectId) {
      return;
    }
    setUsedFacilities(rowLoanFacilities?.length || 0);
    setValue(`${formKey}.${selectId}.facilityId`, facilityId);
    const facilityOption = facilitiesOptions.find(
      (facility) => facility.facilityId === facilityId
    );
    setValue(`${formKey}.${selectId}.name`, facilityOption.name);

    clearErrors(`${formKey}.${selectId}.facilityId`);
    await trigger(formKey);
  };

  const handleAmountChange = useCallback(
    async (row: RowLoanFacility, amount: string, rowLoanFacilities) => {
      const selectId = row.selectId;
      let amountNumber = '0';
      if (amount) {
        amountNumber = amount?.toString() ?? '0';
        setValue(`${formKey}.${selectId}.amount`, amountNumber);
      } else {
        setValue(`${formKey}.${selectId}.amount`, undefined);
      }
      const rowsNewState: RowLoanFacility[] = rowLoanFacilities.map(
        (facility: RowLoanFacility) => {
          if (facility.selectId === selectId) {
            return { ...facility, amount: amountNumber };
          }
          return facility;
        }
      );
      setValue(
        `${formKey}`,
        Object.fromEntries(rowsNewState.map((row) => [row.selectId, row]))
      );
      clearErrors(`${formKey}.${selectId}.amount`);
      if (!amountNumber) {
        setError(`${formKey}.${selectId}.amount`, {
          type: 'required',
          message: FACILITY_MESSAGE.FACILITY_AMOUNT_REQUIRED,
        });
      }
      calculateTotalUsedAmount(rowsNewState);
      await trigger(formKey);
    },
    [clearErrors, availableAmount, setError, setValue, errors]
  );

  const getAvailableToAllocateAmountForFacility = (id: string): number => {
    const facilityOption = facilitiesOptions?.find((f) => f.facilityId === id);
    return parseFloat(facilityOption?.amount) || 0;
  };

  const getAvailableToAllocateLabel = (facilityId: string) => {
    if (!facilityId) return '';
    return `Available to allocate ${formatAmount(
      getAvailableToAllocateAmountForFacility(facilityId),
      true,
      currency
    )}`;
  };

  return (
    <div>
      <div className="flex flex-col">
        <div className="w-full" key={rowLoanFacilities?.length}>
          {!!rowLoanFacilities?.length && (
            <div className="flex flex-col mb-3">
              {rowLoanFacilities?.length && (
                <div className="flex justify-between items-top">
                  <label className={`heading-100 ${elementWidth} mt-1`}>
                    Facility
                  </label>
                  <label className={`heading-100 ${elementWidth} mt-1 ml-4`}>
                    Amount
                  </label>
                </div>
              )}
              {rowLoanFacilities.map((row) => (
                <div key={row?.selectId}>
                  <div className={'flex items-top justify-between h-[90px]'}>
                    <Select
                      placeholder="Select a facility"
                      disabled={disabled}
                      label=" "
                      options={
                        facilitiesOptions.filter(
                          (o) =>
                            o.value === row.facilityId ||
                            !rowLoanFacilities
                              .map((r) => r.facilityId)
                              .includes(o.value)
                        ) || []
                      }
                      value={row?.facilityId}
                      onChange={(e) =>
                        handleSetLoanFacilityValue(e.target.value, row.selectId)
                      }
                      error={
                        getNestedProperty(errors, formKey)?.[row.selectId]
                          ?.facilityId?.message
                      }
                      errorBottomPosition={
                        getNestedProperty(errors, formKey)?.[row.selectId]
                          ?.facilityId?.type === 'value' && '-bottom-3'
                      }
                      className={`${elementWidth} mt-1 max-h-[70px]`}
                    />

                    <FormInput
                      label=" "
                      disabled={disabled}
                      currencyInput={currency}
                      containerClassName={`${elementWidth} mt-1 ${
                        row.facilityId ? '' : 'max-h-[85px]'
                      }`}
                      bottomLabel={getAvailableToAllocateLabel(row.facilityId)}
                      value={row?.amount || ''}
                      onValueChange={(value) =>
                        handleAmountChange(row, value, rowLoanFacilities)
                      }
                      errors={
                        getNestedProperty(errors, formKey)?.[row?.selectId]
                          ?.amount?.message
                      }
                    />
                    {!disabled && (
                      <Button
                        icon={<SvgIcon name="DeleteIcon" />}
                        layout="link"
                        className="h-[50px] absolute -right-10 mt-1"
                        onClick={() => onDeleteLoanFacility(row)}
                        aria-label="Remove facility"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}

          {!!availableFacilitiesOptions?.length && !disabled && (
            <div
              className="text-secondary-400 cursor-pointer flex items-center font-normal text-xs"
              onClick={() => {
                onAddFacility();
              }}
            >
              {`+ Add ${rowLoanFacilities?.length ? 'another' : ''} facility`}
            </div>
          )}
        </div>
      </div>
      <div className="w-full mt-6 mb-4">
        <div className="flex items-start justify-between">
          <div className={`flex flex-col ${elementWidth}`}>
            <span className="body-200 text-neutral-700 mb-2">
              Total facilities selected
            </span>
            <span className="text-sm/[11px]  mr-2">
              {usedFacilities} of {facilitiesOptions?.length || 0}
            </span>
          </div>
          <div className={`flex flex-col ${elementWidth}`}>
            <span className="body-200 text-neutral-700 mb-2">
              Allocated amount
            </span>
            <span className="text-sm/[11px] mr-2 whitespace-nowrap">
              {formatAmount(totalUsedAmount || 0)} of{' '}
              {formatAmount(availableAmountRef.current || 0)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

EditTranchesFacilitiesForm.displayName = 'EditTranchesFacilitiesForm';

export { EditTranchesFacilitiesForm };
