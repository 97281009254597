import { useRef, useState } from 'react';
import InfoIcon from 'kennek/icons/svg/InfoIcon';
import { Button, Dialog } from 'ui';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useGetUserByEmailQuery } from '@/services/accounts';
import { useGetOnboardingTaskStatusesQuery } from '@/services/accounts/borrower';
import { useGetCompanyByIdQuery } from '@/services/originator';
import { useGetBorrowerCompanyByIdQuery } from '@/services/originator/borrower-company';
import { useAppDispatch } from '@/store/hooks';
import { useSelectUser } from '@/store/user/selectors';
import {
  CompanyInformationForm,
  CompanyInformationProps,
  mapDtoToForm,
} from './CompanyInformationForm';
import {
  onContinueWithExistingCompany,
  useSelectCompanyForm,
} from './onboardingSlice';

type ContainerProps = Pick<
  CompanyInformationProps,
  'onBack' | 'onContinue' | 'isReview' | 'showInitialValuesForm'
> & {
  borrowerEmail: string;
  companyNumber: string;
  loanId?: string;
  borrowerCompanyExternalId: string;
};

const CompanyInformationContainer = ({
  borrowerEmail,
  companyNumber,
  borrowerCompanyExternalId,
  loanId,
  ...props
}: ContainerProps) => {
  const { borrowerLower } = useGetLabelsConfig();
  const { data: onboardingTasks = [] } = useGetOnboardingTaskStatusesQuery(
    {
      loanId: loanId ?? undefined,
      borrowerEmail,
    },
    { skip: !borrowerEmail }
  );

  const dispatch = useAppDispatch();

  const isEdit =
    onboardingTasks?.find(({ name }) => name === 'COMPANY')?.status ===
    'COMPLETED';

  const [reuseCompanyModalOpened, setReuseCompanyModalOpened] = useState(false);
  const borrowerCompanyExternalIdRef = useRef(null);
  const originatorExternalIdRef = useRef(null);

  const { data: companyDto, ...companyQuery } = useGetCompanyByIdQuery(
    companyNumber,
    { skip: !companyNumber || !isEdit }
  );

  const { data: borrowerUser, ...userQuery } = useGetUserByEmailQuery(
    { email: borrowerEmail },
    { skip: !borrowerEmail }
  );

  const { data: editCompanyInfo, ...companyQueryEdit } =
    useGetBorrowerCompanyByIdQuery(
      {
        id: borrowerUser?.borrowerCompanyIds.at(-1),
      },
      {
        skip: !borrowerUser?.borrowerCompanyIds.at(-1),
      }
    );

  const user = useSelectUser();

  const { data: borrower, ...borrowerQuery } = useGetUserByEmailQuery(
    {
      email: borrowerEmail,
      mambuBranchEncodedKey: user?.mambuUser?.[0]?.mambuBranchEncodedKey,
    },
    { skip: !borrowerEmail || !user?.mambuUser?.[0]?.mambuBranchEncodedKey }
  );

  const companyForm = useSelectCompanyForm();

  const openReuseCompanyModal = (
    borrowerCompanyExternalId: string,
    originatorExternalId: string
  ) => {
    borrowerCompanyExternalIdRef.current = borrowerCompanyExternalId;
    originatorExternalIdRef.current = originatorExternalId;
    setReuseCompanyModalOpened(true);
  };

  const initialFormValues =
    companyForm || editCompanyInfo
      ? companyForm || editCompanyInfo
      : companyDto
        ? mapDtoToForm(companyDto)
        : undefined;

  if (
    companyQuery.isFetching ||
    borrowerQuery.isFetching ||
    companyQueryEdit.isFetching ||
    userQuery.isFetching
  )
    return (
      <div className="flex justify-center items-center">
        <LoadingAnimationPlat fitBox={!props.isReview} />
      </div>
    );

  return (
    <>
      <CompanyInformationForm
        isEdit={isEdit}
        borrower={borrower}
        initialFormValues={initialFormValues}
        borrowerCompanyExternalId={borrowerCompanyExternalId}
        openReuseCompanyModal={openReuseCompanyModal}
        {...props}
      />

      <Dialog
        open={reuseCompanyModalOpened}
        onClose={() => setReuseCompanyModalOpened(false)}
        dialogIcon={<InfoIcon />}
        dialogIconColor="warning"
      >
        <div className="w-96">
          <p className="body-500-semibold mb-4">
            This {borrowerLower} already exists in the system. Do you want to
            keep the same contact information?
          </p>
          <Button
            className="w-50 text-secondary-400"
            layout="transparent"
            onClick={() => setReuseCompanyModalOpened(false)}
          >
            Cancel
          </Button>
          <Button
            className="w-50 text-secondary-400"
            layout="transparent"
            onClick={() => {
              dispatch(
                onContinueWithExistingCompany({
                  borrowerCompanyExternalId:
                    borrowerCompanyExternalIdRef.current,
                  originatorExternalId: originatorExternalIdRef.current,
                })
              );
              setReuseCompanyModalOpened(false);
              // allow form to reload so onboarding flow can be changed
              setTimeout(() => {
                props.onContinue();
              }, 0);
            }}
          >
            Use existing contact
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export { CompanyInformationContainer };
