import { UserTypes } from 'kennek/interfaces/accounts';
import { Loan } from 'kennek/interfaces/loans';

export interface CustomInformationFields {
  key: string;
  value: string;
  visibility: UserTypes[];
}

export interface CustomInformationResponse {
  data: CustomInformationFields[];
  loanId?: string;
}

export interface CustomInformationProps {
  onContinue: () => void;
  onBack: () => void;
  loanId: Loan['id'];
  branchEncodedKey: string;
  isRestructure?: boolean;
}

export enum CustomInformationPageIds {
  LeaseEstate = 'LeaseEstate',
  ThinkProperty = 'ThinkProperty',
  FailedPage = 'FailedPage',
}
