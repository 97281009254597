import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SvgIcon } from 'kennek/icons';
import { Alert, Button, FormInput, Title } from 'ui';
import { DevTool } from '@hookform/devtools';
import { LOAN_MESSAGES } from '@/constants/onboarding';
import { SNACKBAR_ONBOARDING } from '@/constants/snackbar-messages';
import { onAdditionalDetailsSaved } from '@/features/edit-loan/editLoanSlice';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useSnackbar } from '@/hooks/useSnackbar';
import {
  useCreateLoanMutation,
  useUpdateLoanMutation,
  useUploadRepaymentScheduleMutation,
} from '@/services/loans';
import { isFetchBaseQueryError } from '@/services/utils';
import { useAppDispatch } from '@/store/hooks';
import { base64ToFile } from '@/utils/helpers';
import {
  onEnterAdditionalDetails,
  onExitAdditionalDetails,
  onLoanCreated,
  onLoanUpdated,
  onSetDraftLoan,
  useSelectDraftLoan,
  useSelectLoanInformationForm,
} from '../onboardingSlice';
import { ScheduleFormType } from './ScheduleForm';
import { CreateLoan, Loan, LoanProductRules } from '@/interfaces/loans';

export type AdditionalDetailsFormType = {
  gracePeriod: number;
  graceAmount: number;
  penaltyRate: number;
};

export type AdditionalDetailsProps = {
  onContinue: () => void;
  onBack: (arg?: AdditionalDetailsFormType) => void;
  loanProductRules: LoanProductRules;
  isEdit?: boolean;
  loan?: Loan;
  onChangeLoanId?: (loanId: string) => void;
  initialFormValues: AdditionalDetailsFormType;
  getPayload?: (value: { form: AdditionalDetailsFormType }) => CreateLoan;
  formFlow: 'ONBOARDING' | 'REFINANCE';
  setIsSubmittingForm: (val: boolean) => void;
  scheduleForm?: ScheduleFormType;
};

const AdditionalDetailsForm = ({
  onBack,
  onContinue,
  isEdit,
  loan,
  initialFormValues,
  onChangeLoanId,
  loanProductRules,
  getPayload,
  formFlow,
  setIsSubmittingForm,
  scheduleForm,
}: AdditionalDetailsProps) => {
  const dispatch = useAppDispatch();
  const { loanLower } = useGetLabelsConfig();
  const snackbar = useSnackbar();

  const [createLoan, { isLoading: isCreatingLoan }] = useCreateLoanMutation();
  const [updateLoan, { isLoading: isUpdatingLoan }] = useUpdateLoanMutation();

  const [uploadRepaymentSchedule] = useUploadRepaymentScheduleMutation();
  const loanInformationForm = useSelectLoanInformationForm();
  const draftLoanData = useSelectDraftLoan();
  const [error, setErrors] = useState<boolean>(false);

  const { rules, variableRequiredFields } = loanProductRules ?? {};

  const form = useForm<AdditionalDetailsFormType>({
    shouldFocusError: false,
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  const isDirtyRef = useRef(form.formState.isDirty);

  useLayoutEffect(() => {
    isDirtyRef.current = form.formState.isDirty;
  }, [form.formState.isDirty]);

  useEffect(() => {
    dispatch(onEnterAdditionalDetails());
    return () => {
      dispatch(
        onExitAdditionalDetails({
          form: form.getValues(),
          isDirty: isDirtyRef.current,
        })
      );
    };
  }, [dispatch, form, form.getValues]);

  const isSubmitting = isCreatingLoan || isUpdatingLoan;
  useEffect(() => {
    setIsSubmittingForm(isSubmitting);
  }, [isSubmitting, setIsSubmittingForm]);

  const handleRepaymentScheduleUpload = async (
    loanId: string
  ): Promise<boolean> => {
    const uploadedFile = scheduleForm?.importedSchedule;
    if (!uploadedFile) return true;
    try {
      const file = base64ToFile(
        uploadedFile?.base64File,
        uploadedFile.fileName,
        uploadedFile.type
      );
      if (!file) return false;
      const loanAmount = isEdit
        ? loan?.loanAmount
        : loanInformationForm?.loanAmount;
      const data = new FormData();
      data.append('file', file, uploadedFile.fileName);
      data.append('loanAmount', loanAmount?.toString());
      data.append(
        'repaymentInstallments',
        scheduleForm?.repaymentInstallments?.toString()
      );
      data.append('loanId', loanId);

      return uploadRepaymentSchedule(data)
        .unwrap()
        .then(() => {
          return true;
        })
        .catch((e) => {
          const isFeesErr =
            e?.data?.message[0]?.errorReason === 'FEE_NOT_ALLOWED';
          snackbar.show({
            severity: 'error',
            title: isFeesErr
              ? SNACKBAR_ONBOARDING.LOAN_IMPORT_SCHEDULE_FAILED_FEES
              : SNACKBAR_ONBOARDING.LOAN_IMPORT_SCHEDULE_FAILED,
          });
          return false;
        });
    } catch (e) {
      return false;
    }
  };

  const onSubmit = form.handleSubmit((loanRepaymentsForm) => {
    const payload = getPayload?.({
      form: loanRepaymentsForm,
    });

    if (formFlow === 'ONBOARDING') {
      if (!isEdit && !draftLoanData) {
        createLoan(payload)
          .unwrap()
          // TODO: add type to `data`
          .then(async (data: Loan) => {
            dispatch(onSetDraftLoan(data));
            snackbar.show({
              severity: 'primary',
              title: SNACKBAR_ONBOARDING.LOAN_CREATION_TITLE,
            });
            const isScheduleUploaded = await handleRepaymentScheduleUpload(
              data.id
            );
            if (!isScheduleUploaded) return;
            onChangeLoanId(data.id);
            dispatch(onLoanCreated());
            onContinue();
          })
          .catch((error) => {
            // This condition is validating against Mambu. BE should handle our own errors.
            // Should refactor this after BE implements our status codes.
            if (
              isFetchBaseQueryError(error) &&
              error.status === 400 &&
              hasMambuMessage(error.data) &&
              (error.data.message?.[0]?.errorReason ===
                'DISBURSEMENT_DATE_AFTER_LAST_REPAYMENT_DUE_DATE' ||
                error.data.message?.[0]?.errorReason ===
                  'INVALID_DISBURSEMENT_DATE')
            ) {
              setErrors(true);
            } else {
              snackbar.show({
                severity: 'error',
                title: SNACKBAR_ONBOARDING.LOAN_CREATION_FAILED_TITLE,
              });
            }
          });
      } else {
        updateLoan({
          ...payload,
          id: loan?.encodedKey || draftLoanData?.encodedKey,
        })
          .unwrap()
          .then(async () => {
            const isScheduleUploaded = await handleRepaymentScheduleUpload(
              loan?.id
            );
            if (!isScheduleUploaded) return;
            snackbar.show({
              severity: 'primary',
              title: SNACKBAR_ONBOARDING.LOAN_UPDATE_TITLE,
            });
            dispatch(onLoanUpdated());
            onContinue();
          })
          .catch((error) => {
            // This condition is validating against Mambu. BE should handle our own errors.
            // Should refactor this after BE implements our status codes.
            if (
              isFetchBaseQueryError(error) &&
              error.status === 400 &&
              hasMambuMessage(error.data) &&
              (error.data.message?.[0]?.errorReason ===
                'DISBURSEMENT_DATE_AFTER_LAST_REPAYMENT_DUE_DATE' ||
                error.data.message?.[0]?.errorReason ===
                  'INVALID_DISBURSEMENT_DATE')
            ) {
              setErrors(true);
            } else {
              snackbar.show({
                severity: 'error',
                title: SNACKBAR_ONBOARDING.LOAN_UPDATE_FAILED_TITLE,
              });
            }
          });
      }
    } else if (formFlow === 'REFINANCE') {
      dispatch(onAdditionalDetailsSaved(loanRepaymentsForm));
      onContinue();
    }
  });

  return (
    <div className="flex flex-col gap-8">
      <Title
        title="Additional details"
        icon={<SvgIcon name="StatementIcon" />}
        titleSize="lg"
        className="mt-2 mb-1"
      />
      <form
        className="form stepper-form"
        onSubmit={(e) => e.preventDefault()}
        autoComplete="off"
        noValidate
      >
        <div className="form-input">
          <FormInput
            label="Days Grace period"
            errors={form.formState.errors?.gracePeriod?.message}
            questionTooltip="The period of time after payment is due, but before late fees, interest, or other penalties start to accrue"
            placeholder="Days"
            type="number"
            defaultValue={
              rules?.gracePeriod.defaultValue !== form.watch('gracePeriod') &&
              rules?.gracePeriod.defaultValue
            }
            defaultValueSetter={() => {
              form.setValue('gracePeriod', rules?.gracePeriod?.defaultValue);
              form.clearErrors('gracePeriod');
            }}
            {...form.register('gracePeriod', {
              valueAsNumber: true,
              // required: LOAN_MESSAGES.REQUIRED_GRACE_PERIOD, // ! Restore after bug LL-3568 fix by mambu
              max: {
                value: rules?.gracePeriod?.maxValue
                  ? rules?.gracePeriod?.maxValue
                  : undefined,
                message: LOAN_MESSAGES.MAX_VALUE_GRACE_PERIOD(
                  rules?.gracePeriod?.maxValue
                ),
              },
              min: {
                value: rules?.gracePeriod?.minValue
                  ? rules?.gracePeriod?.minValue
                  : 0,
                message: LOAN_MESSAGES.MIN_VALUE_GRACE_PERIOD(
                  rules?.gracePeriod?.minValue || 0
                ),
              },
            })}
          />

          <FormInput
            label="Grace Amount %"
            type="number"
            leftSymbol="%"
            questionTooltip={`% of Outstanding Principal above which ${loanLower} is deemed in arrears`}
            placeholder="Percentage amount"
            errors={form.formState.errors?.graceAmount?.message}
            defaultValue={
              rules?.graceAmount.defaultValue !== form.watch('graceAmount') &&
              rules?.graceAmount.defaultValue
            }
            defaultValueSetter={() => {
              form.setValue('graceAmount', rules?.graceAmount?.defaultValue);
              form.clearErrors('graceAmount');
            }}
            {...form.register('graceAmount', {
              valueAsNumber: true,
              // required: LOAN_MESSAGES.REQUIRED_GRACE_AMOUNT, // ! Restore after bug LL-3568 fix by mambu
              max: {
                value: rules?.graceAmount?.maxValue
                  ? rules?.graceAmount?.maxValue
                  : undefined,
                message: LOAN_MESSAGES.MAX_VALUE_GRACE_AMOUNT(
                  rules?.graceAmount?.maxValue
                ),
              },
              min: {
                value: rules?.graceAmount?.minValue
                  ? rules?.graceAmount?.minValue
                  : 0,
                message: LOAN_MESSAGES.MIN_VALUE_GRACE_AMOUNT(
                  rules?.graceAmount?.minValue || 0
                ),
              },
            })}
          />

          {variableRequiredFields?.some(
            (field) => field.name === 'penaltyRate'
          ) && (
            <FormInput
              label="Penalty rate"
              leftSymbol="%"
              type="number"
              errors={form.formState.errors?.penaltyRate?.message}
              defaultValue={
                rules?.penalty?.defaultValue !== form.watch('penaltyRate') &&
                rules?.penalty?.defaultValue
              }
              defaultValueSetter={() => {
                form.setValue('penaltyRate', rules?.penalty?.defaultValue);
                form.clearErrors('penaltyRate');
              }}
              {...form.register('penaltyRate', {
                valueAsNumber: true,
                required: LOAN_MESSAGES.REQUIRED_PENALTY_RATE,
                max: {
                  value: rules?.penalty?.maxValue
                    ? rules?.penalty?.maxValue
                    : undefined,
                  message: LOAN_MESSAGES.MAX_VALUE_PENALTY_RATE(
                    rules?.penalty?.maxValue
                  ),
                },
                min: {
                  value: rules?.penalty?.minValue
                    ? rules?.penalty?.minValue
                    : 0,
                  message: LOAN_MESSAGES.MIN_VALUE_PENALTY_RATE(
                    rules?.penalty?.minValue || 0
                  ),
                },
              })}
            />
          )}
        </div>
        {error && (
          <div className="w-full">
            <Alert type="error" className="w-full">
              <span className="body-400 mt-1">
                One or more tranches have a disbursement date after the final
                payment is due. Review and edit the dates before continuing.
              </span>
            </Alert>
          </div>
        )}

        <div className="flex justify-between">
          <Button
            layout="ghost"
            onClick={() =>
              onBack(formFlow === 'REFINANCE' ? form.getValues() : null)
            }
            disabled={isSubmitting}
          >
            Back
          </Button>

          <Button
            layout="primary"
            onClick={onSubmit}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {formFlow === 'ONBOARDING' ? 'Save and continue' : 'Continue'}
          </Button>
        </div>
      </form>

      <DevTool control={form.control} />
    </div>
  );
};

type MambuErrorObject = { errorCode: number; errorReason: string };

const hasMambuMessage = (
  value: unknown
): value is { message: MambuErrorObject[] } => {
  return !!(value as { message: MambuErrorObject[] })?.message?.[0]
    ?.errorReason;
};

export { AdditionalDetailsForm };
