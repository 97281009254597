import * as React from 'react';
import { useContext } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { ROUTES } from '@/constants/routes';
import useDirectDebitFeatures from '@/hooks/useDirectDebitFeatures';
import useGetUserIdentity from '@/hooks/useGetUserIdentity';
import { getRouterQuery } from '@/utils/helpers';
import { LoanSummaryContext } from './ContextWrapper';
import { DirectDebitMandateStatus } from '@/interfaces/loans/queries';
import { DirectDebitProps } from '@/interfaces/next-payment';

const inactiveProp: DirectDebitProps = {
  text: 'Inactive',
  color: 'text-neutral-700',
};

const directDebitMap: Record<DirectDebitMandateStatus, DirectDebitProps> = {
  UNDER_REVIEW: { text: 'Under review', color: 'text-warning-500' },
  ACTIVE: { text: 'Active', color: 'text-neutral-600' },
  INACTIVE: inactiveProp,
  SUSPENDED: inactiveProp,
  REJECTED: inactiveProp,
  CANCELLED: { text: 'Disabled', color: 'text-neutral-600' },
};

const DirectDebitStateText: React.FC = () => {
  const router = useRouter();
  const { isBorrower, isOriginator } = useGetUserIdentity();

  const { nextPaymentData, directDebitMandateStatus, loanId } =
    useContext(LoanSummaryContext);

  const canUseDirectDebitFeatures = useDirectDebitFeatures(
    nextPaymentData?.directDebitAccountId
  );

  if (
    !canUseDirectDebitFeatures ||
    (isOriginator &&
      nextPaymentData?.customerId &&
      !nextPaymentData?.directDebitAccountId)
  ) {
    return null;
  }

  if (isBorrower && directDebitMandateStatus === 'INACTIVE') {
    const path = getRouterQuery(ROUTES.DIRECT_DEBIT, { loanId });
    return (
      <button
        className="heading-200 text-secondary-400"
        onClick={() => router.push(path)}
      >
        Activate Direct Debit
      </button>
    );
  }

  return (
    <>
      <p className="flex items-center mt-4">
        <span className="body-400 text-neutral-700">Direct Debit: </span>
        <span
          className={classNames(
            'heading-200 ml-1 font-medium',
            directDebitMap[directDebitMandateStatus].color
          )}
        >
          {directDebitMap[directDebitMandateStatus].text}
        </span>
      </p>
      {directDebitMandateStatus === 'UNDER_REVIEW' && (
        <p className="text-neutral-600 body-200 mt-2">
          The review process could take up to 4 days
        </p>
      )}
    </>
  );
};

export default DirectDebitStateText;
