import useGetLabelsConfig from './useGetLabelsConfig';
import { AmountToFundType } from '@/interfaces/loans';

const useLoanOptions = () => {
  const { loanUpper } = useGetLabelsConfig();

  const amountToFundOptions = [
    {
      label: `Gross ${loanUpper} amount`,
      value: AmountToFundType.GROSS,
    },
    {
      label: `Net ${loanUpper} amount`,
      value: AmountToFundType.NET,
    },
  ];

  return {
    amountToFundOptions,
  };
};

export default useLoanOptions;
