import React from 'react';
import type { Row } from 'react-table';
import classNames from 'classnames';
import { SvgIcon } from 'kennek/icons';
import { Table } from 'ui';
import { ChevronDownIcon } from '@heroicons/react/outline';
import * as Dialog from '@radix-ui/react-dialog';
import {
  addFees,
  findFeeInRules,
  getFeeFromRules,
} from '@/features/onboarding/utils';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { formatAmount, formatDate } from '@/utils/formatters';
import { Column } from 'ui/types';
import { LoanProductRules, Tranche } from '@/interfaces/loans';

interface TranchesSummaryModalProps {
  tranches: Tranche[];
  rules: LoanProductRules;
  editable: boolean;
  open: boolean;
  onClose: () => void;
  onEdit: () => void;
}

const TranchesSummaryModal = ({
  tranches,
  editable,
  rules,
  open,
  onClose,
  onEdit,
}: TranchesSummaryModalProps) => {
  const tranchesColumns = getColumns(rules);

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-[100] bg-neutral-900 bg-opacity-60 overflow-y-auto flex justify-center items-center">
          <Dialog.Content className="max-w-2xl">
            <Table.Container variant="compact" withoutHeaderOffset>
              <Table.Header noBorder>
                <Table.Header.Left>
                  <Table.Name
                    title="Disbursement tranches"
                    style="heading-300"
                  />
                </Table.Header.Left>

                <Table.Header.Right>
                  {editable && (
                    <button
                      type="button"
                      className="heading-200 text-secondary-400"
                      onClick={onEdit}
                    >
                      Edit tranches
                    </button>
                  )}

                  <Dialog.Close className="p-2">
                    <SvgIcon name="CloseIcon" />
                  </Dialog.Close>
                </Table.Header.Right>
              </Table.Header>

              <Table.Body
                accordionElements={<AccordionFees rules={rules} />}
                columns={tranchesColumns as Column<object>[]}
                data={tranches ?? []}
                emptyTableMessage="No tranches available"
                maxHeight
                maxRows={tranches?.length < 6 ? tranches?.length : 6}
                noPaddingTop
                expandable
              />
            </Table.Container>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

interface AccordionFeesProps {
  row?: Row<Tranche>;
  rules: LoanProductRules;
}

const AccordionFees = ({ row, rules }: AccordionFeesProps) => {
  const { loanUpper } = useGetLabelsConfig();
  return (
    <div className="body-200 -mt-2 w-full p-3 pr-9">
      <div className="flex justify-between mt-2 w-full">
        <span>{loanUpper} amount</span>
        {formatAmount(row?.original?.amount, true, rules?.currency)}
      </div>
      {row?.original?.fees?.map((fee) => {
        const feeFound = findFeeInRules(rules, fee.predefinedFeeEncodedKey);
        return (
          <div
            key={fee.predefinedFeeEncodedKey}
            className="flex justify-between mt-2"
          >
            <span>{feeFound?.name}</span>
            {formatAmount(
              fee?.amount ||
                getFeeFromRules(
                  fee.predefinedFeeEncodedKey,
                  row?.original?.amount,
                  rules
                ),
              true,
              rules?.currency
            )}
          </div>
        );
      })}
      {row?.original?.counterparty && (
        <div className="flex justify-between mt-2 w-full">
          <span>Counterparty account</span>
          {row?.original?.counterparty?.bankAccountHolderName} (
          {row?.original?.counterparty?.bankAccountNumber})
        </div>
      )}
    </div>
  );
};

const getColumns = (rules: LoanProductRules): Column<Tranche>[] => [
  {
    Header: 'Tranche',
    id: 'index',
    Cell: ({ cell }) => `Tranche ${cell.row.index + 1}`,
    minWidth: 100,
    maxWidth: 100,
  },
  {
    Header: 'Disbursement date',
    accessor: 'disbursementDate',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
    alignRight: true,
    Cell: ({ cell: { value } }) => formatDate(value),
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    alignRight: true,
    maxWidth: 170,
    minWidth: 170,
    Cell: ({
      cell: {
        row: { original },
      },
    }) =>
      formatAmount(
        addFees(original.fees, original.amount, rules),
        true,
        rules?.currency
      ),
  },
  {
    id: 'expander',
    Header: '',
    maxWidth: 50,
    minWidth: 50,
    Cell: ({ row }: { row: Row<Tranche> }) => (
      <ChevronDownIcon
        {...row?.getToggleRowExpandedProps()}
        className={classNames('w-4 h-4 rounded-full transition-all', {
          'rotate-180': row.isExpanded,
        })}
      />
    ),
  },
];

export default TranchesSummaryModal;
