import React, { useEffect, useState } from 'react';
import { Button } from 'ui';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { LeaseEstate } from '@/components/loans/custom-information/lease-state';
import { ThinkProperty } from '@/components/loans/custom-information/think-property';
import { SNACKBAR_ONBOARDING } from '@/constants/snackbar-messages';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useGetLoanOnboardingCustomInformationPageIdQuery } from '@/services/kennek';
import { useGetCustomInformationsQuery } from '@/services/loans';
import { CustomInformationPageIds, CustomInformationProps } from './utils';

export const CustomInformation = ({
  onContinue,
  onBack,
  loanId,
  branchEncodedKey,
}: CustomInformationProps) => {
  const [page, setPage] = useState<CustomInformationPageIds>(null);
  const snackbar = useSnackbar();
  const { data: pageToRender, ...pageToRenderQuery } =
    useGetLoanOnboardingCustomInformationPageIdQuery(
      { branchEncodedKey: branchEncodedKey },
      { skip: !branchEncodedKey }
    );

  const { data: customInformationData, ...customInformationQuery } =
    useGetCustomInformationsQuery(loanId, { skip: !loanId });

  useEffect(() => {
    if (pageToRenderQuery?.isError || customInformationQuery?.isError) {
      snackbar.show({
        severity: 'error',
        title: SNACKBAR_ONBOARDING.CUSTOM_INFORMATION_FAILED_GET,
      });
      setPage(CustomInformationPageIds.FailedPage);
    } else setPage(pageToRender?.pageId);
  }, [
    customInformationQuery?.isError,
    pageToRenderQuery?.isError,
    pageToRender?.pageId,
  ]);

  if (pageToRenderQuery?.isLoading || customInformationQuery?.isLoading)
    return <LoadingAnimationPlat />;

  const renderFailingContentMessage = (header: string) => {
    return (
      <div className="flex flex-col items-center justify-center text-center relative">
        <span className="heading-500 text-neutral-500">{header}</span>
        <div className="absolute bottom-0 right-0">
          <Button onClick={onContinue}>Continue</Button>
        </div>
      </div>
    );
  };
  switch (page) {
    case CustomInformationPageIds.LeaseEstate:
      return (
        <LeaseEstate
          onBack={onBack}
          onContinue={onContinue}
          loanId={loanId}
          initialValues={customInformationData?.data || []}
          refetch={customInformationQuery.refetch}
        />
      );
    case CustomInformationPageIds.ThinkProperty:
      return <ThinkProperty />;
    case CustomInformationPageIds.FailedPage:
      return renderFailingContentMessage(
        "Couldn't get custom information settings. Click continue if you want to proceed"
      );
    default:
      return renderFailingContentMessage(
        "You haven't specified the page ID or provided page ID does not exist."
      );
  }
};
