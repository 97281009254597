import { useEffect, useState } from 'react';
import { useGetBrandByDomainQuery, useGetBrandQuery } from '@/services/kennek';

const useGetBrandData = (branchEncodedKey?: string) => {
  const [domainName, setDomainName] = useState(null);
  useEffect(() => {
    // Must use useEffect -> prevent hydration error
    if (typeof window !== 'undefined') setDomainName(window.location?.host);
  }, []);

  const {
    currentData: branDataByBranchKey,
    isLoading: isLoadingBranchKey,
    isError: isErrorBranchKey,
  } = useGetBrandQuery({ branchEncodedKey }, { skip: !branchEncodedKey });

  const {
    currentData: branDataByDomain,
    isLoading: isLoadingDomain,
    isError: isErrorDomain,
  } = useGetBrandByDomainQuery(
    { domainName },
    { skip: !domainName || !!branchEncodedKey }
  );

  return {
    brandData: branDataByBranchKey || branDataByDomain,
    isLoadingBranding: isLoadingBranchKey || isLoadingDomain,
    isErrorBranding: isErrorBranchKey || isErrorDomain,
  };
};

export default useGetBrandData;
