import { Label, OnboardingSummaryItem as SummaryItem } from 'ui';
import { CustomInformationFields } from '../custom-information/utils';

const renderCustomInformationSingleField = (
  filteredFields: CustomInformationFields[]
) => {
  return filteredFields.map((item) => (
    <div key={item.key}>
      <SummaryItem
        topic={`${item.key}:`}
        inline={true}
        customMargin="mb-4"
        content={item.value}
      />
    </div>
  ));
};

export const RoleBasedColumns = (
  mappedCustomInformationData: CustomInformationFields[]
) => {
  const borrowerFields =
    mappedCustomInformationData?.filter(
      (item) =>
        item.visibility.includes('BORROWER') &&
        item.visibility.includes('ORIGINATOR_ADMIN') &&
        item.value
    ) || [];

  const investorFields =
    mappedCustomInformationData?.filter(
      (item) =>
        item.visibility.includes('INVESTOR') &&
        item.visibility.includes('ORIGINATOR_ADMIN') &&
        item.value
    ) || [];

  const originatorFields =
    mappedCustomInformationData?.filter(
      (item) =>
        item.visibility.includes('ORIGINATOR_ADMIN') &&
        !item.visibility.includes('BORROWER') &&
        !item.visibility.includes('INVESTOR') &&
        item.value
    ) || [];

  return (
    <div className="grid grid-cols-3 gap-4 border-t pb-8 border-neutral-300 mt-5">
      <div className="mt-4 p-5">
        <Label>Only visible to you</Label>
        <div className="flex flex-col mt-8">
          {renderCustomInformationSingleField(originatorFields)}
        </div>
      </div>
      <div className="mt-4 p-5">
        <Label>Visible to Borrower</Label>
        <div className="flex flex-col mt-8">
          {renderCustomInformationSingleField(borrowerFields)}
        </div>
      </div>
      <div className="mt-4 p-5">
        <Label>Visible to Investor</Label>
        <div className="flex flex-col mt-8">
          {renderCustomInformationSingleField(investorFields)}
        </div>
      </div>
    </div>
  );
};
