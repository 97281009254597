import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { SNACKBAR_RESTRUCTURE } from '@/constants/snackbar-messages';
import { BalanceForm } from '@/features/edit-loan/EditLoanBalanceForm';
import {
  useCustomInformation,
  useSelectAdditionalDetailsForm,
  useSelectBalanceForm,
  useSelectEditAmountToFundType,
  useSelectEditInvestments,
  useSelectEditLoanFacilitiesStore,
  useSelectLoanInfoForm,
  useSelectScheduleForm,
} from '@/features/edit-loan/editLoanSlice';
import { InvestmentsFormFields } from '@/features/investments/InvestmentsForm/InvestmentsForm.types';
import { CustomInformationFields } from '@/features/onboarding/custom-information/utils';
import { mapFeesToDto } from '@/features/onboarding/loan-details/AdditionalDetailsContainer';
import { AdditionalDetailsFormType } from '@/features/onboarding/loan-details/AdditionalDetailsForm';
import { LoanInformationFormType } from '@/features/onboarding/loan-details/LoanInformationForm';
import { ScheduleFormType } from '@/features/onboarding/loan-details/ScheduleForm';
import { mapAssetsFormInputToAssetDto } from '@/features/onboarding/loanAssets/LoanAssets.utils';
import { useSelectAssetsForm } from '@/features/onboarding/onboardingSlice';
import { useGetLoanPreviewMutation } from '@/services/loans';
import {
  getValueOrDefault,
  safeExtractBase64,
  toFloatOrDefault,
} from '@/utils/helpers';
import { useSnackbar } from './useSnackbar';
import {
  AmountToFundType,
  Asset,
  LoanProductRules,
  NET_LOAN_CALCULATION_METHOD_OPTIONS,
  RestructureLoan,
  VariableRequiredFields,
} from '@/interfaces/loans';
import { LoanFacility, RowLoanFacility } from '@/interfaces/loans/facility';

const useGetLoanPreview = (
  rules?: LoanProductRules,
  loanEncodedKey?: string,
  onlyWithInterestGraceInstallments?: boolean
) => {
  const [loanPreview, setLoanPreview] = useState(null);
  const balanceForm = useSelectBalanceForm();
  const loanInformationForm = useSelectLoanInfoForm();
  const scheduleForm = useSelectScheduleForm();
  const additionalDetailsForm = useSelectAdditionalDetailsForm();
  const storedInvestments = useSelectEditInvestments();
  const { loanFacilities } = useSelectEditLoanFacilitiesStore();
  const assetsFormData = useSelectAssetsForm();
  const amountToFundType = useSelectEditAmountToFundType();
  const customInformation = useCustomInformation();

  const snackbar = useSnackbar();
  const [getLoanPreview, { isLoading }] = useGetLoanPreviewMutation();

  const restructureInfo = useMemo(() => {
    const assets = assetsFormData?.map(mapAssetsFormInputToAssetDto) || [];
    return mapToDto({
      balanceForm,
      loanInformationForm,
      scheduleForm,
      additionalDetailsForm,
      loanProductRules: rules,
      loanFacilities,
      loanInvestments: storedInvestments,
      assets,
      amountToFundType,
      customInformation,
    });
  }, [
    additionalDetailsForm,
    balanceForm,
    storedInvestments,
    loanFacilities,
    loanInformationForm,
    rules,
    scheduleForm,
    assetsFormData,
    amountToFundType,
    customInformation,
  ]);

  const fetchLoanPreviewValuesDebounced = useCallback(
    debounce(() => {
      fetchLoanPreviewValues();
    }, 1000),
    [
      loanEncodedKey,
      rules,
      onlyWithInterestGraceInstallments,
      restructureInfo?.interestGraceInstallments,
    ]
  );

  useEffect(() => {
    const installmentsAllow = onlyWithInterestGraceInstallments
      ? restructureInfo?.interestGraceInstallments
      : true;
    if (loanEncodedKey && rules && installmentsAllow)
      fetchLoanPreviewValuesDebounced();
  }, [
    loanEncodedKey,
    rules,
    onlyWithInterestGraceInstallments,
    restructureInfo?.interestGraceInstallments,
    fetchLoanPreviewValuesDebounced,
  ]);

  const fetchLoanPreviewValues = async () => {
    try {
      const response = await getLoanPreview({
        ...restructureInfo,
        loanId: loanEncodedKey,
        useDocumentManagement: true, // TO DO: KBP-382 TO remove after BE changes
      });
      if (response && 'data' in response && response.data) {
        setLoanPreview(response.data);
      }
    } catch (error) {
      snackbar.show({
        severity: 'error',
        title: SNACKBAR_RESTRUCTURE.CANNOT_GET_DEDUCTED_FEES_VALUE,
      });
    }
  };

  return {
    loanPreview,
    restructureInfo,
    isLoanPreviewLoading: isLoading,
  };
};

const mapToDto = ({
  balanceForm,
  loanInformationForm,
  scheduleForm,
  additionalDetailsForm,
  loanProductRules,
  loanFacilities,
  loanInvestments,
  assets,
  amountToFundType,
  customInformation,
}: {
  balanceForm: BalanceForm;
  loanInformationForm: LoanInformationFormType;
  scheduleForm: ScheduleFormType;
  additionalDetailsForm: AdditionalDetailsFormType;
  loanProductRules: LoanProductRules;
  loanFacilities?: RowLoanFacility[] | LoanFacility[];
  loanInvestments?: InvestmentsFormFields[];
  assets?: Asset[];
  amountToFundType: AmountToFundType;
  customInformation?: CustomInformationFields[];
}): Omit<RestructureLoan, 'id'> => {
  const hasTranches = loanProductRules?.maxNumberOfTranches > 0;
  const variable = loanProductRules?.variableRequiredFields;

  const scheduleFile = safeExtractBase64(
    scheduleForm?.importedSchedule?.base64File
  );

  const formToDto: Omit<RestructureLoan, 'id'> = {
    productTypeKey:
      loanInformationForm?.productTypeKey || balanceForm?.productTypeKey,
    newPrincipal: balanceForm?.principal,
    newBalances: {
      fee: getValueOrDefault(balanceForm?.fee, 0),
      interest: getValueOrDefault(balanceForm?.interest, 0),
      penalty: getValueOrDefault(balanceForm?.penalty, 0),
    },
    disbursementDate: hasTranches ? undefined : scheduleForm?.disbursementDate,
    externalId: loanInformationForm?.externalId,
    fees: mapFeesToDto({ fees: loanInformationForm?.fees, loanProductRules }),
    interestGraceHandleMethod:
      loanProductRules?.interestGraceHandleMethodEnabled
        ? scheduleForm?.interestGraceHandleMethod
        : undefined,
    firstRepaymentDate: scheduleForm?.firstRepaymentDate,
    interestRate: getValueOrDefault(loanInformationForm?.interestRate, 0),
    defaultInterestRate: loanInformationForm?.defaultInterestRate,
    interestCapitalisationFrequency:
      loanInformationForm?.interestCapitalisationFrequency,
    interestGraceInstallments: loanProductRules?.interestGrace
      ? scheduleForm?.interestGraceInstallments
      : 0,
    loanAmount: loanInformationForm?.loanAmount,
    loanName: loanInformationForm?.loanName,
    graceAmount: additionalDetailsForm?.graceAmount,
    gracePeriod: additionalDetailsForm?.gracePeriod,
    monthlyRepaymentDay: getVariableRequiredFieldIfExistsOrUndefined(
      variable,
      'monthlyRepaymentDay',
      scheduleForm?.monthlyRepaymentDay
    ),
    paymentHoliday: loanProductRules?.paymentHoliday
      ? scheduleForm?.paymentHoliday
      : 0,
    penaltyRate: getVariableRequiredFieldIfExistsOrUndefined(
      variable,
      'penaltyRate',
      additionalDetailsForm?.penaltyRate
    ),
    periodicPayment: loanProductRules?.balloonPaymentEnabled
      ? parseFloat(loanInformationForm.periodicPayment)
      : undefined,
    balloonPaymentAmount:
      loanProductRules?.balloonPaymentEnabled &&
      !!loanInformationForm.balloonPaymentAmount
        ? parseFloat(loanInformationForm.balloonPaymentAmount)
        : undefined,
    minimumInterestPeriod: scheduleForm?.minimumInterestPeriod,
    repaymentInstallments: scheduleForm?.repaymentInstallments,
    payCurrentInstallment: scheduleForm?.payCurrentInstallment,
    exitFeeAmount: +loanInformationForm?.exitFeeAmount,
    repaymentPeriodCount: getVariableRequiredFieldIfNotExistsOrUndefined(
      variable,
      'monthlyRepaymentDay',
      scheduleForm?.repaymentPeriodCount
    ),
    repaymentPeriodUnits: getVariableRequiredFieldIfNotExistsOrUndefined(
      variable,
      'monthlyRepaymentDay',
      scheduleForm?.repaymentPeriodUnits
    ),
    netLoanCalculationMethod:
      balanceForm?.netLoanCalculationMethod ||
      loanInformationForm?.netLoanCalculationMethod,
    newFactorAmount: toFloatOrDefault(
      loanInformationForm?.factorAmount?.toString(),
      0
    ),
    rollUpDuration: scheduleForm?.rollUpDuration,
    loanFacilities: hasTranches
      ? mapLoanFacilitiesToDto(loanFacilities)
      : undefined,
    tranches: hasTranches ? mapTranchesToDto(scheduleForm) : undefined,
    investments: loanInvestments?.map((investment) => ({
      amount: parseFloat(investment.amount),
      investorId: investment.investorId,
      lenderOfRecordId: investment.lenderOfRecordId,
      investorInterest: parseFloat(investment.interestRate),
      investmentType: investment.type,
    })),
    assets,
    counterpartyId: scheduleForm?.counterpartyId,
    amountToFundType,
    interestCeiling: loanInformationForm?.interestCeiling,
    interestFloor: loanInformationForm?.interestFloor,
    useInitialInterestRateAsCeiling:
      loanInformationForm?.useInitialInterestRateAsCeiling,
    useInitialInterestRateAsFloor:
      loanInformationForm?.useInitialInterestRateAsFloor,
    customInformation: customInformation,
    repaymentScheduleFile: scheduleFile,
  };
  if (
    NET_LOAN_CALCULATION_METHOD_OPTIONS.LTV ===
    formToDto.netLoanCalculationMethod
  ) {
    formToDto.ltv = balanceForm?.ltv || loanInformationForm?.ltv;
    formToDto.gdv = balanceForm?.gdv || loanInformationForm?.gdv;
  }
  if (
    NET_LOAN_CALCULATION_METHOD_OPTIONS.LTC ===
    formToDto.netLoanCalculationMethod
  ) {
    formToDto.ltc = balanceForm?.ltc || loanInformationForm?.ltc;
    formToDto.gdc = balanceForm?.gdc || loanInformationForm?.gdc;
  }
  return formToDto;
};

const mapLoanFacilitiesToDto = (
  loanFacilities: RowLoanFacility[] | LoanFacility[]
) => {
  return loanFacilities?.length
    ? loanFacilities?.map((f: RowLoanFacility | LoanFacility) => ({
        facilityId: f.facilityId,
        name: f.name,
        amount: parseFloat(f.amount.toString()),
      }))
    : [];
};

const mapTranchesToDto = (scheduleForm: ScheduleFormType) => {
  return scheduleForm?.tranches?.map((tranche) => ({
    facilities: Object.values(tranche?.facilities).map(
      (f: Partial<RowLoanFacility>) => {
        return {
          facilityId: f.facilityId,
          amount: parseFloat(f.amount),
          name: f.name,
        } as Partial<LoanFacility>;
      }
    ),
    amount: tranche.disbursementAmount ? tranche.disbursementAmount : undefined,
    disbursementDate: tranche.disbursementDate,
    fees: Object.entries(tranche?.fees ?? {}).map(
      ([predefinedFeeEncodedKey, content]) => ({
        predefinedFeeEncodedKey,
        amount: content.amount,
      })
    ),
    counterpartyId: tranche.counterpartyId,
  }));
};

const getVariableRequiredFieldIfExistsOrUndefined = <TValue>(
  variables: VariableRequiredFields[],
  searchedName: string,
  sourceValue: TValue
) => {
  return variables?.some(({ name }) => name === searchedName)
    ? sourceValue
    : undefined;
};

const getVariableRequiredFieldIfNotExistsOrUndefined = <TValue>(
  variables: VariableRequiredFields[],
  searchedName: string,
  sourceValue: TValue
) => {
  return !variables?.some(({ name }) => name === searchedName)
    ? sourceValue
    : undefined;
};

export default useGetLoanPreview;
