import { useEffect, useState } from 'react';
import { produce } from 'immer';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { mapDtoToLoanInformationForm } from '@/features/onboarding/loan-details/LoanInformationContainer';
import { mapDtoToScheduleForm } from '@/features/onboarding/loan-details/ScheduleContainer';
import { useGetOnboardingTaskStatusesQuery } from '@/services/accounts/borrower';
import { useGetLoanProductRulesQuery, useGetLoanQuery } from '@/services/loans';
import { useGetLoanFacilitiesForLoanQuery } from '@/services/loans/facility';
import { useGetBorrowerCompanyByCompanyExternalIdQuery } from '@/services/originator/borrower-company';
import { useGetProductsByOriginatorIdQuery } from '@/services/products';
import { useSelectUser } from '@/store/user/selectors';
import {
  useSelectAdditionalDetailsForm,
  useSelectIsLoanInformationFormReady,
  useSelectIsScheduleFormReady,
  useSelectLoanFacilities,
  useSelectLoanInformationForm,
  useSelectRepaymentsForm,
  useSelectShouldOverrideAdditionalDetailsWithRules,
} from '../onboardingSlice';
import {
  AdditionalDetailsForm,
  AdditionalDetailsFormType,
  AdditionalDetailsProps,
} from './AdditionalDetailsForm';
import type { LoanInformationFormType } from './LoanInformationForm';
import type { ScheduleFormType } from './ScheduleForm';
import type { CreateLoan, Loan, LoanProductRules } from '@/interfaces/loans';
import { LoanFacility, RowLoanFacility } from '@/interfaces/loans/facility';

type ContainerProps = Pick<
  AdditionalDetailsProps,
  'onBack' | 'onContinue' | 'onChangeLoanId'
> & {
  loanId: string;
  originatorExternalId: string;
  borrowerCompanyExternalId: string;
  borrowerEmail?: string;
  executionId?: string;
};

const AdditionalDetailsContainer = ({
  loanId,
  executionId,
  originatorExternalId,
  borrowerCompanyExternalId,
  borrowerEmail,
  ...props
}: ContainerProps) => {
  const isLoanInformationFormReady = useSelectIsLoanInformationFormReady();
  const user = useSelectUser();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const isScheduleFormReady = useSelectIsScheduleFormReady();
  const { data: onboardingTasks = [], ...onboardingTasksQuery } =
    useGetOnboardingTaskStatusesQuery(
      { loanId, borrowerEmail },
      { skip: !loanId }
    );

  const { data: loan, ...loanQuery } = useGetLoanQuery(loanId, {
    skip: !loanId,
  });

  const { data: products, ...productsQuery } =
    useGetProductsByOriginatorIdQuery(
      { id: user?.mambuUser?.[0]?.mambuBranchEncodedKey },
      { skip: !user?.mambuUser?.[0]?.mambuBranchEncodedKey }
    );

  const { data: company, ...companyQuery } =
    useGetBorrowerCompanyByCompanyExternalIdQuery(
      { borrowerCompanyExternalId },
      {
        skip: !borrowerCompanyExternalId,
      }
    );

  const loanInformationForm = useSelectLoanInformationForm();

  const loanFacilitiesForm = useSelectLoanFacilities();
  const { data: loanFacilitiesStored } = useGetLoanFacilitiesForLoanQuery(
    loan?.encodedKey,
    {
      skip: !loan?.encodedKey,
    }
  );

  const [loanFacilities, setLoanFacilities] = useState(undefined);

  useEffect(() => {
    setLoanFacilities(
      (loanFacilitiesForm ?? loanFacilitiesStored)?.map(
        ({ facilityId, amount }) => ({
          facilityId,
          amount,
        })
      )
    );
  }, [loanFacilitiesStored, loanFacilitiesForm]);

  const loanInformation = loanInformationForm
    ? loanInformationForm
    : loan
      ? mapDtoToLoanInformationForm({ loan, company, products })
      : undefined;

  const scheduleForm = useSelectRepaymentsForm();

  const schedule = scheduleForm
    ? scheduleForm
    : loan
      ? mapDtoToScheduleForm(loan, 'ONBOARDING')
      : undefined;

  const { data: loanProductRules, ...rulesQuery } = useGetLoanProductRulesQuery(
    { id: loanInformation?.productTypeKey || loan?.productTypeKey },
    { skip: !loanInformation?.productTypeKey && !loan?.productTypeKey }
  );

  const additionalDetailsForm = useSelectAdditionalDetailsForm();

  const isEdit =
    onboardingTasks?.find(({ name }) => name === 'LOAN_DETAILS')?.status ===
    'COMPLETED';

  const shouldOverrideValueWithRules =
    useSelectShouldOverrideAdditionalDetailsWithRules();

  const initialFormValues = (() => {
    const loanRepaymentsInitialFormValues = additionalDetailsForm
      ? additionalDetailsForm
      : loan
        ? mapDtoToForm(loan)
        : EMPTY_FORM;

    const graceAmount = loanProductRules?.rules?.graceAmount?.defaultValue;
    const gracePeriod = loanProductRules?.rules?.gracePeriod?.defaultValue;
    const penalty = loanProductRules?.rules?.penalty?.defaultValue;

    const overrideWithRules = produce<AdditionalDetailsFormType>((draft) => {
      if (graceAmount !== null || graceAmount !== undefined) {
        draft.graceAmount = graceAmount;
      }

      if (gracePeriod !== null || gracePeriod !== undefined) {
        draft.gracePeriod = gracePeriod;
      }

      if (penalty !== null || penalty !== undefined) {
        draft.penaltyRate = penalty;
      }
    });

    return shouldOverrideValueWithRules
      ? overrideWithRules(loanRepaymentsInitialFormValues)
      : loanRepaymentsInitialFormValues;
  })();

  const isLoading =
    !isLoanInformationFormReady ||
    !isScheduleFormReady ||
    loanQuery.isFetching ||
    rulesQuery.isFetching ||
    companyQuery.isFetching ||
    onboardingTasksQuery.isFetching ||
    productsQuery.isFetching;

  if (isLoading && !isSubmittingForm)
    return (
      <div className="flex justify-center items-center">
        <LoadingAnimationPlat fitBox />
      </div>
    );

  return (
    <AdditionalDetailsForm
      loan={loan}
      isEdit={isEdit}
      loanProductRules={loanProductRules}
      initialFormValues={initialFormValues}
      // TODO: I propose to move entire save/update logic outside AdditionalDetailsForm
      getPayload={({ form: additionalDetailsForm }) =>
        mapFormToPayload({
          borrowerCompanyExternalId,
          originatorExternalId,
          loan,
          loanProductRules,
          loanInformationForm: loanInformation,
          scheduleForm: schedule,
          additionalDetailsForm,
          loanFacilities,
          executionId,
        })
      }
      scheduleForm={scheduleForm}
      formFlow="ONBOARDING"
      setIsSubmittingForm={setIsSubmittingForm}
      {...props}
    />
  );
};

export const mapDtoToForm = (
  loan: Loan
): AdditionalDetailsFormType | undefined => {
  if (!loan) return;

  return {
    graceAmount: loan.graceAmount,
    gracePeriod: loan.gracePeriod,
    penaltyRate: loan?.penaltyRate ?? loan?.interestSettings?.rate, // FIXME: is this OK?
  };
};

export const mapFormToPayload = ({
  borrowerCompanyExternalId,
  originatorExternalId,
  loan,
  loanInformationForm,
  scheduleForm,
  additionalDetailsForm,
  loanProductRules,
  loanFacilities,
  executionId,
}: {
  borrowerCompanyExternalId: string;
  originatorExternalId: string;
  loan: Loan;
  loanInformationForm: LoanInformationFormType;
  scheduleForm: ScheduleFormType;
  additionalDetailsForm: AdditionalDetailsFormType;
  loanProductRules: LoanProductRules;
  loanFacilities?: RowLoanFacility[];
  executionId?: string;
}) => {
  const hasTranches = loanProductRules?.maxNumberOfTranches > 0;

  // FIXME: is paymentPlan needed?
  const payload: Omit<CreateLoan, 'paymentPlan'> = {
    accountHolderKey: borrowerCompanyExternalId,
    assignedBranchKey: originatorExternalId,
    assignedCentreKey: loan?.assignedCentreKey, // FIXME: is this field needed? it's only available in updates
    externalId: loanInformationForm?.externalId,
    firstRepaymentDate: scheduleForm?.firstRepaymentDate,
    gracePeriod: additionalDetailsForm.gracePeriod,
    // graceAmount: additionalDetailsForm.graceAmount || loan?.graceAmount || 0, // ! Restore after bug LL-3568 fix by mambu
    graceAmount: additionalDetailsForm.graceAmount || loan?.graceAmount,
    interestRate: loanInformationForm?.interestRate || 0,
    defaultInterestRate: loanInformationForm?.defaultInterestRate,
    interestCapitalisationFrequency:
      loanInformationForm?.interestCapitalisationFrequency,
    loanName: loanInformationForm?.loanName,
    loanAmount: loanInformationForm?.loanAmount,
    paymentHoliday: scheduleForm?.paymentHoliday,
    rollUpDuration: scheduleForm?.rollUpDuration,
    productTypeKey: loanInformationForm?.productTypeKey,
    repaymentInstallments: scheduleForm.repaymentInstallments,
    payCurrentInstallment: scheduleForm.payCurrentInstallment,
    minimumInterestPeriod: scheduleForm.minimumInterestPeriod,
    interestGraceInstallments: scheduleForm?.interestGraceInstallments,
    interestGraceHandleMethod: scheduleForm?.interestGraceHandleMethod,
    repaymentPeriodCount: scheduleForm.repaymentPeriodCount,
    repaymentPeriodUnits: scheduleForm.repaymentPeriodUnits,
    netLoanCalculationMethod: loanInformationForm?.netLoanCalculationMethod,
    ltv: loanInformationForm?.ltv,
    gdv: loanInformationForm?.gdv,
    ltc: loanInformationForm?.ltc,
    gdc: loanInformationForm?.gdc,
    exitFeeAmount: loanInformationForm?.exitFeeAmount
      ? +loanInformationForm?.exitFeeAmount
      : null,
    periodicPayment: loanProductRules?.balloonPaymentEnabled
      ? parseFloat(loanInformationForm.periodicPayment)
      : undefined,
    balloonPaymentAmount:
      loanProductRules?.balloonPaymentEnabled &&
      !!loanInformationForm.balloonPaymentAmount
        ? parseFloat(loanInformationForm.balloonPaymentAmount)
        : undefined,
    penaltyRate: loanProductRules?.variableRequiredFields?.some(
      (field) => field.name === 'penaltyRate'
    )
      ? additionalDetailsForm.penaltyRate
      : undefined,
    monthlyRepaymentDay: loanProductRules?.variableRequiredFields?.some(
      (field) => field.name === 'monthlyRepaymentDay'
    )
      ? scheduleForm.monthlyRepaymentDay
      : undefined,
    fees: mapFeesToDto({
      fees: loanInformationForm?.fees,
      loanProductRules,
    }),
    disbursementDate: hasTranches ? undefined : scheduleForm?.disbursementDate,
    loanFacilities: loanFacilities?.length
      ? loanFacilities.map((f) => ({
          facilityId: f.facilityId,
          amount: parseFloat(f.amount),
        }))
      : [],
    tranches: hasTranches
      ? scheduleForm?.tranches?.map((tranche) => ({
          facilities: Object.values(tranche?.facilities).map(
            (f: Partial<RowLoanFacility>) => {
              return {
                facilityId: f.facilityId,
                amount: parseFloat(f.amount),
                name: f.name,
              } as Partial<LoanFacility>;
            }
          ),
          amount: tranche.disbursementAmount
            ? tranche.disbursementAmount
            : undefined,
          disbursementDate: tranche.disbursementDate,
          fees: Object.entries(tranche?.fees ?? {}).map(
            ([predefinedFeeEncodedKey, content]) => ({
              predefinedFeeEncodedKey,
              amount: content.amount,
            })
          ),
          counterpartyId: tranche.counterpartyId,
        }))
      : undefined,
    factorAmount: loanInformationForm?.factorAmount
      ? parseFloat(loanInformationForm?.factorAmount as unknown as string)
      : 0,
    feeSettings: {
      feeAmortizationDetails: loanInformationForm.feeAmortizationDetails,
    },
    counterpartyId: scheduleForm.counterpartyId,
    interestCeiling: loanInformationForm?.interestCeiling,
    interestFloor: loanInformationForm?.interestFloor,
    useInitialInterestRateAsCeiling:
      loanInformationForm?.useInitialInterestRateAsCeiling,
    useInitialInterestRateAsFloor:
      loanInformationForm?.useInitialInterestRateAsFloor,
    executionId,
  };
  return payload as CreateLoan;
};

export const EMPTY_FORM: AdditionalDetailsFormType = {
  gracePeriod: null,
  graceAmount: null,
  penaltyRate: null,
};

type Fees = Record<
  string,
  {
    predefinedEncodedKey: string;
    amount: number;
  }
>;

const mapFeesToDto = ({
  fees,
  loanProductRules,
}: {
  fees: Fees;
  loanProductRules: LoanProductRules;
}) => {
  const feeById = new Map(loanProductRules?.fees?.map((fee) => [fee.id, fee]));

  return Object.entries(fees ?? {}).map(
    ([predefinedFeeEncodedKey, content]) => ({
      predefinedFeeEncodedKey,
      amount: (() => {
        const fee = feeById.get(predefinedFeeEncodedKey);
        return (fee?.calculationMethod === 'FLAT' && fee?.amount > 0) ||
          fee?.calculationMethod === 'PERCENTAGE'
          ? undefined
          : content.amount;
      })(),
    })
  );
};

export { AdditionalDetailsContainer, mapFeesToDto };
