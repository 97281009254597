import React from 'react';
import classNames from 'classnames';
import Lottie from 'lottie-react';
import primaryData from '../assets/kennek_Loading_Animation_Primary.json';
import secondaryData from '../assets/kennek_Loading_Animation_Secondary.json';
import standardData from '../assets/kennek_Loading_Animation_Standard.json';

export interface LoadingAnimationProps {
  fitBox?: boolean;
  size?: 'xs' | 'sm' | 'md';
  color?: 'primary' | 'secondary' | 'standard';
}

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({
  color = 'primary',
  fitBox = false,
  size = 'md',
}) => {
  const animationColor = {
    primary: primaryData,
    secondary: secondaryData,
    standard: standardData,
  };
  const defaultOptions = {
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div
      className={classNames({
        'absolute left-1/2 top-1/2': !fitBox,
        relative: fitBox,
      })}
    >
      <div className={classNames(sizes[size])}>
        <Lottie
          loop
          autoplay
          animationData={animationColor[color]}
          rendererSettings={defaultOptions.rendererSettings}
        />
      </div>
    </div>
  );
};

export default LoadingAnimation;

const sizes = { xs: 'w-6', sm: 'w-8', md: 'w-16' };
