import { SvgIcon } from 'kennek/icons';
import TitleWithIcon from 'ui/components/TitleWithIcon';
import { PencilAltIcon } from '@heroicons/react/outline';
import { RefinanceSteps } from '@/features/edit-loan/EditLoan';
import { OnboardingSteps } from '../../Onboarding';
import { CustomInformationFields } from '../../custom-information/utils';
import { RoleBasedColumns } from '../OnboardingSummary.utils';

interface CustomInformationProps {
  customInformationData: CustomInformationFields[];
  editable: boolean;
  setActiveStep: (step: OnboardingSteps | RefinanceSteps) => void;
}

export const CustomInformation = ({
  customInformationData,
  editable,
  setActiveStep,
}: CustomInformationProps) => {
  return (
    <div className="grid grid-cols-1 gap-2 mt-2">
      <div className="border border-neutral-300 rounded-md p-4 flex flex-col body-200">
        <div className="flex flex-col">
          <div className="flex justify-between">
            <div className="flex items-start">
              <TitleWithIcon
                title="Additional information"
                icon={<SvgIcon name="DocumentsIcon" />}
                insideCard
                titleSize="md"
              />
            </div>
            {customInformationData?.length !== 0 && editable && (
              <button onClick={() => setActiveStep('CUSTOM_INFORMATION')}>
                <PencilAltIcon height={20} className="text-secondary-500" />
              </button>
            )}
          </div>
          {customInformationData?.length > 0 ? (
            RoleBasedColumns(customInformationData)
          ) : (
            <div className="flex items-center justify-center h-full col-span-3">
              <span className="heading-500 text-neutral-500 text-center">
                No additional information
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
