import React, { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import { Combobox } from '@headlessui/react';
import { ThemeContext } from '../theme';

type Result = { label: string; id: string };

export interface AutocompleteProps {
  results: Result[];
  value: string;
  onChange: (selected: string) => void;
  onSearch: (query: string) => void;
  onFocus?: () => void;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  openOnFocus?: boolean;
  label?: string;
  error?: boolean;
}

const Autocomplete: React.FC<AutocompleteProps> = (props) => {
  const {
    results,
    value,
    onChange,
    onSearch,
    className,
    placeholder,
    onFocus,
    openOnFocus,
    label,
    error,
    ...other
  } = props;
  const {
    theme: { autocomplete },
  } = useContext(ThemeContext);
  const [open, setOpen] = useState(true);

  const baseStyle = autocomplete.base;
  const activeStyle = autocomplete.active;
  const disabledStyle = autocomplete.disabled;
  const optionStyle = autocomplete.option;
  const selectedStyle = autocomplete.selected;
  const invalidStyle = autocomplete.invalid;
  const defaultPlaceholder = 'Please, provide a placeholder';
  const comboboxButtonRef = useRef<HTMLButtonElement>(null);

  const forceOpenOptions = () => {
    comboboxButtonRef.current?.click();
    setOpen(true);
  };

  const handleOnChange = (selected: string) => {
    onChange(selected);
    setOpen(false);
  };

  const handleOnSearch = (searchValue: string) => {
    onSearch(searchValue);
    setOpen(true);
  };

  return (
    <Combobox
      value={value}
      onChange={handleOnChange}
      as="div"
      className="w-full relative"
      {...other}
    >
      <Combobox.Button
        className="hidden"
        ref={comboboxButtonRef}
      ></Combobox.Button>
      <Combobox.Label className="heading-100 flex mb-2">{label}</Combobox.Label>
      <Combobox.Input
        placeholder={placeholder || defaultPlaceholder}
        className={({ disabled }) =>
          classNames(
            baseStyle,
            disabled && disabledStyle,
            error && invalidStyle,
            className
          )
        }
        onChange={(event) => handleOnSearch(event.target.value)}
        onFocus={() => {
          if (openOnFocus) forceOpenOptions();
          if (onFocus) onFocus();
        }}
      />
      {results?.length > 0 && open && (
        <Combobox.Options className={classNames(optionStyle)}>
          {results?.map((option: Result) => (
            <Combobox.Option
              key={option.id}
              value={option.id}
              className={({ active, selected }) =>
                classNames(
                  'px-3 py-2 cursor-pointer',
                  selected && selectedStyle,
                  active && activeStyle
                )
              }
            >
              {option.label}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}
    </Combobox>
  );
};

export default Autocomplete;
